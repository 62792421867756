import React from "react";
import styled from "styled-components";
import { withI18n } from "react-i18next";
import { connect } from "react-redux";
import qs from "query-string";

interface IProps extends StateProps {
  t?: any;
}

const HeaderComponent: React.FC<IProps> = ({ t, isLoggedIn }) => {
  const isVisible =
    (!isLoggedIn && window.location.href.indexOf("/register") > -1) ||
    window.location.href.indexOf("/confirm") > -1;
  
  const trialCheckout = window.location.href.includes('trial-actor-app');

  let params = qs.stringify({
      ...(qs.parse(window.location.search))
  });

  if(qs.parse(window.location.search).bd){
    params = qs.stringify({
      page:'openbd',
      ...(qs.parse(window.location.search))
  });
  }

  
  return (
    <Wrapper>
      <Container>
        <img
          src="/images/icon-cwb-logo-full.svg"
          alt="Casting Workbook Logo"
          onClick={() =>
            (window.location.href = process.env.REACT_APP_CWB_Site)
          }
          style={{ cursor: "pointer" }}
        />
        {(isVisible || trialCheckout) && (
          <LoginDiv>
            <StyledSpan>{t("Already have an account?")}</StyledSpan>
            <LoginButton
              href={`${process.env.REACT_APP_CWB_Site}/${process.env.REACT_APP_CWB_Login_Path}?${params}`}
            >
              {t("Log in")}
            </LoginButton>
          </LoginDiv>
        )}
      </Container>
    </Wrapper>
  );
};

const Wrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;

  height: 60px;
  background-color: ${(p) => p.theme.white};

  @media all and (min-width: 520px) {
    box-shadow: 0 2px 4px 0 rgba(218, 218, 218, 0.5);
  }
`;

const Container = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;

  width: 65%;

  img {
    height: 40px;
  }

  @media all and (max-width: 920px) {
    width: 80%;
  }
`;

const StyledSpan = styled.span`
  @media all and (max-width: 920px) {
    display: none;
  }
`;

const LoginDiv = styled.div`
  font-size: 11px;
  letter-spacing: 0.21px;
`;

const LoginButton = styled.a`
  font-weight: 600;
  margin-left: 8px;
  padding: 10px 15px 10px 15px;
  font-size: 16px;

  border: 1px solid ${(p) => p.theme.lightBlue} !important;
  border-radius: ${(p) => p.theme.dropDown["border-radius"]};

  background: #2ed2ff;
  color: white !important;

  &:hover {
    background: #00aaff !important;
    border: 1px solid ${(p) => p.theme.btnDarkBlue} !important;
    color: white !important;
  }
`;

interface StateProps {
  isLoggedIn: boolean;
}

function mapStateToProps(state: any): StateProps {
  return {
    isLoggedIn: state.headerModel.isLoggedIn,
  };
}

export default withI18n()(
  connect<StateProps, {}, {}>(mapStateToProps, {})(HeaderComponent)
);
