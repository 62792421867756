import * as http from "../http";
// @ts-ignore
import { getAuthConfig } from "cwb-react";
import { ISubscriptionService } from "./dtos/IServicesDto";

const api = `${process.env.REACT_APP_REG_Api}/Services`;

export const getPaidServicesSignup = (actorSignUpId: number) => {
  return http.get<ISubscriptionService[]>(
    `${api}/paid/signup/${actorSignUpId}`
  );
};

export const getFreeServiceSignup = (actorSignUpId: number) => {
  return http.get<ISubscriptionService>(`${api}/free/signup/${actorSignUpId}`);
};

export const getSubscriptionsServices = () => {
  return http.get<ISubscriptionService[]>(`${api}/all`);
};

export const getTrialService = (countryCode:string) => {
  return http.get<ISubscriptionService[]>(`${api}/trial/${countryCode}`);
}

export const getPaidServicesClient = () => {
  const options = {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      CWB: "1",
      ...getAuthConfig().headers,
    },
  } as RequestInit;

  return http.get<ISubscriptionService[]>(`${api}/paid/client`, options);
};

export const getFreeServiceClient = () => {
  const options = {
    method: "GET",
    mode: "cors",
    credentials: "include",
    headers: {
      CWB: "1",
      ...getAuthConfig().headers,
    },
  } as RequestInit;

  return http.get<ISubscriptionService>(`${api}/free/client`, options);
};

