import { setCurUserSignUp, getSessionId } from "../helpers";
import { createModel } from "@rematch/core";
import * as CastingRegistrationApi from "../shared/api/CastingRegistration.api";
import {
  ICastingAccountDto,
  ICastingUser,
  ICastingFilmCenter,
  ICastingAccountType,
  ICastingError,
  ICastingProApprovalDetails
} from "../shared/api/dtos/ICastingDtos";
import {
  IError,
  IApiResponseError
} from "../shared/api/dtos/IApiRepsonseError";
import { IAgentError } from "../shared/api/dtos/IAgentDtos";

const castingModel = createModel({
  state: {
    isLoading: false,
    sessionId: "",
    castingUser: {} as ICastingUser,
    castingFilmCenter: {} as ICastingFilmCenter,
    castingAccountType: {} as ICastingAccountType,
    errors: {} as ICastingError
  },
  reducers: {
    setIsLoading: (state: any, isLoading: boolean) => ({
      ...state,
      isLoading
    }),
    setErrors: (state: any, errors: any) => ({
      errors
    })
  },
  effects: (dispatch: any) => ({
    async saveCastingAccount(dto: ICastingUser, state: any) {
      dispatch.castingModel.setIsLoading(true);
      await CastingRegistrationApi.saveCastingAccount(dto)
        .then((resp: any) => {
          dispatch.castingModel.setIsLoading(false);
          let castingDto: ICastingAccountDto = resp!.data;
          if (castingDto) {
            dispatch.castingModel.setErrors({} as ICastingError);
            setCurUserSignUp(null); // clear user in case pick diff account type and went through one step
            setCurUserSignUp(castingDto);
          }
        })
        .catch(resp => {
          setCurUserSignUp(null);
          let apiResponseError: IApiResponseError = resp;
          if (apiResponseError.errors) {
            dispatch.castingModel.setErrors(
              getAgentErrors(apiResponseError.errors)
            );
          } else if (apiResponseError.message) {
            dispatch.castingModel.setErrors({
              errorMessage: apiResponseError.message
            });
          }
          console.log(resp);
        });
    },
    async saveCastingFilmCenter(dto: ICastingFilmCenter, state: any) {
      await CastingRegistrationApi.saveCastingFilmCenter(getSessionId(), dto)
        .then((resp: any) => {
          dispatch.castingModel.setIsLoading(false);
          let castingDto: ICastingAccountDto = resp!.data;
          if (castingDto) {
            dispatch.castingModel.setErrors({} as ICastingError);
            setCurUserSignUp(castingDto);
          }
        })
        .catch(resp => {
          setCurUserSignUp(null);
          let apiResponseError: IApiResponseError = resp;
          if (apiResponseError.errors) {
            dispatch.castingModel.setErrors(
              getAgentErrors(apiResponseError.errors)
            );
          } else if (apiResponseError.message) {
            dispatch.castingModel.setErrors({
              errorMessage: apiResponseError.message
            });
          }
          console.log(resp);
        });
    },
    async createCastingUnverified() {
      dispatch.castingModel.setIsLoading(true);
      try {
        const resp = await CastingRegistrationApi
          .createCastingUnverified(getSessionId());
        const castingDto: ICastingAccountDto = resp!.data;
        if (castingDto) {
          dispatch.castingModel.setErrors({} as ICastingError);
          setCurUserSignUp(castingDto);
        }
        dispatch.headerModel.setUserLoggedIn(true);
      } catch (e) {
        setCurUserSignUp(null);
        dispatch.headerModel.setUserLoggedIn(false);
        let apiResponseError: IApiResponseError = e;
        if (apiResponseError.message) {
          dispatch.castingModel.setErrors({
            errorMessage: apiResponseError.message
          });
        }
        console.log(e);
      } finally {
        dispatch.castingModel.setIsLoading(false);
      }
    },
    async createCastingPro(
      dto: ICastingProApprovalDetails
    ) {
      dispatch.castingModel.setIsLoading(true);
      try {
        const sessionId = getSessionId();
        await CastingRegistrationApi.saveCastingProApproval(
          sessionId,
          dto
        );
        const resp = await CastingRegistrationApi.createCastingPro(
          sessionId
        );
        const castingDto: ICastingAccountDto = resp!.data;
        if (castingDto) {
          dispatch.castingModel.setErrors({} as ICastingError);
          setCurUserSignUp(castingDto);
        }
        dispatch.headerModel.setUserLoggedIn(true);
      } catch (e) {
        setCurUserSignUp(null);
        dispatch.headerModel.setUserLoggedIn(false);
        throw e;
      } finally {
        dispatch.castingModel.setIsLoading(false);
      }
    },
    async createCastingProducer() {
      dispatch.castingModel.setIsLoading(true);
      try {
        const resp = await CastingRegistrationApi.createCastingProducer(
          getSessionId()
        );
        const castingDto: ICastingAccountDto = resp!.data;
        if (castingDto) {
          dispatch.castingModel.setErrors({} as ICastingError);
          setCurUserSignUp(castingDto);
        }
        dispatch.headerModel.setUserLoggedIn(true);
      } catch (e) {
        setCurUserSignUp(null);
        dispatch.headerModel.setUserLoggedIn(false);
        let apiResponseError: IApiResponseError = e;
        if (apiResponseError.message) {
          dispatch.castingModel.setErrors({
            errorMessage: apiResponseError.message
          });
        }
        console.log(e);
      } finally {
        dispatch.castingModel.setIsLoading(false);
      }
    }
  })
});

const getAgentErrors = (apiErrors: IError[]): IAgentError => {
  let errors = {} as IAgentError;
  apiErrors.forEach((k: IError) => (errors[k.field] = k.message));
  return errors;
};

export default castingModel;
