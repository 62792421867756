import { createModel } from "@rematch/core";
import * as PerformerAPI from "shared/api/Performer.api";

const performerModel = createModel({
  state: {
  },
  reducers: {
  },
  effects: (dispatch) => ({
    async getPerformerTypes() {
      const response = await PerformerAPI.getPerformerTypes();
      if (!response.ok) return [];
      return (response.data as any[]).map((item) => ({
        label: item.description,
        value: item.performerTypeID
      }));
    },
    async createPerformer(performer) {
      return await PerformerAPI.createPerformer(performer);
    }
  })
});

export default performerModel;
