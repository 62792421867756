import {
  IActorCredentials,
  IActorPhone,
  IActorAccountDto,
  IActorStageName,
  IActorSignUpDto,
  IAccountInfo,
  IAccountCredentials,
  IActorFilmCenter
} from "./dtos/IActorDto";
import * as http from "../http";
import axios from "axios";
import { TrackingData } from "./dtos/ITrackingDataDto";

export const createActorAccount = (dto: IActorCredentials) => {
  return http.post<IActorAccountDto>(
    `${process.env.REACT_APP_REG_Api}/actor/credentials`,
    dto
  );
};

export const createActorPhone = (sessionId: any, dto: IActorPhone) => {
  return http.post<IActorAccountDto>(
    `${process.env.REACT_APP_REG_Api}/actor/${sessionId}/phone`,
    dto
  );
};

export const verifyActorPhone = (
  sessionId: any,
  dto: IActorPhone,
  code: string
) => {
  return http.post<IActorAccountDto>(
    `${process.env.REACT_APP_REG_Api}/actor/${sessionId}/verify-phone/${code}`,
    dto
  );
};

export const createStageName = (sessionId: any, dto: IActorStageName) => {
  return http.post<IActorAccountDto>(
    `${process.env.REACT_APP_REG_Api}/actor/${sessionId}/stagename`,
    dto
  );
};

export const createTrialSignUp = (sessionId: any, dto: IActorFilmCenter) => {
  return http.post<IActorSignUpDto>(
    `${process.env.REACT_APP_REG_Api}/actor/${sessionId}/trial/signup`,
    dto
  );
};

export const createActorSignUp = (sessionId: any, dto: IActorAccountDto) => {
  return http.post<IActorSignUpDto>(
    `${process.env.REACT_APP_REG_Api}/actor/${sessionId}/save-signup`,
    dto
  );
};

export const getAccountInfo = (guid: string) => {
  return http.get<IAccountInfo>(
    `${process.env.REACT_APP_REG_Api}/actor/basicInfo/${guid}`
  );
};

export const confirmAccount = (password: string, guid: string) => {
  return axios.post(
    `${process.env.REACT_APP_REG_Api}/actor/updatepass/${guid}?password=${password}`,
    null
  );
};

export const loginAccount = (dto: IAccountCredentials) => {
  return axios.post(`${process.env.REACT_APP_Api}/auth/jwt/authorize`, {
    login: dto.workbookId,
    password: dto.password,
  });
};

export const createFreemiumUser = (actorSignUpId: number) => {
  const trackingData = JSON.parse(
    localStorage.getItem("tData")
  ) as TrackingData;
  return http.post(
    `${process.env.REACT_APP_REG_Api}/actor/create-freemium/${actorSignUpId}${window.location.search}`,
    trackingData
  );
};

export const createTrialUser = (actorSignUpId: number) => {
  const trackingData = JSON.parse(
    localStorage.getItem("tData")
  ) as TrackingData;
  return http.post(
    `${process.env.REACT_APP_REG_Api}/StripeSubscription/trial/${actorSignUpId}`,
    trackingData
  );
};
