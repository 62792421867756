import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import { connect } from 'react-redux';
import { withI18n } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled, { css } from 'styled-components';
import {
  Footer,
  Modal,
  PageContainer,
  Typography
} from '../Common';
import CWBLabsFooter from 'components/CWBLabs/components/Footer';
import SpinnerIcon from '../../images/blue-arrow-spinner.gif';

type Props = {
  t: any;
  location: any;
} & RouteComponentProps & DispatchProps;

const ActorPaymentProcessing: React.FC<Props> = ({
  t,
  history,
  location,
  getPaymentStatus
}) => {
  if (!location.state) {
    window.top.location.replace(`${process.env.REACT_APP_CWB_500}`);
  }

  const [numAttempts, setNumAttempts] = useState(0);

  useEffect(() => {
    type PaymentStatus = 'success' | 'error' | 'processing';

    const checkPaymentStatus = async () => {
      let result;
      const pstatus = await getPaymentStatus(location.state.serviceId);
      if (pstatus.isPending) {
        result = 'processing';
      } else {
        result = pstatus.success ? 'success' : 'error';
      }
      return result as PaymentStatus;
    };

    const pollPaymentStatus = async () => {
      const paymentStatus: PaymentStatus = await checkPaymentStatus();

      if (paymentStatus === 'processing') {
        if (numAttempts < 20) {
          setTimeout(() => {
            setNumAttempts(numAttempts + 1);
          }, 1000);
        } else {
          history.push({
            pathname: '/actor/payment-declined',
            search: location.search
          });
        }
      } else if (paymentStatus === 'success') {
        history.push({
          pathname: location.state.successPath,
          search: location.search
        });
      } else {
        history.push({
          pathname: '/actor/payment-declined',
          search: location.search
        });
      }
    };

    pollPaymentStatus();
  }, [history, location.search, location.state, numAttempts, getPaymentStatus]);

  const isCWBLabs = qs.parse(location.search).cwblabs;

  return (
    <StyledPageContainer isCWBLabs={isCWBLabs}>
      <StyledModal isCWBLabs={isCWBLabs}>
        <Typography
          align="center"
          component="h1"
          variant="h2"
        >
          {t('Payment Processing.')}
        </Typography>
        <StyledImg src={SpinnerIcon} />
        <Typography
          align="center"
          color="darkGrey"
          variant="h4"
        >
          {t('We are processing your payment. This may take a few minutes. Please do not refresh your screen. You will be redirected once payment is complete!')}
        </Typography>
      </StyledModal>
      {isCWBLabs ? (<StyledCWBLabsFooter />) : (<Footer />)}
    </StyledPageContainer>
  );
};

const StyledPageContainer = styled(PageContainer)`
  ${(p) => p.isCWBLabs && css`
    background-color: #040F1C;
  `}

  @media all and (max-width: 520px) {
    width: 100%;

    ${(p) => p.isCWBLabs && css`
      padding: ${(p) => p.theme.spacing(4, 2)};
      border: none;
    `}
  }
`;

const StyledModal = styled(Modal)`
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${p => p.theme.spacing(10, 19, 12)};

  @media all and (max-width: 520px) {
    width: 100%;
    padding: ${p => p.theme.spacing(4)};

    ${(p) => p.isCWBLabs && css`
      border-radius: 4px;
      border-top: 6px solid ${(p) => p.theme.lightBlue};
    `}
  }
`;

const StyledImg = styled.img`
  margin: ${p => p.theme.spacing(3, 0, 4)}
`;

const StyledCWBLabsFooter = styled(CWBLabsFooter)`
  margin-top: ${(p) => p.theme.spacing(3)};
`;

const mapStateToProps = (state: any) => ({});

type DispatchProps = {
  getPaymentStatus: (serviceId: number) => any;
};

const mapDispatchToProps = (dispatch: any) => ({
  getPaymentStatus: dispatch.stripeModel.getPaymentStatus
});

export default withI18n()(connect(
  mapStateToProps,
  mapDispatchToProps
)(withRouter(ActorPaymentProcessing)));
