import React from "react";
import styled from "styled-components";
import { withI18n } from "react-i18next";
import * as SharedStyle from "../Common/StyledComponents";

interface IProps {
  t?: any;
}

const ActorSellingFeatures: React.FC<IProps> = ({ t }) => {
  return (
    <>
      <TitleWrapper>
        <MainTile>{t("Actor")}</MainTile>
      </TitleWrapper>
      <StyledP>
        {t(
          "Why Upgrade to Pro? This is the one membership every actor needs to take their career to the next level."
        )}
      </StyledP>
      <TitleWrapper>
        <Title>
          <SharedStyle.SellingFeatureIcon src="../images/camera_blue.svg" />
          <div>{t("Virtual Casting Rooms")}</div>
        </Title>
      </TitleWrapper>
      <SharedStyle.SellingFeatureP>
        {t("Receive all the materials for your upcoming audition including sides, scripts, video directions, and audition link. Attend your audition virtually from the comfort of your home. Open the app and we take care of the rest.")}
      </SharedStyle.SellingFeatureP>
      <TitleWrapper>
        <Title>
          <SharedStyle.SellingFeatureIcon src="../images/video_gallery.svg" />
          <div>{t("Your total portfolio")}</div>
        </Title>
      </TitleWrapper>
      <SharedStyle.SellingFeatureP>
        {t("This includes 90 minutes of video & audio demo reels, self-tapings, recordings, 16 photos, EPKs, Bios and multiple resumes with multiple agents.")}
      </SharedStyle.SellingFeatureP>
      <TitleWrapper>
        <Title>
          <SharedStyle.SellingFeatureIcon src="../images/iphone.svg" />
          <div>{t("Actor.App")}</div>
        </Title>
      </TitleWrapper>
      <SharedStyle.SellingFeatureP>
        {t("New with version 3.0, imagine Jobs with sides that you can click, record and edit a self-tape and submit instantly without leaving the app. We have voice recordings too with complete profile management and sharing.")}
      </SharedStyle.SellingFeatureP>
      <TitleWrapper>
        <Title>
          <SharedStyle.SellingFeatureIcon src="../images/lab.svg" />
          <div>{t("CWBlabs")}</div>
        </Title>
      </TitleWrapper>
      <SharedStyle.SellingFeatureP>
        {t("Casting Workbook's new integrated e-learning platform! Get free access to Livestream series including Interviews with top casting directors & industry professionals. As part of your membership you will also get access to book coaching sessions with the best in the business as well as free & paid tutorials and workshops.")}
      </SharedStyle.SellingFeatureP>
    </>
  );
};

export default withI18n()(ActorSellingFeatures);

const TitleWrapper = styled.div`
  width: 100%;
`;

const MainTile = styled.div`
  font-size: 32px;
  font-weight: 600;
  line-height: 44px;
  color: #0a1f44 !important;
  margin-bottom: 12px;
`;

const Title = styled.div`
  display: flex;
  font-size: 21px;
  font-weight: 600;
  line-height: 29px;
  color: #364866 !important;
  margin-bottom: 12px;
`;

const StyledP = styled.p`
  font-size: 14px;
  color: #364866 !important;
  line-height: 25px;
`;
