import React, { useState } from "react";
import qs from "query-string";
import { connect } from "react-redux";
import { withI18n } from "react-i18next";
import styled from "styled-components";
import { Button } from "../Common";
import Footer from "../Common/Footer";
import Modal from "../Common/Modal";
import PageContainer from "../Common/PageContainer";
import Text from "../Common/Text";
import UpgradeTable from "../Common/UpgradeTable";
import EmailUpgradeModal from "../Modals/EmailUpgradeModal";
import Tooltip from "../Common/Tooltip";
import { emailValidation } from "helpers/validations";
import CWBLabsActorUpgradeTable from "components/CWBLabs/ActorUpgradeTable";

interface IProps extends StateProps, DispatchProps {
  history: any;
  location: any;
  t: any;
}

const ActorUpgradeTable: React.FC<IProps> = ({
  history,
  location,
  t,
  actorProfile,
  yearlyPerMonthCharge,
  currencySymbol,
}) => {
  const [isOpen, setIsOpen] = useState(!actorProfile.email);

  const handleUpgrade = () => {
    let errors = emailValidation(actorProfile.email, t);
    if (!errors) {
      history.push({
        pathname: "/actor/select-membership",
        search: location.search,
      });
    } else {
      setIsOpen(true);
    }
  };

  const handleClose = () => {
    setIsOpen(false);
  };

  const hoverTip = (
    <StyledTooltip placement="top" tip={t("On an annual plan.")}>
      <img alt="more info" src="../images/info.svg" />
    </StyledTooltip>
  );

  return qs.parse(location.search).cwblabs ? (
    <CWBLabsActorUpgradeTable />
  ) : (
    <PageContainer>
      <StyledModal>
        <ContentWrapper>
          <div>
            <Badge>{t("upgrade").toUpperCase()}</Badge>
            <StyledTextH1 variant="h1">
              {t("Go Pro and take control of your career!")}
            </StyledTextH1>
            <StyledTextP>
              {/* eslint-disable-next-line */}
              {t(
                "Get access to everything Casting Workbook has to offer for as little as {{currencySymbol}}{{yearlyPerMonthCharge}}/mo.",
                { currencySymbol, yearlyPerMonthCharge }
              )}
              {hoverTip}
            </StyledTextP>
          </div>
          <UpgradeTable />
          <ButtonContainer>
            <Button variant="secondary" onClick={() => history.goBack()}>
              {t("Go Back")}
            </Button>
            <Button onClick={() => handleUpgrade()}>
              {/* eslint-disable-next-line */}
              {t("Upgrade now")}
            </Button>
          </ButtonContainer>
        </ContentWrapper>
      </StyledModal>
      <Footer />
      {isOpen && <EmailUpgradeModal onClose={() => handleClose()} />}
    </PageContainer>
  );
};

const StyledModal = styled(Modal)`
  width: 800px;

  @media all and (max-width: 768px) {
    width: 90%;
  }

  @media all and (max-width: 520px) {
    width: 100%;
  }
`;

const ContentWrapper = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: 40px 115px;

  & > div:first-child {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  & > table {
    margin: ${(p) => p.theme.spacing(2, 0)};
  }

  @media all and (max-width: 768px) {
    padding: 24px 40px;
  }

  @media all and (max-width: 520px) {
    padding: 24px 16px;
  }
`;

const Badge = styled.div`
  && {
    color: ${(p) => p.theme.white};
    padding: 4px 8px;
    font-size: 12px;
    background-color: ${(p) => p.theme.lightBlue};
    border-radius: 4px;
  }
`;

const StyledTextH1 = styled(Text)`
  && {
    margin: 22px 0 16px;
    text-align: center;

    @media all and (max-width: 520px) {
      width: 80%;
    }
  }
`;

const StyledTextP = styled(Text)`
  && {
    text-align: center;
    display: inherit;
    width: max-content;
  }
`;

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;

  & > * + * {
    margin-left: 10px;
  }
`;

const StyledTooltip = styled(Tooltip)`
  top: -3px;

  & > img {
    width: 18px;
    height: 18px;
    margin-top: -2px;
  }
`;

interface StateProps {
  actorProfile: any;
  yearlyPerMonthCharge: number;
  currencySymbol: string,
}

const mapStateToProps = (state: any) => ({
  actorProfile: state.appModel.actorProfile,
  yearlyPerMonthCharge: state.premiumModel.yearlyPerMonthCharge,
  currencySymbol: state.premiumModel.currencySymbol,
});

interface DispatchProps {}

const mapDispatchToProps = (dispatch: any) => ({});

export default withI18n()(
  connect(mapStateToProps, mapDispatchToProps)(ActorUpgradeTable)
);
