import React, { useEffect } from 'react';
import qs from 'query-string';
import { withI18n } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as ReactRouterLink, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Skeleton, Typography } from 'components/Common';
import ActorSignUpForm from './components/ActorSignUpForm';
import Card from './components/Card';
import Header from './components/Header';
import Page from './components/Page';

type Props = {
  t: any;
} & StateProps & DispatchProps & RouteComponentProps;

const ActorSignUp: React.FC<Props> = ({
  t,
  location,
  currencySymbol,
  mediaLimit,
  numPhotos,
  yearlyPerMonthCharge,
  getSubscriptionServices
}) => {
  useEffect(() => {
    const fetchSubscriptionServices = async () => {
      try {
        await getSubscriptionServices();
      } catch (e) {
        window.top.location.href = process.env.REACT_APP_CWB_500;
      }
    };

    fetchSubscriptionServices();
  }, [getSubscriptionServices]);

  const params = qs.stringify({
    cwblabs: true,
    redirect: 'cwblabs',
    ...(qs.parse(location.search))
  });

  return (
    <>
      <Header />
      <Page>
        <StyledDiv>
          <Typography align="center" color="white" component="h1" variant="h1">
            {t('Sign up for your Casting Workbook Pro Account!')}
          </Typography>
          <Subheading align="center" color="white" variant="h4">
            {yearlyPerMonthCharge ? t(
              'Get access to all the educational account Casting Workbook has to offer plus the robust features in Casting Workbook and Actor App. This is the one membership every actor needs to take their career to the next level - all for as little as {{currencySymbol}}{{yearlyPerMonthCharge}}/mo!',
              { currencySymbol, yearlyPerMonthCharge }
            ) : [...Array(3)].map((_, index) => <Skeleton key={index} />)}
          </Subheading>
          <StyledCard>
            <MembershipDescription>
              <Typography component="h2" gutterBottom variant="h3">
                {t('Your Pro Actor Membership')}:
              </Typography>
              <HorizontalSeparator />
              <Description>
                <Typography component="span" variant="bodyBold">
                  {t('Virtual Casting Rooms')}:
                </Typography>
                &nbsp;
                <Typography color="medGrey" component="span">
                  {t('Receive everything you need to get you prepped for your audition and attend virtually from anywhere.')}
                </Typography>
              </Description>
              <Description>
                <Typography component="span" variant="bodyBold">
                  {t('Your total portfolio')}:
                </Typography>
                &nbsp;
                <Typography color="medGrey" component="span">
                  {mediaLimit && numPhotos ? t(
                    'Includes {{mediaMinutes}} minutes of video & audio demo reels, self-tapings, recordings, {{numPhotos}} photos, EPKs, Bios and multiple resumes with multiple agents.',
                    { mediaMinutes: Math.floor((mediaLimit * 0.03) / 10) * 10, numPhotos }
                  ) : [...Array(3)].map((_, index) => <Skeleton key={index} />)}
                </Typography>
              </Description>
              <Description>
                <Typography component="span" variant="bodyBold">
                  {t('Actor.App')}:
                </Typography>
                &nbsp;
                <Typography color="medGrey" component="span">
                  {t('Imagine jobs with sides that you can click, record and edit a self-tape and submit instantly without leaving the app.')}
                </Typography>
              </Description>
              <Description>
                <Typography component="span" variant="bodyBold">
                  {t('CWBLabs')}:
                </Typography>
                &nbsp;
                <Typography color="medGrey" component="span">
                  {t(
                    `Casting Workbook's new integrated e-learning platform! Get unlimited access to live & previously recorded interviews with top casting directors & industry professionals. Access coaching services from the best in the business*.`
                  )}
                </Typography>
              </Description>
              <Typography color="medGrey" variant="caption">
                *{t('small additional per session fee')}
              </Typography>
            </MembershipDescription>
            <StyledActorSignUpForm
              currencySymbol={currencySymbol}
              yearlyPerMonthCharge={yearlyPerMonthCharge}
            />
          </StyledCard>
          <Footnote align="center" color="white">
            {t(
              'Are you a Casting Director or Agent? Learn more about how Casting Workbook can support you.'
            )}
          </Footnote>
          <LinksContainer>
            <StyledReactRouterLink to={`/register/agent?${params}`}>
              {t('Agents & Managers')}
            </StyledReactRouterLink>
            <StyledReactRouterLink to={`/register/casting?${params}`}>
              {t('Casting Directors')}
            </StyledReactRouterLink>
          </LinksContainer>
        </StyledDiv>
      </Page>
    </>
  );
};

const StyledDiv = styled.div`
  width: 696px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: ${(p) => p.theme.spacing(4, 'auto', 0)};

  @media all and (max-width: 520px) {
    width: unset;
    margin-top: ${(p) => p.theme.spacing(3)};
    padding: ${(p) => p.theme.spacing(0, 2)};
  }
`;

const Subheading = styled(Typography)`
  margin: ${(p) => p.theme.spacing(2, 0, 5)};

  @media all and (max-width: 520px) {
    margin: ${(p) => p.theme.spacing(1, 0, 4)};
  }
`;

const StyledCard = styled(Card)`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  margin-bottom: ${(p) => p.theme.spacing(4)};
  padding: 0;

  @media all and (max-width: 520px) {
    flex-flow: column nowrap;
  }
`;

const MembershipDescription = styled.div`
  width: 50%;
  padding: ${(p) => p.theme.spacing(4, 3.5, 3)};
  background: #F8F9FB;
  border-bottom-left-radius: 4px;

  @media all and (max-width: 520px) {
    width: 100%;
    padding: ${(p) => p.theme.spacing(4, 3, 3)};
  }
`;

const HorizontalSeparator = styled.div`
  width: 72px;
  height: 3px;
  background: #02B8F9;
  margin-bottom: ${(p) => p.theme.spacing(3)};
`;

const Description = styled.div`
  margin-bottom: ${(p) => p.theme.spacing(3)};
  line-height: 18px;

  & > span {
    line-height: 18px;
  }
`;

const StyledActorSignUpForm = styled(ActorSignUpForm)`
  width: 50%;

  @media all and (max-width: 520px) {
    width: 100%;
  }
`;

const Footnote = styled(Typography)`
  margin-bottom: ${(p) => p.theme.spacing(2)};
  line-height: 19px;
`;

const LinksContainer = styled.div`
  margin-bottom: ${(p) => p.theme.spacing(6)};

  & > a + a {
    margin-left: ${(p) => p.theme.spacing(6)};
  }
`;

const StyledReactRouterLink = styled(ReactRouterLink)`
  && {
    color: #02B8F9;
    font-weight: bold;

    &:hover {
      color: #02B8F9;
      filter: brightness(1.15);
    }
  }
`;

type StateProps = {
  currencySymbol: string;
  mediaLimit: number;
  yearlyPerMonthCharge: number;
  numPhotos: number;
};

const mapStateToProps = (state: any) => ({
  currencySymbol: state.premiumModel.currencySymbol,
  mediaLimit: state.premiumModel.mediaLimit,
  numPhotos: state.premiumModel.headshot.premium,
  yearlyPerMonthCharge: state.premiumModel.yearlyPerMonthCharge
});

type DispatchProps = {
  getSubscriptionServices: () => void;
};

const mapDispatchToProps = (dispatch: any) => ({
  getSubscriptionServices: dispatch.premiumModel.getSubscriptionServices
});

export default withI18n()(connect(
  mapStateToProps,
  mapDispatchToProps
)(ActorSignUp));
