import React from "react";
import { Elements } from "react-stripe-elements";
import { withI18n } from "react-i18next";

interface Props {}

class StripeActorPaymentWrapper extends React.Component<Props, any> {
  render() {
    return (
      <Elements>
        {this.props.children}
      </Elements>
    );
  }
}

export default withI18n()(StripeActorPaymentWrapper);
