import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { getI18n, withI18n } from 'react-i18next';
import styled from 'styled-components';

import { Skeleton } from 'components/Common';
import Tooltip from "../Common/Tooltip";
import { IPremiumFeatureComparison } from "../../shared/api/dtos/IPremiumDto";

interface Props extends StateProps, DispatchProps {
  t: any;
}

const UpgradeTable: React.FC<Props> = ({
  t,
  monthlyCharge,
  yearlyCharge,
  headshot,
  resume,
  mediaLimit,
  getSubscriptionServices,
  currencySymbol,
}) => {
  useEffect(() => {
    const fetchSubscriptionServices = async () => {
      try {
        await getSubscriptionServices();
      } catch (e) {
        window.top.location.href = process.env.REACT_APP_CWB_500;
      }
    };

    fetchSubscriptionServices();
  }, [getSubscriptionServices]);

  const isMobile = window.matchMedia("(max-width: 520px)").matches;

  const tickIcon = <Icon alt="check" src="../images/icon-tick.svg" />;

  const getServiceNum = (num: number) => {
    if (num >= 99) return tickIcon;
    return num;
  };

  const getMediaLimitDisplay = (mediaLimit: number) => {
    const numData =  mediaLimit.toString().charAt(0);
    return t("{{numData}}GB of Data", { numData });
  };

  const jobHoverTip = (
    <StyledTooltip
      placement="top"
      tip={t("Actors can only submit to direct to actor postings")}
    >
      <img alt="more info" src="../images/info.svg" />
    </StyledTooltip>
  );

  const premiumHoverTip = (
    <StyledTooltip
      placement="top"
      tip={t("1:1 Coaching, AMAs, Guest Workshops, and much more!")}
    >
      <img alt="more info" src="../images/info.svg" />
    </StyledTooltip>
  );

  const tableContent: any = [
    [
      t("What you get"),
      t("Free"),
      (isMobile
        ? t("Pro") // eslint-disable-next-line
        : t(
          "Pro ({{currencySymbol}}{{monthlyCharge}}mo / {{currencySymbol}}{{yearlyCharge}}yr)",
          { currencySymbol, monthlyCharge, yearlyCharge }
        ))
    ],
    [
      t("Headshots"),
      getServiceNum(headshot.free),
      getServiceNum(headshot.premium)
    ],
    [
      t("Resumes"),
      getServiceNum(resume.free),
      getI18n().language === "es"
      ? resume.premium >= 99
        ? "Múltiples"
        : resume.premium
      : getServiceNum(resume.premium)
    ],
    [<>{t("Job Submissions")}{jobHoverTip}</>, "-", tickIcon],
    [
      <>{t("Media Content")} ({getMediaLimitDisplay(mediaLimit)})</>,
      "-",
      tickIcon
    ],
    [<>{t("CWB Premium Content")}{premiumHoverTip}</>, "-", tickIcon],
    [t("Send Materials / Epitch"), "-", tickIcon],
    [t("Scripts & Sides"), "-", tickIcon]
  ];

  const isLoading = !(
    monthlyCharge ||
    yearlyCharge ||
    headshot.free ||
    headshot.premium ||
    resume.free ||
    resume.premium ||
    mediaLimit
  );

  return (
    <StyledTable>
      <thead>
        <tr>
          {tableContent[0].map((tableHeader: any, index: number) => (
            <th key={index}>
              {isLoading ? <StyledSkeleton height={18} /> : tableHeader}
            </th>
          ))}
        </tr>
      </thead>
      <tbody>
        {tableContent.slice(1).map((row: any, index: number) => (
          <tr key={index}>
            {row.map((tableData: any, index: number) => (
              <td key={index}>
                {isLoading ? <StyledSkeleton height={18} /> : tableData}
              </td>
            ))}
          </tr>
        ))}
      </tbody>
    </StyledTable>
  );
};

const StyledTable = styled.table`
  width: 100%;

  & > thead > tr {
    color: #353B48;
    background-color: #f8f8f8;
    border-bottom: 1px solid #f1f2f4;
    border-radius: 3px 3px 0 0;
  }

  & > tbody > tr {
    color: #53627C;
    &:nth-child(even) { background-color: #f1f2f4; }
  }

  & th, & td {
    font-size: 14px;
    &:first-child {
      width: 295px;
      padding-left: 16px;
      @media all and (max-width: 520px) {
        width: 190px;
        padding-left 8px;
      }
    }
    &:not(:first-child) { text-align: center; }
  }

  & tr { height: 53px; }
  & th { font-weight: bold; }
  & td { font-weight: 600; }
`;

const Icon = styled.img`
  width: 20px;
  height: 20px;
  padding: 4px;
  background-color: ${p => p.theme.lightBlue};
  border-radius: 50%;
`;

const StyledTooltip = styled(Tooltip)`
  margin-left: 5px;
  & > img {
    width: 18px;
    height: 18px;
    margin-top: -2px;
  }
`;

const StyledSkeleton = styled(Skeleton)`
  width: 80%;
`;

interface StateProps {
  monthlyCharge: number;
  yearlyCharge: number;
  headshot: IPremiumFeatureComparison;
  resume: IPremiumFeatureComparison;
  mediaLimit: number;
  currencySymbol: string,
}

const mapStateToProps = (state: any) => ({
  monthlyCharge: state.premiumModel.monthlyCharge,
  yearlyCharge: state.premiumModel.yearlyCharge,
  headshot: state.premiumModel.headshot,
  resume: state.premiumModel.resume,
  mediaLimit: state.premiumModel.mediaLimit,
  currencySymbol: state.premiumModel.currencySymbol,
});

interface DispatchProps {
  getSubscriptionServices: () => void;
}

const mapDispatchToProps = (dispatch: any) => ({
  getSubscriptionServices: dispatch.premiumModel.getSubscriptionServices
});

export default withI18n()(connect(
  mapStateToProps,
  mapDispatchToProps
)(UpgradeTable));
