import React from 'react';
import { withI18n } from 'react-i18next';
import styled from 'styled-components';
import { Typography } from 'components/Common';
import Card from './components/Card';
import Header from './components/Header';
import Page from './components/Page';
import PerformerSignUpForm from './components/PerformerSignUpForm';
import { setBrowserLang } from 'helpers';

type Props = {
  t: any;
};

const PerformerSignUp: React.FC<Props> = ({ t }) => {
  setBrowserLang('es');

  return (
    <>
      <Header />
      <Page>
        <StyledDiv>
          <Typography
            align="center"
            color="white"
            component="h1"
            variant="h1"
          >
            {t('Sign up for your Casting Workbook Labs Account!')}
          </Typography>
          <Subheading
            align="center"
            color="white"
            variant="h2"
          >
            {t(
              'Get access to all the educational account Casting Workbook has to offer. Free-for a limited time!'
            )}
          </Subheading>
          <StyledCard>
            <MembershipDescription>
              <Typography component="h2" gutterBottom variant="h3">
                {t('Your membership includes')}:
              </Typography>
              <HorizontalSeparator />
              <Description>
                <Typography component="span" variant="bodyBold">
                  {t('Los Apuntes de Casting Workbook')}:
                </Typography>
                &nbsp;
                <Typography color="medGrey" component="span">
                  {t('weekly show on what is going on in the industry.')}
                </Typography>
              </Description>
              <Description>
                <Typography component="span" variant="bodyBold">
                  {t('The Working Actor')}:
                </Typography>
                &nbsp;
                <Typography color="medGrey" component="span">
                  {t('weekly Interviews, Tutorials and Workshops with top industry professionals-free!')}
                </Typography>
              </Description>
              <Description>
                <Typography component="span" variant="bodyBold">
                  {t('CWBLive')}:
                </Typography>
                &nbsp;
                <Typography color="medGrey" component="span">
                  {t('access 1:1 coaching from the best in the business (additional cost per session).')}
                </Typography>
              </Description>
              <Tag>
                {t('COMING SOON')}
              </Tag>
              <Description>
                <Typography component="span" variant="bodyBold">
                  {t('Casting Workbook Lessons')}:
                </Typography>
                &nbsp;
                <Typography color="medGrey" component="span">
                  {t('Tailored courses to hone your craft and grow your career (additional cost per course).')}
                </Typography>
              </Description>
            </MembershipDescription>
            <StyledPerformerSignUpForm />
          </StyledCard>
        </StyledDiv>
      </Page>
    </>
  );
};

const StyledDiv = styled.div`
  width: 696px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: ${(p) => p.theme.spacing(5, 'auto', 0)};

  @media all and (max-width: 520px) {
    width: unset;
    margin-top: ${(p) => p.theme.spacing(3)};
    padding: ${(p) => p.theme.spacing(0, 2)};
  }
`;

const Subheading = styled(Typography)`
  margin: ${(p) => p.theme.spacing(2, 0, 5)};
  font-weight: normal;

  @media all and (max-width: 520px) {
    margin: ${(p) => p.theme.spacing(1, 0, 4)};
  }
`;

const StyledCard = styled(Card)`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  margin-bottom: ${(p) => p.theme.spacing(4)};
  padding: 0;

  @media all and (max-width: 520px) {
    flex-flow: column nowrap;
  }
`;

const MembershipDescription = styled.div`
  width: 50%;
  padding: ${(p) => p.theme.spacing(4, 3.5)};
  background: #F8F9FB;
  border-bottom-left-radius: 4px;

  @media all and (max-width: 520px) {
    width: 100%;
    padding: ${(p) => p.theme.spacing(4, 3, 1)};
  }
`;

const HorizontalSeparator = styled.div`
  width: 72px;
  height: 3px;
  background: #02B8F9;
`;

const Description = styled(Typography)`
  margin-bottom: ${(p) => p.theme.spacing(3)};
  line-height: 18px;

  &:first-of-type {
    margin-top: ${(p) => p.theme.spacing(3)};
  }

  & > span {
    line-height: 18px;
  }
`;

const Tag = styled.div`
  width: max-content;
  margin-bottom: ${(p) => p.theme.spacing(1)};
  padding: ${(p) => p.theme.spacing('1px', 1)};
  color: ${(p) => p.theme.palette.common.white};
  background-color: #FF1870;
  border-radius: 4px;
  font-size: 12px;
  font-weight: bold;
`;

const StyledPerformerSignUpForm = styled(PerformerSignUpForm)`
  width: 50%;

  @media all and (max-width: 520px) {
    width: 100%;
  }
`;

export default withI18n()(PerformerSignUp);
