import React from 'react';
import { withI18n } from 'react-i18next';
import PhoneInput from "react-phone-input-2";
import styled from 'styled-components';
import { Button, Typography } from 'components/Common';
import { Spinner } from 'components/Common/StyledComponents';
import Card from './components/Card';
import Footer from './components/Footer';
import Page from './components/Page';

type Props = {
  t: any;
  errors: any;
  isLoading: boolean;
  state: any;
  onChange: (phoneNumber: string) => void;
  createAccount: (event: any) => void;
};

const ActorCreatePhoneStep: React.FC<Props> = ({
  t,
  errors,
  isLoading,
  state,
  onChange: handleChange,
  createAccount
}) => {
  return (
    <StyledPage>
      <StyledCard>
        <Typography align="center" component="h1" gutterBottom variant="h2">
          {t('Let’s add your phone number for your security')}
        </Typography>
        <Typography align="center" color="medGrey">
          {t(`We'll send you a confirmation code to verify your phone number.`)}
        </Typography>
        <Form onSubmit={createAccount}>
          <FormLabel>
            {t('Phone Number')}
            <PhoneInput
              containerStyle={{ marginTop: "8px" }}
              country={state.countryCode}
              value={state.fields.phone}
              onChange={handleChange}
            />
          </FormLabel>
          <Typography color="error" variant="captionBold">
            {errors.Phone}
          </Typography>
          <StyledTypography align="center" color="medGrey">
            {t(`We only use this to verify your account. We'll never contact you without your permission.`)}
          </StyledTypography>
          <StyledButton
            startIcon={isLoading && (
              <Spinner className="spinner-width" src="../images/spinner.svg" />
            )}
          >
            {t('Send Confirmation Code')}
          </StyledButton>
        </Form>
        <Typography align="center" color="darkGrey" variant="caption">
          {t('Standard message rates apply.')}
        </Typography>
      </StyledCard>
      <StyledFooter />
    </StyledPage>
  );
};

const StyledPage = styled(Page)`
  @media all and (max-width: 520px) {
    padding: ${(p) => p.theme.spacing(10, 2, 0)};
  }
`;

const StyledCard = styled(Card)`
  width: 480px;
  padding: ${(p) => p.theme.spacing(4, 10)};

  @media all and (max-width: 520px) {
    width: 100%;
    padding: ${(p) => p.theme.spacing(4, 2)};
  }
`;

const Form = styled.form`
  width: 100%;
`;

const FormLabel = styled.label`
  width: 100%;
  color: ${(p) => p.theme.typography.color.darkGrey};
  margin-top: ${(p) => p.theme.spacing(2)};
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
`;

const StyledTypography = styled(Typography)`
  background-color: #F8F8F9;
  margin: ${(p) => p.theme.spacing(2, 0, 3)};
  padding: ${(p) => p.theme.spacing(2)};
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-bottom: ${(p) => p.theme.spacing(2)};
`;

const StyledFooter = styled(Footer)`
  margin-top: ${(p) => p.theme.spacing(3)};
`;

export default withI18n()(ActorCreatePhoneStep);
