// @ts-ignore
import { CWBAPI, setUserAuthToken } from "cwb-react";
// @ts-ignore
import { moment, getCurUser, setUserInfo } from "cwb-react";
import { client } from "../shared/apiClient";
import { getUserLanguageChoice, getWebSessionKey, localeKeys, setBrowserLang } from "../helpers";

import i18n from "../i18n";

const sharedApiPromise = import(
  `cwb-react/dist/${process.env.REACT_APP_CWB_API_FILE}`
);

const setupCwbApi = async () => {
  const sharedApi = await sharedApiPromise;
  const setup = { ...sharedApi.default };
  client.api = new CWBAPI(setup, { cwb: process.env.REACT_APP_Api }, false);
};

export const getUser = async (jwt: string) => {
  try {
    setUserAuthToken(jwt);
    await setupCwbApi();
    return await client.api.user.getCurrentUser();
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const setUser = async (jwt: string) => {
  try {
    const userResp = await getUser(jwt);
    setUserInfo(userResp.data);
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const bridgeAuthentication = async (k: string): Promise<boolean> => {
  try {
    if (!k) {
      k = getWebSessionKey();
      if (!k) return false;
    }
    await setupCwbApi();
    const tokenResp = await client.api.user.postBridgeAuthTokenFromSession(k);
    const authToken = tokenResp && tokenResp.data && tokenResp.data.accessToken;
    if (authToken) {
      setUserAuthToken(authToken);
      const userResp = await client.api.user.getCurrentUser();
      setUserInfo(userResp.data);
      return true;
    }
    return false;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const seamlessAuthentication = async (): Promise<any> => {
  try {
    await setupCwbApi();

    const tokenResp = await client.api.user.getAuthToken();
    const authToken = tokenResp && tokenResp.data && tokenResp.data.accessToken;

    if (authToken) {
      setUserAuthToken(authToken);
      const userResp = await client.api.user.getCurrentUser();
      setUserInfo(userResp.data);
      return authToken;
    }

    return null;
  } catch (e) {
    console.error(e);
    throw e;
  }
};

export const setLanguage = () => {
  try {
    const user = getCurUser();
    if (!user) {
      setBrowserLang();
      return;
    }
    const preferredLanguage = getUserLanguageChoice(
      localeKeys,
      user.languageChoice
    );
    if (Object.keys(localeKeys).includes(preferredLanguage)) {
      i18n.changeLanguage(preferredLanguage);
      moment.moment.locale(preferredLanguage);
    }
  } catch (e) {
    console.error(e);
    setBrowserLang();
  }
};
