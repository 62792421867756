import { createModel } from "@rematch/core";
import * as ServicesAPI from "../shared/api/Services.api";
import { ISubscriptionService } from "../shared/api/dtos/IServicesDto";

const servicesModel = createModel({
  state: {
    isLoading: false as boolean,
    services: [] as ISubscriptionService[],
  },
  reducers: {
    setIsLoading: (state: any, isLoading: boolean) => ({
      ...state,
      isLoading,
    }),
    setServices: (state: any, services: ISubscriptionService[]) => ({
      ...state,
      services,
    }),
  },
  effects: (dispatch: any) => ({
    getPaidServicesSignup: async (actorSignUpId) => {
      dispatch.servicesModel.setIsLoading(true);
      try {
        const response = await ServicesAPI.getPaidServicesSignup(actorSignUpId);
        dispatch.servicesModel.setServices(response.data);
      } catch (e) {
      } finally {
        dispatch.servicesModel.setIsLoading(false);
      }
    },
    getPaidServicesClient: async () => {
      dispatch.servicesModel.setIsLoading(true);
      try {
        const response = await ServicesAPI.getPaidServicesClient();
        dispatch.servicesModel.setServices(response.data);
      } catch (e) {
      } finally {
        dispatch.servicesModel.setIsLoading(false);
      }
    },
  }),
});

export default servicesModel;
