import React, {useState} from 'react';
import { connect } from 'react-redux';
import { withI18n } from 'react-i18next';
import {RouteComponentProps} from 'react-router-dom';
import styled from "styled-components";
import Modal from "../../Common/Modal";
import PageContainer from "../../Common/PageContainer";
import BackButton from "../../Common/BackButton";
import {
  TrialDivider, StyledBlueBtn, Spinner, Section
} from "../../Common/StyledComponents";
import qs from 'query-string';

type Props = {
  t: any;
} & DispatchProps & RouteComponentProps;

const ActorTrialCheckout: React.FC<Props> = ({
  t,
  history,
  location,
}) => {
  const backToSignUp = () => {
    const params = qs.stringify({
      ...(qs.parse(location.search))
    });
    history.push(`/register/special/actor?${params}`);
  }

  const isTablet = window.matchMedia("(max-width: 768px)").matches;

  return (
    <PageContainer >
    <BackButton width={isTablet ? 690 : 880} />
        <Wrapper >
          <StyledTitle>{t("Welcome! Let’s begin your free trial.")}</StyledTitle>
          <StyledSubTitle>
            {t("Get access to everything Casting Workbook has to offer until Sept 30th, 2021")}
          </StyledSubTitle>
          <StyledSubTitle>
            {t("*no credit card necessary to enroll")}
          </StyledSubTitle>
          <FeatureWrapper>
            <Feature>
              <FeatureIcon src="/images/trial_headshot.svg" />
              <FeatureDetail>
                  <FeatureTitle>
                    {t("Headshots")}
                      Headshots
                  </FeatureTitle>
                  <FeatureContent>
                    {t("16 Headshots and unlimited Resumes (no update fee).")}
                  </FeatureContent>
              </FeatureDetail>
            </Feature>
            <TrialDivider></TrialDivider>
            <Feature>
              <FeatureIcon src="/images/trial_media.svg" />
              <FeatureDetail>
                  <FeatureTitle>
                    {t("Unlimited Demo Reels")}
                  </FeatureTitle>
                  <FeatureContent>
                    {t("For real! Let us show you how.")}
                  </FeatureContent>
              </FeatureDetail>
            </Feature>
            <TrialDivider></TrialDivider>
            <Feature>
              <FeatureIcon src="/images/trial_resume.svg" />
              <FeatureDetail>
                  <FeatureTitle>
                    {t("Auditions and Jobs")}
                  </FeatureTitle>
                  <FeatureContent>
                    {t("Receive audition notices in your app. In addition, our new Jobs database is also integrated and we are about to add several new regions.")}
                  </FeatureContent>
              </FeatureDetail>
            </Feature>
            <TrialDivider></TrialDivider>
            <Feature>
              <FeatureIcon src="/images/trial_pitch.svg" />
              <FeatureDetail>
                  <FeatureTitle>
                  {t("Profile Pitching")}
                  </FeatureTitle>
                  <FeatureContent>
                    {t("Epitch allows you to share & track your Casting Workbook materials.")}
                  </FeatureContent>
              </FeatureDetail>
            </Feature>
            <TrialDivider></TrialDivider>
            <Feature>
              <FeatureIcon src="/images/trial_virtual.svg" />
                  <FeatureTitleSingle>
                    {t("Virtual casting notices and preparation from Actor .App")}
                  </FeatureTitleSingle>
            </Feature>
            <TrialDivider></TrialDivider>
            <Feature>
              <FeatureIcon src="/images/trial_tech.svg" />
              <FeatureDetail>
                  <FeatureTitle>
                    {t("Tech Checks")}
                  </FeatureTitle>
                  <FeatureContent>
                    {t(" Self taping is here to stay. Make an appointment and let us tweak your home setup to make sure you look your best.")}
                  </FeatureContent>
              </FeatureDetail>
            </Feature>
            <TrialDivider></TrialDivider>
            <Feature>
              <FeatureIcon src="/images/trial_actor.svg" />
              <FeatureDetail>
                  <FeatureTitle>
                    {t("The Working Actor")}
                  </FeatureTitle>
                  <FeatureContent>
                    {t("Full access to our growing library of casting, agent and production interview series.")}
                  </FeatureContent>
              </FeatureDetail>
            </Feature>
          </FeatureWrapper>
          <Section>
          <TrailStyledBlueBtn onClick={backToSignUp}
            >
             {t("Get started for free")}
            </TrailStyledBlueBtn>
          </Section>
      </Wrapper>
    </PageContainer>
    
  );
};

interface DispatchProps {
  /* setClientId: (clientId: string) => void;
  createTrialUser:(actorSignUpId: number) => any; */
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    /* setClientId: dispatch.actorModel.setClientId,
    createTrialUser:dispatch.actorModel.createTrialUser */
  };
};

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  * {
    color: ${(p) => p.theme.color};
    font-size: ${(p) => p.theme["s-font-size"]};
  }

  width: 880px;
  padding: 40px 60px;

  @media all and (max-width: 768px) {
    width: 690px;
    margin: 0;
    padding: 24px 24px 40px;
  }

  @media all and (max-width: 520px) {
    width: 100%;
    margin: 0;
    padding: 24px 16px 40px;
  }
`;

const StyledTitle = styled.div`
  font-size: ${(p) => p.theme["xxl-font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};
  margin-bottom: 16px;
  letter-spacing: 0.374px;
`;

const StyledSubTitle = styled.div`
font-size: ${(p) => p.theme["font-size"]};
letter-spacing: 0.374px;
line-height: 18px;
`;

const FeatureWrapper = styled.div`
width:416px;
displat:flex;
flex-direction:column;
margin:40px auto;
`;

const Feature = styled.div`
width:100%;
display:flex;
`;

const FeatureIcon = styled.img`
height:20px;
width:20px;
margin-right:12px;
`;

const FeatureDetail = styled.div`
display:flex;
flex-direction:column;
`;

const FeatureTitle = styled.div`
font-size: ${(p) => p.theme["font-size"]};
letter-spacing: 0.374px;
line-height: 18px;
`;

const FeatureTitleSingle = styled.div`
font-size: ${(p) => p.theme["font-size"]};
letter-spacing: 0.374px;
line-height: 20px;
`;

const FeatureContent = styled.div`
font-size: ${(p) => p.theme["font-size"]};
letter-spacing: 0.374px;
line-height: 18px;
color:${(p) => p.theme.placeHolderColor}
`;

const TrailStyledBlueBtn = styled(StyledBlueBtn)`
width:166px;
height:35px;
margin:0 auto;
`;

export default withI18n()(connect(null, mapDispatchToProps)(ActorTrialCheckout));