import { setCurUserSignUp } from "../helpers";
import { createModel } from "@rematch/core";
import { IAgentError, IAgentUser } from "../shared/api/dtos/IAgentDtos";
import * as AgentRegistrationApi from "../shared/api/AgentRegistration.api";
import {
  IApiResponseError,
  IError
} from "../shared/api/dtos/IApiRepsonseError";

const agentModel = createModel({
  state: {
    isLoading: false,
    sessionId: "",
    agentUser: {} as IAgentUser,
    errors: {} as IAgentError
  },
  reducers: {
    setIsLoading: (state: any, isLoading: boolean) => ({
      ...state,
      isLoading
    }),
    setErrors: (state: any, errors: IAgentError) => ({
      errors
    })
  },
  effects: (dispatch: any) => ({
    async createAgentAccount(dto: IAgentUser, state: any) {
      dispatch.agentModel.setIsLoading(true);
      await AgentRegistrationApi.createAgentAccount(dto)
        .then((resp: any) => {
          dispatch.agentModel.setIsLoading(false);
          let agentDto: IAgentUser = resp!.data;
          if (agentDto) {
            dispatch.agentModel.setErrors({} as IAgentError);
            setCurUserSignUp(null); // clear user in case pick diff account type and went through one step
            setCurUserSignUp(agentDto);
          }
        })
        .catch(resp => {
          setCurUserSignUp(null);
          let apiResponseError: IApiResponseError = resp;
          if (apiResponseError.errors) {
            let apiErrors: IError[] = resp.errors;
            let errors = {} as IAgentError;
            apiErrors.forEach((k: IError) => (errors[k.field] = k.message));
            dispatch.agentModel.setErrors(errors);
          } else if (apiResponseError.message) {
            dispatch.agentModel.setErrors({
              errorMessage: apiResponseError.message
            });
          }
          console.log(resp);
        });
    }
  })
});

export default agentModel;
