import React from "react";
import { connect } from "react-redux";
import { RouteComponentProps } from "react-router-dom";
import Membership from "../Common/Membership";
import { getCountryCode } from "../../helpers";
import { iRootState } from "../../store";
import { IStripeCharge } from "../../shared/api/dtos/IStripeDto";

interface Props extends RouteComponentProps, StateProps, DispatchProps {}

const ActorUpgradePayment: React.FC<Props> = ({
  history,
  location,
  authenticatedUser,
  getServices,
  upgradeFreemium,
}) => {
  const fetchServices = async () => {
    await getServices();
  };

  const handleSubmit = async (apiPayload: IStripeCharge) => {
    await upgradeFreemium(apiPayload);
  };

  const moveToNext = (serviceId: number) => {
    history.push({
      pathname: "/actor/payment-processing",
      search: location.search,
      state: {
        successPath: "/actor/upgrade-completed",
        serviceId,
      },
    });
  };

  return (
    <Membership
      country={getCountryCode()}
      provinceId={authenticatedUser.provinceId}
      getServices={fetchServices}
      onSubmit={(apiPayload) => handleSubmit(apiPayload)}
      moveToNext={moveToNext}
    />
  );
};

interface StateProps {
  authenticatedUser: any;
}

const mapStateToProps = (state: iRootState) => ({
  authenticatedUser: state.appModel.authenticatedUser,
});

interface DispatchProps {
  getServices: () => void;
  upgradeFreemium: (apiPayload: IStripeCharge) => void;
}

const mapDispatchToProps = (dispatch: any) => ({
  getServices: dispatch.servicesModel.getPaidServicesClient,
  upgradeFreemium: dispatch.stripeModel.upgradeFreemium,
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ActorUpgradePayment);
