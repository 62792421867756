import React, { useEffect } from "react";
import { connect } from "react-redux";
import { Redirect, Route, RouteProps } from "react-router-dom";
import { iRootState } from "../store";
import styled, { css } from "styled-components";
import qs from "query-string";
import CWBLabsHeader from "./CWBLabs/components/Header";
import HeaderComponent from "./HeaderComponent";
import { TrackingData } from "shared/api/dtos/ITrackingDataDto";

interface Props extends RouteProps, StateProps {
  requireAuth?: boolean;
}

const RouteWrapper: React.FC<Props> = ({
  actorProfile,
  authenticatedUser,
  requireAuth,
  sessionData,
  component: Component,
  ...rest
}) => {
  useEffect(() => {
    setTrackingData();
  }, []);

  if (requireAuth) {
    if (!authenticatedUser) {
      const queryString = qs.stringify({
        ...qs.parse(rest.location.search),
        page: "upgrade",
      });
      window.location.replace(
        `${process.env.REACT_APP_CWB_Actor_Login}?${queryString}`
      );
      return <></>;
    }

    if (authenticatedUser.id.userTypeId !== 8) {
      return (
        <Redirect
          to={{
            pathname: "/register",
            search: rest.location.search,
          }}
        />
      );
    }

    if (
      actorProfile &&
      !actorProfile.servicesPlan.isFreemium &&
      !actorProfile.servicesPlan.canUpgrade
    ) {
      const queryString = qs.stringify({
        tk: sessionData.WebSessionKey,
        cid: authenticatedUser.id.userId,
      });
      const homePath = `${process.env.REACT_APP_CWB_Site}/actorsworkbook/default.asp?${queryString}`;
      window.location.replace(homePath);
      return <></>;
    }
  }

  const params = qs.parse(rest.location.search);
  const showHeader = !params.hs;
  const isCWBLabs = params.cwblabs;

  const setTrackingData = () => {
    let trackingData = {} as TrackingData;
    if (params.src) trackingData.queryParamSource = params.src as string;
    if (rest.location.search) trackingData.queryParams = rest.location.search;
    localStorage.setItem("tData", JSON.stringify(trackingData));
  };

  return (
    <>
      {showHeader && (isCWBLabs ? <CWBLabsHeader /> : <HeaderComponent />)}
      <Wrapper isCWBLabs={isCWBLabs} showHeader={showHeader}>
        <Route component={Component} {...rest} />
      </Wrapper>
    </>
  );
};

const Wrapper: any = styled.div`
  display: flex;
  padding-top: 60px;

  ${(p: any) =>
    p.isCWBLabs &&
    css`
      padding-top: 80px;
    `}

  ${(props: any) =>
    !props.showHeader &&
    css`
      padding-top: 0;
    `}

  @media all and (max-width: 520px) {
    flex-direction: column;
  }
`;

interface StateProps {
  actorProfile: any;
  authenticatedUser: any;
  sessionData: any;
}

const mapStateToProps = (state: iRootState) => ({
  actorProfile: state.appModel.actorProfile,
  authenticatedUser: state.appModel.authenticatedUser,
  sessionData: state.appModel.sessionData,
});

export default connect(mapStateToProps, null)(RouteWrapper);
