import { createModel } from '@rematch/core';
import * as StripeCustomerAPI from '../shared/api/StripeCustomer.api';
import { IPaymentDetailsDto } from '../shared/api/dtos/IPaymentDetailsDto';
import { ICustomerPortalDto } from '../shared/api/dtos/ICustomerPortalDto';

const stripeCustomerModel = createModel({
  state: {
    isLoading: false as boolean,
    paymentDetails: {} as IPaymentDetailsDto,
    customerPortal: {} as ICustomerPortalDto
  },
  reducers: {
    setIsLoading: (state: any, isLoading: boolean) => ({
      ...state,
      isLoading
    }),
    setPaymentDetails: (state: any, paymentDetails: IPaymentDetailsDto) => ({
      ...state,
      paymentDetails
    }),
    setCustomerPortal: (state: any, customerPortal: ICustomerPortalDto) => ({
      ...state,
      customerPortal
    })
  },
  effects: (dispatch: any) => ({    
    getCustomerPaymentDetails: async () => {
      dispatch.stripeCustomerModel.setIsLoading(true);
      try {
        const response = await StripeCustomerAPI.getCustomerPaymentDetails();
        dispatch.stripeCustomerModel.setPaymentDetails(response.data);
      } catch (e) {} finally {
        dispatch.stripeCustomerModel.setIsLoading(false);
      }
    },
    getCustomerPortal: async () => {
      dispatch.stripeCustomerModel.setIsLoading(true);
      try {
        const response = await StripeCustomerAPI.getCustomerPortal();
        dispatch.stripeCustomerModel.setCustomerPortal(response.data);
      } catch (e) {} finally {
        dispatch.stripeCustomerModel.setIsLoading(false);
      }
    }
  })
});

export default stripeCustomerModel;
