import React from "react";
import { withI18n } from "react-i18next";
import styled from "styled-components";
import { withRouter } from "react-router";
import { RouteComponentProps } from "react-router-dom";

interface IProps extends RouteComponentProps<any> {
  t?: any;
}

class FailConfirmEmailComponent extends React.Component<IProps, {}> {
  async componentDidMount() {
    this.props.history.push(this.props.location.pathname, { step: 4 });
    window.onpopstate = () => {
      this.props.history.go(1);
    };
  }

  componentWillUnmount() {
    window.onpopstate = () => {};
  }

  render() {
    const { t } = this.props;
    return (
      <Wrapper>
        <StyledTitle>{t("We couldn’t verify your email address")}</StyledTitle>
        <StyledSubTitle>
          {t(
            "There was a problem while trying to verify your email address, please contact us to activate your account."
          )}
        </StyledSubTitle>
        <StyledTitle>
          <LoginButton href="http://home.castingworkbook.com/contacts/">
            {t("Contact Us")}
          </LoginButton>
        </StyledTitle>
      </Wrapper>
    );
  }
}

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;

  max-width: 480px;
  min-width: 480px;
  background-color: ${p => p.theme.white};
  margin: 40px auto 60px auto;
  padding: 40px 50px;
  text-align: center;

  * {
    font-size: ${p => p.theme["s-font-size"]};
  }

  @media all and (max-width: 520px) {
    width: 90%;
    min-width: 40%;
    padding: 40px;
  }
`;

const StyledTitle = styled.div`
  width: 100%;
  font-size: ${p => p.theme["xxl-font-size"]};
  font-weight: ${p => p.theme["font-weight-600"]};
  padding: 0 30px;
`;

const StyledSubTitle = styled.div`
  color: ${p => p.theme.color} !important;
  padding: 8px 0 24px 0;
`;

const LoginButton = styled.a`
  display: flex;
  justify-content: center;

  color: ${p => p.theme.white} !important;
  font-size: ${p => p.theme["font-size"]};
  font-weight: ${p => p.theme["font-weight-600"]};
  background-color: ${p => p.theme.btnBlue};
  border-radius: 4px;
  padding: 9px 0;
  margin-left: 8px;
`;

export default withI18n()(withRouter(FailConfirmEmailComponent));
