import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Route, RouteProps } from "react-router-dom";
import { iRootState } from "../store";
import styled, { css } from "styled-components";
import qs from "query-string";
import CWBLabsHeader from "./CWBLabs/components/Header";
import HeaderComponent from "./HeaderComponent";
import { setLanguage, seamlessAuthentication } from "../service/UserService";
// @ts-ignore
import { getCurUser, CWBLoadingCrest } from "cwb-react";
// @ts-ignore
import { getSessionDataFromAuthToken } from "cwb-react";
import { setCountryCode } from "../helpers";
import { ICountryDto } from "../shared/api/dtos/IReferencesDto";

interface Props extends RouteProps, StateProps, DispatchProps {
  isUpgrade?: boolean;
}

const SeamlessAuthRoute: React.FC<Props> = ({
  actorProfile,
  authenticatedUser,
  isUpgrade,
  sessionData,
  setAuthenticatedUser,
  setSessionData,
  getCountryList,
  getActorProfile,
  countryList,
  component: Component,
  ...rest
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(true);

  const fallbackLoginRedirect = () =>
    window.location.replace(`${process.env.REACT_APP_CWB_Actor_Login}`);

  const loginUpgradeRedirect = () => {
    const queryString = qs.stringify({
      ...qs.parse(rest.location.search),
      page: "upgrade",
    });
    window.location.replace(
      `${process.env.REACT_APP_CWB_Actor_Login}?${queryString}`
    );
    return <></>;
  };

  const loginProfileRedirect = () => {
    const queryString = qs.stringify({
      tk: sessionData.WebSessionKey,
      cid: authenticatedUser.id.userId,
    });
    const homePath = `${process.env.REACT_APP_CWB_Site}/actorsworkbook/default.asp?${queryString}`;
    window.location.replace(homePath);
    return <></>;
  };

  const isInvalidActorProfile = () => {
    if (
      actorProfile &&
      !actorProfile.servicesPlan.isFreemium &&
      !actorProfile.servicesPlan.canUpgrade
    ) {
      setIsLoading(false);
      loginProfileRedirect();
    }
  };

  useEffect(() => {
    (async function attemptAuth() {
      try {
        if (!authenticatedUser) {
          const authToken = await seamlessAuthentication();

          if (!authToken && isUpgrade) loginUpgradeRedirect();
          else if (!authToken) fallbackLoginRedirect();

          setSessionData(getSessionDataFromAuthToken(authToken));

          const user = getCurUser();
          if ((!user || user.id.userTypeId !== 8) && isUpgrade)
            loginUpgradeRedirect();
          else if (!user) fallbackLoginRedirect();

          // user Id == 8
          await getActorProfile(user.id.userId);
          isInvalidActorProfile();

          setAuthenticatedUser(user);

          await setLanguage();
          await setCountry();

          setIsLoading(false);
        } else {
          if (authenticatedUser.id.userTypeId === 8) {
            await getActorProfile(authenticatedUser.id.userId);
          }

          isInvalidActorProfile();

          await setCountry();
          await setLanguage();

          setIsLoading(false);
        }
      } catch (error) {
        console.error(error);
        setIsLoading(false);
        if (isUpgrade) loginUpgradeRedirect();
        fallbackLoginRedirect();
      }
    })();
  }, []);

  const params = qs.parse(rest.location.search);
  const showHeader = !params.hs;
  const isCWBLabs = params.cwblabs;

  const setBrowserCountry = async () => {
    try {
      const response = await fetch(process.env.REACT_APP_IpInfo);
      const ipData = await response.json();

      // To test below code. Install Opera. In settings, turn on VPN.
      if (ipData && ipData.country_code) {
        setCountryCode(ipData.country_code);
      }
    } catch (e) {}
  };

  const setCountry = async () => {
    try {
      if (!authenticatedUser) {
        await setBrowserCountry();
        return;
      }
      await getCountryList();
      const userCountry = countryList.find((country: ICountryDto) => {
        return country.countryId === authenticatedUser.countryId;
      });
      setCountryCode(userCountry.countryCode);
    } catch (e) {
      await setBrowserCountry();
    }
  };

  return (
    <>
      {showHeader && (isCWBLabs ? (<CWBLabsHeader />) : (<HeaderComponent />))}
      <Wrapper
        isCWBLabs={isCWBLabs}
        isLoading={isLoading}
        showHeader={showHeader}
      >
        {isLoading ? (
          <CWBLoadingCrest customLogoSrc="/images/icon-cwb-logo-grey.svg" />
        ) : (
          <Route component={Component} {...rest} />
        )}
      </Wrapper>
    </>
  );
};

const Wrapper: any = styled.div`
  display: flex;
  padding-top: 60px;

  ${(props: any) =>
    !props.showHeader &&
    css`
      padding-top: 0;
    `}

  ${(p: any) => p.isCWBLabs && css`
    background-color: #040F1C;
    padding-top: 80px;

    ${(p: any) => p.isLoading && css`
      padding-top: 80px;
      padding-bottom: calc(100vh - 80px - 138px);
    `}
  `}

  @media all and (max-width: 520px) {
    flex-direction: column;
  }
`;

interface StateProps {
  actorProfile: any;
  authenticatedUser: any;
  sessionData: any;
  countryList: ICountryDto[];
}

const mapStateToProps = (state: iRootState) => ({
  actorProfile: state.appModel.actorProfile,
  authenticatedUser: state.appModel.authenticatedUser,
  sessionData: state.appModel.sessionData,
  countryList: state.referencesModel.countryList,
});

interface DispatchProps {
  setAuthenticatedUser: (user: any) => any;
  getCountryList: () => any;
  getActorProfile: (clientId: number) => void;
  setSessionData: (sessionData: any) => any;
}

const mapDispatchToProps = (dispatch: any) => ({
  setAuthenticatedUser: dispatch.appModel.setAuthenticatedUser,
  getCountryList: dispatch.referencesModel.getCountryList,
  getActorProfile: dispatch.appModel.getActorProfile,
  setSessionData: dispatch.appModel.setSessionData,
});

export default connect(mapStateToProps, mapDispatchToProps)(SeamlessAuthRoute);
