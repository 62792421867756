import React from "react";
import qs from "query-string";
import { getI18n, withI18n } from "react-i18next";
import styled from "styled-components";
import { connect } from "react-redux";

import { phoneValidation } from "../../../helpers/validations";
import PhoneInput from "react-phone-input-2";
import Footer from "../../Common/Footer";
import Modal from "../../Common/Modal";
import PageContainer from "../../Common/PageContainer";
import {
  Section,
  StyledBlueBtn,
  SectionTitle,
  SmallTextError,
  Spinner,
} from "../../Common/StyledComponents";
import CWBActorCreatePhoneStep from "components/CWBLabs/ActorCreatePhoneStep";
import { iRootState } from "../../../store";
import { IActorError, IActorPhone } from "../../../shared/api/dtos/IActorDto";
import { IGroupedCountry } from "../../../helpers/ICountryList";

interface IProps extends StateProps, DispatchProps {
  t?: any;
  history?: any;
  location?: any;
}

interface IState {
  countryCode: string;
  isFormSubmitClicked: boolean;
  fields: IActorPhone;
}

class ActorCreatePhoneStep extends React.Component<IProps, IState> {
  public state: IState = {
    countryCode: "CA",
    isFormSubmitClicked: false,
    fields: { phone: "" }
  };

  async componentDidMount() {
    try {
      const response = await fetch(process.env.REACT_APP_IpInfo);
      const ipData = await response.json();
      this.setState({ countryCode: await ipData.country_code.toLowerCase() });
    } catch (e) {
      console.error(e);
      if (getI18n().language === "es") {
        this.setState({ countryCode: "es" });
      }
    }
  }

  checkValidation() {
    const { t } = this.props;
    let fields = this.state.fields;
    let errors = {} as IActorError;
    if(!fields.phone){
      errors.Phone = t("Invalid Phone Number");
    }else if (phoneValidation(fields.phone, t))
      errors.Phone = phoneValidation(fields.phone, t);
  
    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  checkNumber() {
    const { t } = this.props;
    let fields = this.state.fields;
    let errors = {} as IActorError;

    if (!fields.phone || fields.phone.match(/^[0-9`]+$/i)) {
      this.props.setErrors(errors);
      return true;
    }

    errors.Phone = t("Only numbers are accepted");
    fields.phone = "";
    this.setState({ fields });
    this.props.setErrors(errors);
    return false;
  }

  handleChange = (phoneNumber: string) => {
    this.setState({
      fields: {
        ...this.state.fields,
        phone: phoneNumber
      }
    });
    this.checkNumber();
    this.state.isFormSubmitClicked && this.checkValidation();
  };

  getNumber = (): string => {
    return `+${this.state.fields.phone}`;
  };

  handleKeyPress = (event:any) => {
    if(event.key === 'Enter'){
      this.createAccount(event);
    }
  }

  createAccount = async (e: any) => {
    e.preventDefault();
    if (this.props.isLoading) return;
    this.setState({ isFormSubmitClicked: true });
    if (!this.checkValidation()) return;
    await this.props.createActorPhone({ phone: this.getNumber() });
    this.moveToNext();
  };

  moveToNext = () => {
    if (this.props.errors.errorMessage)
      window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    else if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.history.push({
        pathname: "/actor/2",
        search: this.props.location.search
      });
    }
  };

  goBack = () => {
    this.props.history.goBack();
  };

  render() {
    const { t } = this.props;

    return qs.parse(this.props.location.search).cwblabs ? (
      <CWBActorCreatePhoneStep
        errors={this.props.errors}
        isLoading={this.props.isLoading}
        state={this.state}
        onChange={this.handleChange}
        createAccount={this.createAccount}
      />
    ) : (
      <PageContainer>
        <Wrapper>
          <Title>{t("Let’s add your phone number for your security")}</Title>
          <SubTitle>
            {t("We’ll send you a confirmation code to verify your phone number.")}
          </SubTitle>
          <form
            name="registerForm"
            className="registerForm"
            onSubmit={this.createAccount}
            style={{ width: "100%" }}
          >
            <fieldset>
              <Section>
                <SectionTitle>{t("Phone Number")}</SectionTitle>
                <PhoneInput
                  containerStyle={{ marginTop: "8px" }}
                  country={this.state.countryCode}
                  value={this.state.fields.phone}
                  onChange={(phoneNumber) => this.handleChange(phoneNumber)}
                  onEnterKeyPress={this.handleKeyPress}
                />
                <SmallTextError className="error">
                  <span>{this.props.errors.Phone}</span>
                </SmallTextError>
              </Section>
              <InfoBox>
                {t(
                  "We only use this to verify your account. We’ll never contact you without your permission."
                )}
              </InfoBox>
              <Section>
                <StyledBlueBtn type="submit" id="submit">
                  {t("Send Confirmation Code")}
                  {this.props.isLoading && (
                    <Spinner
                      src="../images/spinner.svg"
                      className="spinner-width"
                    />
                  )}
                </StyledBlueBtn>
              </Section>
            </fieldset>
          </form>
        </Wrapper>
        <Footer />
      </PageContainer>
    );
  }
}

interface StateProps {
  errors: IActorError;
  fields: IActorPhone;
  groupCountryList: IGroupedCountry[];
  isLoading: boolean;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    errors: state.actorModel.errors,
    fields: state.actorModel.fields,
    groupCountryList: state.referencesModel.groupCountryList,
    isLoading: state.actorModel.isLoading,
  };
}

interface DispatchProps {
  setErrors: (errors: IActorError) => void;
  createActorPhone: (fields: IActorPhone) => void;
  getGroupCountryList: () => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    setErrors: dispatch.actorModel.setErrors,
    createActorPhone: dispatch.actorModel.createActorPhone,
    getGroupCountryList: dispatch.referencesModel.getGroupCountryList,
  };
}

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  font-weight: ${(p) => p.theme["font-weight-600"]};

  max-width: 480px;
  min-width: 480px;
  background-color: ${(p) => p.theme.white};
  padding: 40px 80px;

  @media all and (max-width: 520px) {
    width: 90%;
    min-width: 40%;
  }

  * {
    font-size: ${(p) => p.theme["s-font-size"]};
  }

  & div.flag-dropdown {
    background-color: #FFFFFF;
  }
`;

const Title = styled.div`
  font-size: ${(p) => p.theme["xxl-font-size"]} !important;
  text-align: center;
`;

const SubTitle = styled.div`
  color: ${(p) => p.theme.color} !important;
  margin: auto 8px;
  font-weight: normal;
  text-align: center;
`;

const InfoBox = styled.div`
  color: ${(p) => p.theme.color} !important;
  font-weight: normal;
  border-radius: 4px;
  background-color: #f8f8f9;
  margin-top: 16px;
  margin-bottom: 8px;
  padding: 16px;
  text-align: center;
`;

export default withI18n()(
  connect(mapStateToProps, mapDispatchToProps)(ActorCreatePhoneStep)
);
