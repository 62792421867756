import { createModel } from '@rematch/core';
import * as PromoCodeAPI from '../shared/api/PromoCode.api';
import { IDiscount } from '../shared/api/dtos/IPromoCodeDto';

const promoCodeModel = createModel({
  state: {},
  reducers: {},
  effects: (dispatch: any) => ({
    getDiscount: async (code: string): Promise<IDiscount> => {
      try {
        const response = await PromoCodeAPI.getDiscount(code);
        return response.data;
      } catch (e) {
        return { isValid: false } as IDiscount;
      }
    }
  })
});

export default promoCodeModel;
