import React from "react";
import { withI18n } from "react-i18next";
import styled from "styled-components";
import {PasswordLength} from "../../helpers";
import {connect} from "react-redux";
import {iRootState} from "../../store";
import {passwordValidation} from "../../helpers/validations";
import {IAccountCredentials, IAccountRequestData, IActorError} from "../../shared/api/dtos/IActorDto";
import {SmallText} from "../Common/StyledComponents";
import Footer from "../Common/Footer";
import PageContainer from "../Common/PageContainer";
// @ts-ignore
import {CWBTooltip, CWBLoadingCrest} from 'cwb-react';

const queryString = require('qs');

interface IProps extends StateProps, DispatchProps {
  t?: any;
  history?: any;
  location? : any;
}

interface IState {
  fields: IAccountCredentials;
  requestData: IAccountRequestData;
  errors: IActorError;
  isConfirmClicked: boolean;
}

class AccountConfirmation extends React.Component<IProps, IState> {
  public state: IState = {
    fields: {} as IAccountCredentials,
    requestData: {} as IAccountRequestData,
    errors: this.props.errors || ({} as IActorError),
    isConfirmClicked: false
  };

  async componentDidMount() {
    try {
      const qsArgs = decodeURI(window.location.search).split('?')[1];
      const result = queryString.parse(qsArgs);
      this.props.appendAccountRequestData(result);
      await this.props.getAccountInfo(result.token);
      this.setState({
        requestData: {token: result.token, sch: result.sch, saud: result.saud},
        fields: {...this.props.accountCredentials}
      });
      if (this.props.accountCredentials.isConfirmed) {
        window.location.href = `${process.env.REACT_APP_CWB_Site}/login/?isConfirmed=1&page=infosheet&${decodeURI(window.location.search).split('?')[1]}`;
        return;
      }
    } catch (e) {
      window.location.href = `${process.env.REACT_APP_CWB_500}?accountConfirmationFailed&${decodeURI(window.location.search).split('?')[1]}`;
      return;
    }
  }

  async componentWillReceiveProps(nextProps: IProps, prevProps: IProps) {
    if (nextProps.errors !== prevProps.errors) {
      this.setState({ errors: nextProps.errors });
    }
    if (nextProps.accountConfirmed) {
      window.location.href = `${window.location.origin}/actor/account-confirmed`
    }
    if (nextProps.invalidAccount) {
      window.location.href = `${process.env.REACT_APP_CWB_500}?accountConfirmationFailed=${nextProps.invalidAccount}&${decodeURI(window.location.search).split('?')[1]}`;
      return;
    }
  }

  checkValidation() {
    const { t } = this.props;
    let fields = this.state.fields;
    let errors: IActorError = {} as IActorError;

    if (passwordValidation(fields.password, PasswordLength, t))
      errors.Password = passwordValidation(fields.password, PasswordLength, t);

    if (fields.password !== fields.confirmPassword)
        errors.ConfirmPassword = t("Confirm Password is not same as password");

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  handleChange = (e: any) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
    this.state.isConfirmClicked && this.checkValidation();
  };

  confirmAccount = async (e: any) => {
    e.preventDefault();
    this.setState({ isConfirmClicked: true });
    if (!this.checkValidation()) return;
    await this.props.confirmAccount({dto: {
        password: this.state.fields.password,
        workbookId: this.state.fields.workbookId,
        sch: this.state.requestData.sch,
        saud: this.state.requestData.saud,
      }, guid: this.state.requestData.token});
  };

  render() {
    const { t } = this.props;
    const { fields, errors } = this.state;

    if (this.props.isLoading) return (
      <CWBLoadingCrest customLogoSrc="/images/icon-cwb-logo-grey.svg" />
    );

    return (
      <PageContainer>
        <TopWrapper>
          <TopHeader>
            <MainHeader>{t('Please confirm your account')}</MainHeader>
            <SubHeader>{t('Casting has invited you to use a Virtual Casting Room to complete your audition. Confirm your details before your auditions.')}</SubHeader>
          </TopHeader>
          <Wrapper>
            <StyledTitle>{t("Confirm your account")}</StyledTitle>
            <Divider />
            <form
              name="accountConfirmForm"
              className="accountConfirmForm"
              onSubmit={this.confirmAccount}
              style={{ width: "100%" }}
            >
              <BodyWrapper>
                <Title>{t('Workbook ID')}</Title>
                <div style={{position: 'relative'}}>
                  <InputBox
                    type="text"
                    name="workbookId"
                    className="disabled"
                    value={(fields && fields.workbookId) || ""}
                    disabled
                    readOnly
                  />
                  <LockIcon id="icon-lock-workbookID" src="../../images/icon-lock.png" />
                </div>
                <Title>{t('Email')}</Title>
                <div style={{position: 'relative'}}>
                  <InputBox
                    type="text"
                    id="account-email"
                    name="email"
                    className="disabled"
                    value={(fields && fields.email) || ""}
                    disabled
                    readOnly
                  />
                  <LockIcon id="icon-lock-email" src="../../images/icon-lock.png" />
                  <CWBTooltip target="icon-lock-email" style={{wrap: 'nowrap'}}>{t('Contact our CSR team to change email')}</CWBTooltip>
                </div>
                <Title>{t('New Password')} <span>({t('min. 6 characters')})</span></Title>
                <InputBox
                  name="password"
                  type="password"
                  autoComplete="new-password"
                  className={errors.Password ? "invalid" : ""}
                  onChange={this.handleChange}
                  value={fields.password}
                  placeholder="Input new password"
                />
                <SmallText className="error"><span>{errors.Password}</span></SmallText>
                <Title>{t('Confirm Password')}</Title>
                <InputBox
                  name="confirmPassword"
                  type="password"
                  autoComplete="confirm-password"
                  className={errors.ConfirmPassword ? "invalid" : ""}
                  onChange={this.handleChange}
                  value={fields.confirmPassword}
                  placeholder="Confirm new password"
                />
                <SmallText className="error"><span>{errors.ConfirmPassword}</span></SmallText>
                <SmallText className="error"><span>{t(errors.errorMessage)}</span></SmallText>
                <LoginButton type="submit" id="submit" value={t("Confirm Account")} />
              </BodyWrapper>
            </form>
          </Wrapper>
        </TopWrapper>
        <Footer />
      </PageContainer>
    );
  }
}

const TopWrapper = styled.div`
  max-width: 610px;
  min-width: 610px;
  

  @media all and (max-width: 576px) {
    width: 90%;
    min-width: 90%;
  }
`

const TopHeader = styled.div`
  width: 100%;  
  margin: 0 auto 32px;
  padding: 0 40px;
  text-align: center;
  @media all and (max-width: 576px) {
    text-align: left;
    margin-top: 24px;
    padding: 0;
  }
`
const MainHeader = styled.div`
  color: ${(p) => p.theme.formField.color} !important;
  font-weight: bold;
  font-size: ${(p) => p.theme["xxl-font-size"]};;
  line-height: 18px;
`

const SubHeader = styled.div`
  ${(p) => p.theme["lg-font-size"]};
  line-height: 22px;
  color: #5D6C84 !important;
  margin-top: 24px;
`

const Wrapper = styled.div`
  width: 100%;
  
  display: flex;
  flex-direction; column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;
  
  background-color: ${(p) => p.theme.white};
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
`;

const StyledTitle = styled.div`
  color: ${(p) => p.theme.blue} !important;
  font-size: ${(p) => p.theme["xl-font-size"]};
  line-height: 25px;
  margin-top: 16px;
  text-align: center;
  text-transform: uppercase;
`;

const Divider = styled.div`
  width: 100%;
  height: 4px;  
  background: ${(p) => p.theme.lightBlue};
  margin-top: 12px;
`;

const BodyWrapper = styled.div`
  width: 100%;
  padding: 24px 36px 32px 35px;
  
  & * {
    font-size: ${(p) => p.theme["font-size"]};
  }
`

const Title = styled.div`
  font-weight: ${(p) => p.theme["font-weight-600"]};
  line-height: 21px;
  color: ${(p) => p.theme.darkerGrey};
  margin-top: 16px;
  
  span {
    font-weight: normal;
  }
`

const InputBox = styled.input`
  color: ${(p) => p.theme.black} !important;
  background: ${(p) => p.theme.lightestGrey};
  border: 1px solid ${(p) => p.theme["disabled-grey"]};
  box-sizing: border-box;
  border-radius: ${(props) => props.theme.dropDown["border-radius"]};
  height: 40px;
  width: 100%;
  padding-left: 16px;
  padding-right: 14px;
  margin-top: 8px;
  
  &.disabled{
    background: ${(p) => p.theme.lighterGrey};
  }
  
  && ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
    color: #A6AEBC;
    opacity: 1; /* Firefox */
  }
  
  && :-ms-input-placeholder { /* Internet Explorer 10-11 */
    color: #A6AEBC;
  }
  
  && ::-ms-input-placeholder { /* Microsoft Edge */
    color: #A6AEBC;
  }
`
const LockIcon = styled.img`
  position: absolute;
  right: 16px;
  top: 19px;
`

const LoginButton = styled.input`
  width: 100%;
  
  display: flex;
  justify-content: center;
  align-items: center;

  color: ${(p) => p.theme.white} !important;
  font-size: ${(p) => p.theme["font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};
  background-color: ${(p) => p.theme.btnBlue};
  border: 1px solid ${(p) => p.theme.btnBlue};
  border-radius: 4px;
  padding: 9px 0;
  margin-top: 32px;
  cursor: pointer;
`;

interface StateProps {
  accountRequestData: IAccountRequestData;
  accountCredentials: IAccountCredentials;
  accountConfirmed: boolean;
  invalidAccount: boolean;
  errors: IActorError;
  isLoading: boolean;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    accountRequestData: state.actorModel.accountRequestData,
    accountCredentials: state.actorModel.accountCredentials,
    accountConfirmed: state.actorModel.accountConfirmed,
    invalidAccount: state.actorModel.invalidAccount,
    errors: state.actorModel.errors,
    isLoading: state.actorModel.isLoading,
  };
}

interface DispatchProps {
  setErrors: (errors: IActorError) => void;
  confirmAccount: ({fields, guid}: any) => void;
  getAccountInfo: (guid: string) => void,
  appendAccountRequestData: (requestData: IAccountRequestData) => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    setErrors: dispatch.actorModel.setErrors,
    confirmAccount: dispatch.actorModel.confirmAccount,
    getAccountInfo: dispatch.actorModel.getAccountInfo,
    appendAccountRequestData: dispatch.actorModel.appendAccountRequestData,
  };
}

export default withI18n()(
    connect(mapStateToProps, mapDispatchToProps)(AccountConfirmation)
);
