import { createModel } from "@rematch/core";
import * as ServicesAPI from "../shared/api/Services.api";
import { IPremiumFeatureComparison } from "../shared/api/dtos/IPremiumDto";
// @ts-ignore
import { getCurUserId } from "cwb-react";
import { getUserActorSignUpId } from "../helpers";
import { ISubscriptionService } from "../shared/api/dtos/IServicesDto";

const premiumModel = createModel({
  state: {
    monthlyCharge: 0,
    yearlyCharge: 0,
    yearlyPerMonthCharge: 0,
    headshot: {
      free: 0,
      premium: 0,
    } as IPremiumFeatureComparison,
    resume: {
      free: 0,
      premium: 0,
    } as IPremiumFeatureComparison,
    mediaLimit: 0,
    currencySymbol: "$",
  },
  reducers: {
    updateMonthlyCharge: (state, data) => {
      return { ...state, monthlyCharge: data };
    },
    updateYearlyCharge: (state, data) => {
      return { ...state, yearlyCharge: data };
    },
    updateYearlyPerMonthCharge: (state, data) => {
      return { ...state, yearlyPerMonthCharge: data };
    },
    updateHeadshot: (state, data: IPremiumFeatureComparison) => {
      return { ...state, headshot: data };
    },
    updateResume: (state, data: IPremiumFeatureComparison) => {
      return { ...state, resume: data };
    },
    updateMediaLimit: (state, data) => {
      return { ...state, mediaLimit: data };
    },
    updateCurrencySymbol: (state, data) => {
      return { ...state, currencySymbol: data };
    },
  },
  effects: (dispatch: any) => ({
    async getSubscriptionServices() {
      let countryCode = "CA";

      let freemium: ISubscriptionService;
      let premiumYearly: ISubscriptionService;
      let premiumMonthly: ISubscriptionService;

      let clientId = getCurUserId();
      let actorSignupId = getUserActorSignUpId();
      let currencySymbol = "$";

      try {
        const response = await fetch(process.env.REACT_APP_IpInfo);
        const ipData = await response.json();
        countryCode = await ipData.country_code;
      } catch (e) {
        console.error(e);
      }

      if (clientId || actorSignupId) {
        try {
          let paidServices = [] as ISubscriptionService[];

          if (clientId) {
            paidServices = (await ServicesAPI.getPaidServicesClient()).data;
            freemium = (await ServicesAPI.getFreeServiceClient()).data;
          } else {
            paidServices = (
              await ServicesAPI.getPaidServicesSignup(actorSignupId)
            ).data;
            freemium = (await ServicesAPI.getFreeServiceSignup(actorSignupId))
              .data;
          }

          premiumMonthly = paidServices.find((s) => s.durationMonths === 1);
          premiumYearly = paidServices.find((s) => s.durationMonths === 12);
          if (premiumMonthly.currency === "EUR") currencySymbol = "€";
        } catch (e) {
          try {
            const {
              data: services,
            } = await ServicesAPI.getSubscriptionsServices();

            freemium = services.find((s) => s.isFreemium);
            premiumYearly = services.find((s) => {
              return (
                !s.discountAge && !s.isVoiceOnly && s.durationMonths === 12
              );
            });
            premiumMonthly = services.find((s) => {
              return !s.discountAge && !s.isVoiceOnly && s.durationMonths === 1;
            });
          } catch (e) {
            throw e;
          }
        }
      } else {
        try {
          const {
            data: services,
          } = await ServicesAPI.getSubscriptionsServices();

          freemium = services.find((s) => s.isFreemium);
          const premiumYearlyMatches = services.filter((s) => {
            return !s.discountAge && !s.isVoiceOnly && s.durationMonths === 12;
          });
          premiumYearly = premiumYearlyMatches.find((s) => {
            return countryCode === s.countryCode;
          }) || premiumYearlyMatches[0];
          const premiumMonthlyMatches = services.filter((s) => {
            return !s.discountAge && !s.isVoiceOnly && s.durationMonths === 1;
          });
          premiumMonthly = premiumMonthlyMatches.find((s) => {
            return countryCode === s.countryCode;
          }) || premiumMonthlyMatches[0];

          if (premiumMonthly.currency === "EUR") currencySymbol = "€";
        } catch (e) {
          throw e;
        }
      }

      dispatch.premiumModel.updateYearlyCharge(premiumYearly.price);
      dispatch.premiumModel.updateMonthlyCharge(
        premiumMonthly
          ? premiumMonthly.price
          : Number((premiumYearly.price / 12).toFixed(2))
      );
      dispatch.premiumModel.updateYearlyPerMonthCharge(
        Number((premiumYearly.price / 12).toFixed(2))
      );
      dispatch.premiumModel.updateHeadshot({
        free: freemium.numberAllowedPhoto,
        premium: premiumYearly.numberAllowedPhoto,
      });
      dispatch.premiumModel.updateResume({
        free: freemium.numberAllowedResume,
        premium: premiumYearly.numberAllowedResume,
      });
      dispatch.premiumModel.updateMediaLimit(
        premiumYearly.numberAllowedAudio + premiumYearly.numberAllowedVideo
      );
      dispatch.premiumModel.updateCurrencySymbol(currencySymbol);
    },
  }),
});

export default premiumModel;
