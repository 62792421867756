import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withI18n } from "react-i18next";
import { RouteComponentProps } from "react-router-dom";
import styled from "styled-components";
import {
  Button,
  ButtonGroup,
  Footer,
  Modal,
  PageContainer,
  Typography,
} from "../../Common";
import BackButton from "../../Common/BackButton";
import { Spinner } from "../../Common/StyledComponents";
import UpgradeTable from "../../Common/UpgradeTable";
import { getCurUserSignUp, getSessionId, getUserActorSignUpId } from "../../../helpers";
import Tooltip from "../../Common/Tooltip";

interface Props extends StateProps, DispatchProps, RouteComponentProps {
  t: any;
}

const ActorRegistrationUpgradeTable: React.FC<Props> = ({
  t,
  history,
  location,
  yearlyPerMonthCharge,
  createFreemiumUser,
  currencySymbol,
  setClientId
}) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);

  useEffect(() => {
    if (!getSessionId() || !getCurUserSignUp()) {
      window.location.replace("/");
    }
  }, []);

  const completeFreemiumRegistration = async () => {
    try {
      setIsLoading(true);
      const response = await createFreemiumUser(getUserActorSignUpId());
      setClientId(response.data.clientId);
      setIsLoading(false);
    } catch (e) {
      window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    }
    history.push({
      pathname: "/actor/free-completed",
      search: location.search
    });
  };
  const hoverTip = (
    <StyledTooltip placement="top" tip={t("On an annual plan.")}>
      <img alt="more info" src="../images/info.svg" />
    </StyledTooltip>
  );

  return (
    <PageContainer>
      <BackButton width={800} />
      <StyledModal>
        <Typography component="h1" gutterBottom variant="h2">
          {t("Continue with free or upgrade now")}
        </Typography>
        <StyledTypography align="center" color="medGrey">
          {/* eslint-disable-next-line */}
          {t(
            "Get started now - for free or get access to everything Casting Workbook has to offer for as little as {{currencySymbol}}{{yearlyPerMonthCharge}}/mo.",
            {
              currencySymbol,
              yearlyPerMonthCharge
            }
          )}
          {hoverTip}
          {t(
            "Not sure what to choose? No worries - you can upgrade your account at any time!"
          )}
        </StyledTypography>
        <UpgradeTable />
        <StyledButtonGroup>
          <Button onClick={completeFreemiumRegistration}>
            {t("Get started for free")}
            {isLoading && <StyledSpinner src="/images/spinner.svg" />}
          </Button>
          <Button
            variant="secondary"
            onClick={() => history.push({
              pathname: "/actor/payment",
              search: location.search
            })}
          >
            {t("Upgrade to Pro")}
          </Button>
        </StyledButtonGroup>
      </StyledModal>
      <Footer />
    </PageContainer>
  );
};

const StyledModal = styled(Modal)`
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
  padding: ${(p) => p.theme.spacing(5, 14, 6)};

  @media all and (max-width: 768px) {
    width: 90%;
    padding: ${(p) => p.theme.spacing(3, 5)};
  }

  @media all and (max-width: 520px) {
    width: 100%;
    padding: ${(p) => p.theme.spacing(3, 2)};
  }
`;

const StyledTypography = styled(Typography)`
  margin-bottom: ${(p) => p.theme.spacing(3)};
  font-size: 13px;
  line-height: 18px;
`;

const StyledButtonGroup = styled(ButtonGroup)`
  margin-top: ${(p) => p.theme.spacing(3)};
`;

const StyledSpinner = styled(Spinner)`
  margin-left: ${(p) => p.theme.spacing(0.5)};
`;

const StyledTooltip = styled(Tooltip)`
  top: -3px;
  padding: 0 2px 0 0;

  & > img {
    width: 16px;
    height: 16px;
    margin: 2px 2px 0;
  }
`;

interface StateProps {
  yearlyPerMonthCharge: number;
  currencySymbol: string;
}

const mapStateToProps = (state: any) => ({
  yearlyPerMonthCharge: state.premiumModel.yearlyPerMonthCharge,
  currencySymbol: state.premiumModel.currencySymbol,
});

interface DispatchProps {
  createFreemiumUser: (actorSignUpId: number) => any;
  setClientId: (clientId: string) => void;
}

const mapDispatchToProps = (dispatch: any) => ({
  createFreemiumUser: dispatch.actorModel.createFreemiumUser,
  setClientId: dispatch.actorModel.setClientId
});

export default withI18n()(
  connect(mapStateToProps, mapDispatchToProps)(ActorRegistrationUpgradeTable)
);
