import { createModel } from "@rematch/core";
import * as ReferencesApi from "../shared/api/References.api";
import { groupCountryList } from "../helpers";
import { ICountryDto } from "../shared/api/dtos/IReferencesDto";
import { IGroupedCountry } from "../helpers/ICountryList";

const referencesModel = createModel({
  state: {
    countryList: [] as ICountryDto[],
    groupCountryList: [] as IGroupedCountry[],
    supportedGroupCountryList: [] as IGroupedCountry[]
  },
  reducers: {
    updateCountryList(state, data) {
      return { ...state, countryList: data };
    },
    updateGroupCountryList(state, data) {
      return { ...state, groupCountryList: data };
    },
    updateSupportedGroupCountryList(state, data) {
      return { ...state, supportedGroupCountryList: data };
    }
  },
  effects: (dispatch: any) => ({
    async getGroupCountryList() {
      const resp = await ReferencesApi.getCountriesWithCities();
      const grouped = groupCountryList(resp.data);
      dispatch.referencesModel.updateGroupCountryList(grouped);
    },
    async getCountryList() {
      const resp = await ReferencesApi.getCountries();
      dispatch.referencesModel.updateCountryList(resp.data);
    },
    async getSupportedGroupCountryList() {
      const resp = await ReferencesApi.getSupportedCitiesCountries();
      const grouped = groupCountryList(resp.data);
      dispatch.referencesModel.updateSupportedGroupCountryList(grouped);
    }
  })
});

export default referencesModel;
