import * as http from "../http";
import {
  ICastingAccountDto,
  ICastingUser,
  ICastingFilmCenter,
  ICastingProApprovalDetails,
} from "./dtos/ICastingDtos";
import { TrackingData } from "./dtos/ITrackingDataDto";

export const saveCastingAccount = (dto: ICastingUser) => {
  return http.post<ICastingAccountDto>(
    `${process.env.REACT_APP_REG_Api}/Casting/user`,
    dto
  );
};

export const saveCastingFilmCenter = (
  sessionId: any,
  dto: ICastingFilmCenter
) => {
  return http.post<ICastingAccountDto>(
    `${process.env.REACT_APP_REG_Api}/Casting/${sessionId}/filmcenter`,
    dto
  );
};

export const createCastingUnverified = (sessionId: string) => {
  const trackingData = JSON.parse(
    localStorage.getItem("tData")
  ) as TrackingData;
  return http.post<ICastingAccountDto>(
    `${process.env.REACT_APP_REG_Api}/Casting/${sessionId}/unverified/create${window.location.search}`,
    trackingData
  );
};

export const createCastingPro = (sessionId: string) => {
  const trackingData = JSON.parse(
    localStorage.getItem("tData")
  ) as TrackingData;
  return http.post<ICastingAccountDto>(
    `${process.env.REACT_APP_REG_Api}/Casting/${sessionId}/pro/create${window.location.search}`,
    trackingData
  );
};

export const createCastingProducer = (sessionId: string) => {
  const trackingData = JSON.parse(
    localStorage.getItem("tData")
  ) as TrackingData;
  return http.post<ICastingAccountDto>(
    `${process.env.REACT_APP_REG_Api}/Casting/${sessionId}/producer/create${window.location.search}`,
    trackingData
  );
};

export const saveCastingProApproval = (
  sessionId: string,
  dto: ICastingProApprovalDetails
) => {
  return http.post<ICastingAccountDto>(
    `${process.env.REACT_APP_REG_Api}/Casting/${sessionId}/pro/approval`,
    dto
  );
};
