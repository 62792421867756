import React from "react";
import { getI18n, withI18n } from "react-i18next";
import styled from "styled-components";

interface Props {
  t: any;
}

const Footer: React.FC<Props> = ({ t }) => {
  const isLangSpanish = getI18n().language === "es";
  const terms = isLangSpanish
    ? process.env.REACT_APP_CWB_Terms_Path_es
    : `${process.env.REACT_APP_CWB_Site}/${process.env.REACT_APP_CWB_Terms_Path}`
  const privacy = isLangSpanish
    ? process.env.REACT_APP_CWB_Privacy_Path_es
    : `${process.env.REACT_APP_CWB_Site}/${process.env.REACT_APP_CWB_Privacy_Path}`;

  return (
    <StyledFooter>
      <div>
        <a
          href={terms}
          rel="noopener noreferrer"
          target="_blank"
        >
         {t("Terms and Conditions")}
        </a>
        <Divider />
        <a
          href={privacy}
          rel="noopener noreferrer"
          target="_blank"
        >
          {t("Privacy Policy")}
        </a>
      </div>
      <div>{t("Copyright © 2021 Casting Workbook Services Inc.")}</div>
    </StyledFooter>
  );
};

const StyledFooter = styled.div`
  margin: 32px 0 0;
  font-size: 12px;

  & > div {
    color: #53627C;
    margin-bottom:${p => p.theme.spacing(2)}
    & > a {
      color: #53627C;
      margin: ${p => p.theme.spacing(0, 2, 0)};
      &:hover { text-decoration: underline; }
    }
  }

  & > div:nth-of-type(1) {
    display: flex;
    justify-content: center;
  }
`;

const Divider = styled.div`
  width: 1px;
  margin: 0 8px;
  background-color: ${p => p.theme.placeHolderColor};
`;

export default withI18n()(Footer);