// @ts-ignore
import { emailRegex } from "cwb-react";

export { emailRegex };

export const nameRegex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s-`']+$/i;

export const passwordRegex = /^(?=.*[a-z])(?=.*\d)[a-z\d\s!@#$%^&*+-=_<>?]{6,}$/i;

export const emailValidation = (email: string, t: any) => {
  let errors: string = "";

  //Email
  if (!email) {
    errors = t("Email is required");
  }

  if (email && !email.match(emailRegex)) {
    errors = t("Email is not valid");
  }

  return errors;
};

export const passwordValidation = (password: string, min: number, t: any) => {
  let errors: string = "";

  if (!password) {
    errors = t("Password is required");
  }

  if (password && password.length < min) {
    errors = t(`Minimum ${min} characters`);
  }

  if (!passwordRegex.test(password)) {
    errors = t("Must contain at least 6 characters, 1 letter and 1 number");
  }

  return errors;
};

export const phoneValidation = (phone: string, t: any) => {
  let errors: string = "";
  if (!phone) return errors;

  const regex = /^[1-9]\d{7,14}$/;

  if (!phone.match(regex)) {
    errors = t("Invalid Phone Number");
  }

  return errors;
};

export const postalCodeValidation = (postalCode: string, t: any) => {
  if (
    postalCode.length <= 10 &&
    (/^\d{5}(?:[-\s]\d{3})?$/.test(postalCode) ||
      /^[0-9]{4,7}$/.test(postalCode) ||
      /^[A-Za-z]\d[A-Za-z][ -]?\d[A-Za-z]\d$/.test(postalCode) ||
      /([Gg][Ii][Rr] 0[Aa]{2})|((([A-Za-z][0-9]{1,2})|(([A-Za-z][A-Ha-hJ-Yj-y][0-9]{1,2})|(([A-Za-z][0-9][A-Za-z])|([A-Za-z][A-Ha-hJ-Yj-y][0-9][A-Za-z]?))))\s?[0-9][A-Za-z]{2})/.test(
        postalCode
      ))
  ) {
    return "";
  }

  return t("Postal Code is not valid");
};
