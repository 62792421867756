import React from 'react';
import qs from 'query-string';
import { getI18n } from 'react-i18next';
import { connect } from 'react-redux';
import { Redirect, RouteComponentProps } from 'react-router-dom';
import Membership from '../../Common/Membership';
import { getCurUserSignUp, getUserActorSignUpId } from '../../../helpers';
import { IActorAccountDto } from '../../../shared/api/dtos/IActorDto';
import { IStripeCharge } from '../../../shared/api/dtos/IStripeDto';

interface Props extends RouteComponentProps, DispatchProps {}

const ActorPaymentStep: React.FC<Props> = ({
  history,
  location,
  createStripeSubscription,
  createFreemiumUser,
  getServices,
  setLoading,
  setClientId,
}) => {
  const user: IActorAccountDto = getCurUserSignUp();

  const fetchServices = async () => {
    await getServices(
      getUserActorSignUpId()
    );
  };

  const handleSubmit = async (apiPayload: IStripeCharge) => {
    const response = await createStripeSubscription(apiPayload);

    if(response && response.data && response.data.clientId)
      setClientId(response.data.clientId);
  };

  const moveToNext = (serviceId: number) => {
    history.push({
      pathname: '/actor/payment-processing',
      search: location.search,
      state: {
        successPath: '/actor/pro-completed',
        serviceId
      }
    });
  };

  const handleSkip = async () => {
    try {
      setLoading(true);
      await createFreemiumUser(getUserActorSignUpId());
      history.push({
        pathname: '/actor/free-completed',
        search: location.search
      });
      setLoading(false);
    } catch (e) {
      window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    }
  };

  if (!user) return (<Redirect to="/register" />);

  const isLangSpanish = getI18n().language === "es";

  return (
    <Membership
      country={user.actorFilmCenter.country}
      provinceId={user.actorFilmCenter.provinceId}
      allowSkip={!qs.parse(location.search).cwblabs || isLangSpanish}
      getServices={() => fetchServices()}
      onSubmit={(apiPayload) => handleSubmit(apiPayload)}
      moveToNext={moveToNext}
      onSkip={handleSkip}
    />
  );
};

interface DispatchProps {
  createStripeSubscription: (dto: IStripeCharge) => any;
  createFreemiumUser: (actorSignUpId: number) => void;
  getServices: (actorSignUpId: number) => void;
  setLoading: (loading: boolean) => any;
  setClientId: (clientId: string) => void;
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    createStripeSubscription: dispatch.stripeModel.createStripeSubscription,
    createFreemiumUser: dispatch.actorModel.createFreemiumUser,
    getServices: dispatch.servicesModel.getPaidServicesSignup,
    setLoading: dispatch.appModel.setLoading,
    setClientId: dispatch.actorModel.setClientId,
  };
};

export default connect(null, mapDispatchToProps)(ActorPaymentStep);
