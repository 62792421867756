import React from "react";
import qs from "query-string";
import { getI18n, withI18n } from "react-i18next";
import styled from "styled-components";
// @ts-ignore
import { CWBDropdown } from "cwb-react";
import { setCurUserSignUp, setUserActorSignUpId } from "../helpers/index";
import CastingUserStep from "./Director/CastingUserStep";
import AgentRegistration from "./Agent/AgentRegistration";
import { withRouter } from "react-router-dom";
import ActorCredentialStep from "./Actor/SignUp/ActorCredentialStep";
import Modal from "./Common/Modal";
import { Section, SectionTitle } from "./Common/StyledComponents";
import { iRootState } from "../store";
import { connect } from "react-redux";
import CastingSellingFeatures from "./Director/CastingSellingFeatures";
import ProducerSellingFeatures from "./Director/ProducerSellingFeatures";
import AgentSellingFeatures from "./Agent/AgentSellingFeatures";
import ActorSellingFeatures from "./Actor/ActorSellingFeatures";

interface IState {
  selectedAccount: any;
  accounts: Array<object>;
}

interface IProps extends StateProps, DispatchProps {
  t?: any;
  history: any;
  location: any;
  match: any;
}

class Registration extends React.Component<IProps, IState> {
  public state: IState = {
    selectedAccount: null,
    accounts: [
      { id: 1, value: "actor", label: this.props.t("Actor") },
      { id: 2, value: "agent", label: this.props.t("Agent") },
      { id: 3, value: "casting", label: getI18n().language === "es" ? this.props.t("Casting Director / Talent Scout") : this.props.t("Casting Director")}
    ],
  };

  async componentWillMount() {
    setCurUserSignUp(null);
    setUserActorSignUpId(null);

    const { match } = this.props;
    if (match && match.params && match.params.accountType) {
      const selected = this.state.accounts.find(
        (x: any) => x.value === match.params.accountType
      );
      if (selected) {
        this.setState({ selectedAccount: selected });
      } else {
        window.location.href = "/register";
      }
    } else {
      this.setState({ selectedAccount: this.state.accounts[0] });
    }
  }

  render() {
    const { t } = this.props;
    const { selectedAccount } = this.state;
    const accounts = this.state.accounts.map((option: any) => ({
      ...option,
      label: t(option.label)
    }));

    const qsArgs = qs.parse(this.props.location.search);
    const isProducer = qsArgs.subType === 'producer';
    return (
      <Wrapper>
        <WrapperColumns>
          <BodySellingFeatures>
            {selectedAccount.id === 1 && <ActorSellingFeatures />}
            {selectedAccount.id === 2 && <AgentSellingFeatures />}
            {selectedAccount.id === 3 && !isProducer && <CastingSellingFeatures />}
            {selectedAccount.id === 3 && isProducer && <ProducerSellingFeatures />}
          </BodySellingFeatures>
        </WrapperColumns>
        <WrapperColumns>
          <Body>
            <StyledTitle>{t("Let’s create your account")}</StyledTitle>
            {!isProducer && (
              <Section>
                <SectionTitle>
                  {t("What type of account would you like to create?")}
                </SectionTitle>
                <CWBDropdown
                  // isDisabled={true}
                  className="account-dropdown"
                  value={this.state.selectedAccount}
                  isValueSelected={true}
                  placeholder={!this.state.selectedAccount && "Select Option"}
                  items={accounts}
                  onChange={(option: any) => {
                    this.setState({ selectedAccount: option });
                  }}
                />
              </Section>
            )}
            {selectedAccount.id === 1 && (
              <ActorCredentialStep {...this.props} />
            )}
            {selectedAccount.id === 2 && <AgentRegistration {...this.props} />}
            {selectedAccount.id === 3 && <CastingUserStep {...this.props} />}
          </Body>
        </WrapperColumns>
      </Wrapper>
    );
  }
}

interface StateProps { }

function mapStateToProps(state: iRootState): StateProps {
  return {};
}

interface DispatchProps { }

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {};
}

export default withI18n()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(Registration))
);

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;

  @media all and (max-width: 768px) {
    flex-direction: column;
  }
`;

const WrapperColumns = styled.div`
  position: relative;
  width: 100%;
  padding-right: 15px;
  padding-left: 15px;

  flex: 0 0 50%;
  max-width: 50%;

  @media all and (max-width: 768px) {
    flex: 0 0 100%;
    max-width: 100%;

    padding-left: 0 !important;
    padding-right: 0 !important;
  }
`;

const BodySellingFeatures = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;

  max-width: 480px;
  min-width: 480px;
  margin: 40px auto 60px auto;

  float: right !important;

  @media all and (max-width: 768px) {
    max-width: 680px;
    min-width: 40%;
    float: none !important;
    margin-bottom: 0;
    padding-right: 15px;
    padding-left: 15px;
  }
`;

const Body = styled(Modal)`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;

  max-width: 480px;
  min-width: 480px;
  background-color: ${(p) => p.theme.white};
  margin: 40px auto 60px auto;
  padding: 40px 80px;

  float: left !important;

  @media all and (max-width: 768px) {
    max-width: 560px;
    min-width: 40%;
    float: none !important;
    padding: 40px 10px;
  }

  .cwb-dropdown-wrapper {
    margin-top: 8px;
    max-width: 100%;
    height: 40px;

    .cwb-dropdown__single-value {
      font-size: ${(p) => p.theme["xs-font-size"]};
      font-weight: ${(p) => p.theme["font-weight-600"]};
    }

    .cwb-dropdown__indicator {
      width: 14px;
    }
  }

  .cwb-dropdown-wrapper > div {
    min-width: 100%;
    max-width: 100%;
  }

  .cwb-dropdown__placeholder {
    color: ${(p) => p.theme.darkGrey};
    font-size: ${(p) => p.theme["xs-font-size"]};
  }

  .cwb-dropdown-wrapper > div .cwb-dropdown__control {
    min-height: 40px;
    border: ${(props) => props.theme.dropDown["border"]} !important;
    border-radius: ${(props) => props.theme.dropDown["border-radius"]};
  }
`;

const StyledTitle = styled.div`
  font-size: ${(p) => p.theme["xxl-font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};
  margin-bottom: 8px;
`;
