import React, { useEffect, useState } from 'react';
import qs from 'query-string';
import { withI18n } from 'react-i18next';
import { connect } from 'react-redux';
import { Link as ReactRouterLink, RouteComponentProps } from 'react-router-dom';
import styled from 'styled-components';
import { Skeleton, Typography } from 'components/Common';
import ActorTrialSignUpForm from './components/ActorTrialSignUpForm';
import ActorSignUpForm from './components/ActorSignUpForm';
import Card from './components/Card';
import * as ServicesAPI from "../../shared/api/Services.api";
import HeaderComponent from "../HeaderComponent";
// @ts-ignore
import { CWBLoadingCrest } from "cwb-react";
// @ts-ignore
import pRetry from 'p-retry';

type Props = {
  t: any;
} & StateProps & DispatchProps & RouteComponentProps;

const ActorTrialSignUp: React.FC<Props> = ({
  t,
  location,
  currencySymbol,
  mediaLimit,
  numPhotos,
  history,
  yearlyPerMonthCharge,
  getSubscriptionServices
}) => {
  const [trialValid, setTrialValid] = useState(false);
  const [loading, setLoading] = useState(true);
  let redirectParams = qs.stringify({
    ...(qs.parse(location.search))
});

if(qs.parse(location.search).bd){
  redirectParams = qs.stringify({
    page:'openbd',
    ...(qs.parse(window.location.search))
});
}

  useEffect(() => {
    const run = async () => {

        const response = await fetch(process.env.REACT_APP_IpInfo);
        if(response.status !== 200){
          throw new Error(response.statusText);
        }
        return await response.json();
    }
    (async () => {
      let countryCode = null;
      try {
        try {
        const result = await pRetry(run, {
          onFailedAttempt: error => {
            console.log('attempt error', error.attemptNumber)
            if(error.attemptNumber >= 3){
              setTrialValid(true);
              setLoading(false);
              return;
            }
          },
          retries: 3
        });
        countryCode = result.country_code;
      }catch(err){
        countryCode = 'us';
      }
    
    const ServiceResponse = await ServicesAPI.getTrialService(countryCode);
    if((ServiceResponse.status === 200 && countryCode !== 'CA') || (qs.parse(location.search).dev) === "1"){
      setTrialValid(true);
      setLoading(false);
    }else {
      history.push(`/register/actor?${redirectParams}`) 
    }
   }catch(err){
    console.log(err);
  }
    })();
  },[setTrialValid, history, location, redirectParams]);

  useEffect(() => {
    const fetchSubscriptionServices = async () => {
      try {
        await getSubscriptionServices();
      } catch (e) {
        window.top.location.href = process.env.REACT_APP_CWB_500;
      }
    };

    fetchSubscriptionServices();
  }, [getSubscriptionServices]);

  const params = qs.stringify({
    ...(qs.parse(location.search))
  });

  return (
    <>
      <HeaderComponent />
      {
        loading ? <StyledDiv>
          <CWBLoadingCrest customLogoSrc="/images/icon-cwb-logo-grey.svg" />
        </StyledDiv>:
        <StyledDiv>
          <Typography align="center"  component="h1" variant="h1">
            {t("Not a member yet?")}
          </Typography>
          <Subheading align="center"  variant="h1">
            {t("Sign-up for our Free Trial till Sept 30th, 2021")}
          </Subheading>
          <div style={{display:'flex'}}>
          <Typography align="center"  component="h4" variant="h4">
            {t("Apply to this job and many others on Casting Workbook. New to Casting Workbook?")}
          </Typography>
          <a href="https://home.castingworkbook.com/for-actors/" target="_blank" rel="noopener noreferrer"><StyledTypography align="center"  color="link" variant="h4">
            {t("Click here to find out more")}
          </StyledTypography></a>
          </div>
          <StyledCard>
              <StyledTrialActorSignUpForm
              currencySymbol={currencySymbol}
              yearlyPerMonthCharge={yearlyPerMonthCharge}
              trialValid={trialValid}
              />
          </StyledCard>
          <Footnote align="center">
            {t(
              'Are you a Casting Director or Agent? Learn more about how Casting Workbook can support you.'
            )}
          </Footnote>
          <LinksContainer>
            <StyledReactRouterLink to={`/register/agent?${params}`}>
              {t('Agents & Managers')}
            </StyledReactRouterLink>
            <StyledReactRouterLink to={`/register/casting?${params}`}>
              {t('Casting Directors')}
            </StyledReactRouterLink>
          </LinksContainer>
        </StyledDiv>
}
    </>
  );
};

const StyledDiv = styled.div`
  width: 889px;
  display: flex;
  flex-flow: column nowrap;
  align-items: center;
  margin: ${(p) => p.theme.spacing(12, 'auto', 0)};

  @media all and (max-width: 520px) {
    width: unset;
    margin-top: ${(p) => p.theme.spacing(3)};
    padding: ${(p) => p.theme.spacing(0, 2)};
  }
`;

const Subheading = styled(Typography)`
  margin: ${(p) => p.theme.spacing(0, 0, 2)};

  @media all and (max-width: 520px) {
    margin: ${(p) => p.theme.spacing(0, 0, 2)};
  }
`;

const StyledCard = styled(Card)`
  width: 427px;
  display: flex;
  flex-flow: row nowrap;
  align-items: stretch;
  margin: ${(p) => p.theme.spacing(4,0,4)};
  padding: 0;

  @media all and (max-width: 520px) {
    flex-flow: column nowrap;
  }
`;

const StyledTypography = styled(Typography)`
margin-left: ${(p) => p.theme.spacing(1)};
cursor:pointer;
`;


const StyledTrialActorSignUpForm = styled(ActorTrialSignUpForm)`
  width: 100%;

  @media all and (max-width: 520px) {
    width: 100%;
  }
`;

const StyledActorSignUpForm = styled(ActorSignUpForm)`
  width: 100%;

  @media all and (max-width: 520px) {
    width: 100%;
  }
`;

const Footnote = styled(Typography)`
  margin-bottom: ${(p) => p.theme.spacing(2)};
  line-height: 19px;
`;

const LinksContainer = styled.div`
  margin-bottom: ${(p) => p.theme.spacing(6)};

  & > a + a {
    margin-left: ${(p) => p.theme.spacing(6)};
  }
`;

const StyledReactRouterLink = styled(ReactRouterLink)`
  && {
    color: #02B8F9;
    font-weight: bold;

    &:hover {
      color: #02B8F9;
      filter: brightness(1.15);
    }
  }
`;

type StateProps = {
  currencySymbol: string;
  mediaLimit: number;
  yearlyPerMonthCharge: number;
  numPhotos: number;
};

const mapStateToProps = (state: any) => ({
  currencySymbol: state.premiumModel.currencySymbol,
  mediaLimit: state.premiumModel.mediaLimit,
  numPhotos: state.premiumModel.headshot.premium,
  yearlyPerMonthCharge: state.premiumModel.yearlyPerMonthCharge
});

type DispatchProps = {
  getSubscriptionServices: () => void;
};

const mapDispatchToProps = (dispatch: any) => ({
  getSubscriptionServices: dispatch.premiumModel.getSubscriptionServices
});

export default withI18n()(connect(
  mapStateToProps,
  mapDispatchToProps
)(ActorTrialSignUp));