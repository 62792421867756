import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { withI18n } from 'react-i18next';
import { RouteComponentProps, withRouter } from 'react-router-dom';
// @ts-ignore
import { emailRegex } from 'cwb-react';
import styled from 'styled-components';
import PhoneInput from "react-phone-input-2";
import Button from '../Common/Button';
import Footer from '../Common/Footer';
import Modal from '../Common/Modal';
import PageContainer from '../Common/PageContainer';
import Typography from '../Common/Typography';
import {
  CheckboxWrapper,
  Spinner,
  StyledCheckbox,
  StyledInput
} from '../Common/StyledComponents';
import { getSessionId } from '../../helpers';
import { nameRegex } from "helpers/validations";
import { IGroupedCountry } from '../../helpers/ICountryList';
import {
  ICastingProApprovalDetails,
  ICastingReference
} from '../../shared/api/dtos/ICastingDtos';

interface Props extends StateProps, DispatchProps, RouteComponentProps {
  t: any;
}

const CastingAssociationsStep: React.FC<Props> = ({
  t,
  history,
  location,
  groupCountryList,
  isLoading,
  getGroupCountryList,
  createCastingPro
}) => {
  const options = [
    'CSA',
    'CCDA',
    'CDC',
    'ADCQ',
    'Other',
    'None'
  ];

  const [selectedAssoc, setSelectedAssoc] = useState<string[]>([]);
  const [showOtherForm, setShowOtherForm] = useState<boolean>(false);
  const [showNoneForm, setShowNoneForm] = useState<boolean>(false);
  const [assocName, setAssocName] = useState<string>('');
  const [references, setReferences] = useState<ICastingReference[]>([{
    name: '',
    company: '',
    email: '',
    phone: ''
  }, {
    name: '',
    company: '',
    email: '',
    phone: ''
  }]);
  const [country, setCountry] = useState<any[]>(['ca', 'ca']);
  const [assocError, setAssocError] = useState<string>('');
  const [errors, setErrors] = useState([{
    name: '',
    company: '',
    email: '',
    phone: ''
  }, {
    name: '',
    company: '',
    email: '',
    phone: ''
  }]);
  const [isSubmitClicked, setIsSubmitClicked] = useState<boolean>(false);

  useEffect(() => {
    if (!getSessionId()) {
      history.push({
        pathname: '/',
        search: location.search
      });
    }
  }, [history, location.search]);

  useEffect(() => {
    fetch(process.env.REACT_APP_IpInfo)
      .then(res => res.json())
      .then(result => {
        const countryCode = result.country_code.toLowerCase();
        setCountry([countryCode, countryCode]);
      })
      .catch((e) => {
        console.error(e);
      });
  }, [setCountry]);

  const handleChange = (option: string) => {
    if (selectedAssoc.includes(option)) {
      if (option === 'Other') setShowOtherForm(false);
      if (option === 'None') setShowNoneForm(false);
      setSelectedAssoc(selectedAssoc.filter(opt => opt !== option));
    } else {
      if (option === 'Other') {
        setShowOtherForm(true);
        setReferences([{
          name: '',
          company: '',
          email: '',
          phone: ''
        }, {
          name: '',
          company: '',
          email: '',
          phone: ''
        }]);
      }
      if (option === 'None') {
        setShowOtherForm(false);
        setShowNoneForm(true);
        setSelectedAssoc(['None']);
        setAssocName('');
        setAssocError('');
      } else {
        setShowNoneForm(false);
        setSelectedAssoc([
          ...selectedAssoc.filter(opt => opt !== 'None'),
          option
        ]);
      }
    }
  };

  const handleChangeReferences = (
    index: number,
    key: string,
    value: string
  ) => {
    const refs = JSON.parse(JSON.stringify(references));
    refs[index] = {
      ...refs[index],
      [key]: value
    };
    setReferences(refs);
    if (isSubmitClicked) checkValidation();
  };

  const checkValidation = () => {
    if (selectedAssoc.includes('None')) {
      let valid = false;
      const e = JSON.parse(JSON.stringify(errors));
      references.forEach((reference, index) => {
        const phoneRegex = /^[0-9]+$/i;

        if (!reference.name.trim()) {
          e[index].name = t('Name is required.');
        } else if (reference.name.trim().length < 2) {
          e[index].name = t('Name must have at least 2 characters.');
        } else if (!nameRegex.test(reference.name.trim())) {
          e[index].name = t('Name can only contain letters, spaces, -, \' and `.');
        } else {
          e[index].name = '';
        }

        if (!reference.company.trim()) {
          e[index].company = t('Company name is required.');
        } else {
          e[index].company = '';
        }

        if (!reference.email.trim()) {
          e[index].email = t('Email is required.');
        } else if (!emailRegex.test(reference.email.trim())) {
          e[index].email = t('Email is invalid.');
        } else {
          e[index].email = '';
        }

        if (!reference.phone.trim()) {
          e[index].phone = t('Phone number is required.');
        } else if (!phoneRegex.test(reference.phone.trim())) {
          e[index].phone = t('Phone number can only contain numbers.');
        } else {
          e[index].phone = '';
        }
      });

      setAssocError('');
      setErrors(e);
      valid = e.reduce((acc: any, cur: any) => {
        return !(
          acc.name || cur.name ||
          acc.email || cur.email ||
          acc.phone || cur.phone
        );
      });
      if (!valid) {
        return false;
      }
    } else if (selectedAssoc.includes('Other')) {
      if (!assocName) {
        setAssocError(t('Association name is required.'));
        return false;
      }
    } else {
      if (!selectedAssoc.length) {
        setAssocError(t('You must select at least one association or "None"'));
        return false;
      }
    }
    setAssocError('');
    setErrors([{
      name: '',
      company: '',
      email: '',
      phone: ''
    }, {
      name: '',
      company: '',
      email: '',
      phone: ''
    }]);
    return true;
  };

  const handleClick = async () => {
    setIsSubmitClicked(true);
    if (!checkValidation()) return;

    let dto: ICastingProApprovalDetails;
    if (selectedAssoc.includes('None')) {
      dto = {
        associations: '',
        otherAssociation: '',
        castingReferences: references.map((ref) => {
          return { ...ref, phone: `+${ref.phone}` };
        })
      };
    } else {
      const getAssocString = (array: string[]) => array.join(', ');

      dto = {
        associations: getAssocString(selectedAssoc),
        otherAssociation: assocName,
        castingReferences: []
      };
    }

    try {
      await createCastingPro(dto);
      history.push({
        pathname: '/casting/pro',
        search: location.search
      });
    } catch (e) {
      window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    }
  };

  const associations = options.map((option, index) => (
    <CheckboxWrapper
      key={index}
      onClick={() => handleChange(option)}
    >
      <StyledCheckbox isChecked={selectedAssoc.includes(option)} />
      <StyledCbLabel>
        {['Other', 'None'].includes(option) ? t(option) : option}
      </StyledCbLabel>
    </CheckboxWrapper>
  ));

  const otherForm = (
    <OtherForm>
      <Typography color="medGrey">
        {t('Please enter your association below.')}
      </Typography>
      <Typography
        color="medGrey"
        variant="captionBold"
      >
        {t('Association name')}
      </Typography>
      <StyledInput
        className={assocError ? 'invalid' : ''}
        type="text"
        value={assocName}
        onChange={e => setAssocName(e.target.value)}
      />
    </OtherForm>
  );

  const noneForm = (
    <NoneForm>
      <Divider />
      <Typography color="medGrey">
        {t('If you are not a member of a Casting Association 2 Agent references are required.')}
      </Typography>
      {[1, 2].map((refNum, index) => (
        <ReferenceContainer key={index}>
          <Typography
            color="darkGrey"
            component="h2"
            gutterBottom
            variant="h5"
          >
            {t(`Reference ${refNum}`)}
          </Typography>
          <Typography
            color="medGrey"
            variant="captionBold"
          >
            {t('Name')}
          </Typography>
          <StyledInput
            className={errors[index].name ? 'invalid' : ''}
            name="name"
            type="text"
            value={references[index].name}
            onChange={e => handleChangeReferences(
              index,
              e.target.name,
              e.target.value
            )}
          />
          <Typography
            color="error"
            component="span"
            variant="caption"
          >
            {errors[index].name}
          </Typography>
          <Typography
            color="medGrey"
            variant="captionBold"
          >
            {t('Company')}
          </Typography>
          <StyledInput
            className={errors[index].company ? 'invalid' : ''}
            name="company"
            type="text"
            value={references[index].company}
            onChange={e => handleChangeReferences(
              index,
              e.target.name,
              e.target.value
            )}
          />
          <Typography
            color="error"
            component="span"
            variant="caption"
          >
            {errors[index].company}
          </Typography>
          <Typography
            color="medGrey"
            variant="captionBold"
          >
            {t('Email')}
          </Typography>
          <StyledInput
            className={errors[index].email ? 'invalid' : ''}
            name="email"
            type="text"
            value={references[index].email}
            onChange={e => handleChangeReferences(
              index,
              e.target.name,
              e.target.value
            )}
          />
          <Typography
            color="error"
            component="span"
            variant="caption"
          >
            {errors[index].email}
          </Typography>
          <Typography
            color="medGrey"
            variant="captionBold"
          >
            {t('Phone Number')}
          </Typography>
          <PhoneInput
            containerStyle={{ height: '40px' }}
            country={country[index]}
            inputStyle={{
              height: '40px',
              border: '1px solid #D3DDE9',
              ...(errors[index].phone ? {
                color: '#AF5756',
                backgroundColor: '#FEF6F6',
                border: '1px solid #AF5756'
              } : {})
            }}
            value={references[index].phone}
            onChange={(phoneNumber) => handleChangeReferences(
              index,
              'phone',
              phoneNumber
            )}
          />
          <Typography
            color="error"
            component="span"
            variant="caption"
          >
            {errors[index].phone}
          </Typography>
          {index === 0 && (<Divider />)}
        </ReferenceContainer>
      ))}
    </NoneForm>
  );

  return (
    <PageContainer>
      <StyledModal>
        <Typography
          align="center"
          component="h1"
          variant="h2"
        >
          {t('What Casting Association(s) do you belong to?')}
        </Typography>
        <FormContainer>
          <Typography
            color="medGrey"
            gutterBottom
            variant="captionBold"
          >
            {t('Please select your Casting Association(s):')}
          </Typography>
          <CheckboxContainer>
            {associations}
          </CheckboxContainer>
          {showOtherForm && otherForm}
          <Typography
            color="error"
            variant="caption"
          >
            {assocError}
          </Typography>
          {showNoneForm && noneForm}
          <StyledButton
            disabled={isLoading}
            onClick={() => handleClick()}
          >
            {t('Continue')}
            {isLoading && (
              <StyledSpinner src="../images/spinner.svg" />
            )}
          </StyledButton>
        </FormContainer>
      </StyledModal>
      <Footer />
    </PageContainer>
  );
};

const StyledModal = styled(Modal)`
  width: 480px;
  padding: ${p => p.theme.spacing(5, 8)};
`;

const FormContainer = styled.div`
  width: 100%;
  margin-top: ${p => p.theme.spacing(2)};
  padding: ${p => p.theme.spacing(0, 2)};

  & input {
    font-size: ${p => p.theme['s-font-size']};
  }

  & button {
    margin-top: ${p => p.theme.spacing(2)};
  }
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  justify-content: space-between;

  & > div {
    width: 48%;
    margin: ${p => p.theme.spacing(0.5, 0)};

    &:first-child { margin: 0 }
  }
`;

const StyledCbLabel = styled.span`
  font-size: ${p => p.theme['xxs-font-size']};
  letter-spacing: 0.2px;
  margin-left: 4px;
`;

const OtherForm = styled.div`
  & > p:first-child {
    margin: ${p => p.theme.spacing(1, 0)};
  }
`;

const NoneForm = styled.div`
  & > p {
    margin-bottom: ${p => p.theme.spacing(2)};
  }
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: ${p => p.theme.spacing(3, 0)};
  background-color: ${p => p.theme.palette.grey[3]};
`;

const ReferenceContainer = styled.div`
  & > input { margin: ${p => p.theme.spacing(0)}; }
  & > p { margin: ${p => p.theme.spacing(2, 0, 1)}; }
`;

const StyledButton = styled(Button)`
  width: 100%;
`;

const StyledSpinner = styled(Spinner)`
  margin-left: ${p => p.theme.spacing(1)};
`;

interface StateProps {
  groupCountryList: IGroupedCountry[];
  isLoading: boolean;
}

const mapStateToProps = (state: any): StateProps => ({
  groupCountryList: state.referencesModel.groupCountryList,
  isLoading: state.castingModel.isLoading
});

interface DispatchProps {
  getGroupCountryList: () => void;
  createCastingPro: (dto: ICastingProApprovalDetails) => void;
}

const mapDispatchToProps = (dispatch: any): DispatchProps => ({
  getGroupCountryList: dispatch.referencesModel.getGroupCountryList,
  createCastingPro: dispatch.castingModel.createCastingPro
});

export default withRouter(withI18n()(connect(
  mapStateToProps,
  mapDispatchToProps
)(CastingAssociationsStep)));
