import React, { useCallback, useEffect, useState } from 'react';
import qs from 'query-string';
import { withI18n } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  Footer,
  Modal,
  PageContainer,
  Typography
} from '../Common';
import CWBLabsActorRegistrationComplete from 'components/CWBLabs/ActorRegistrationComplete';

interface Props extends StateProps, RouteComponentProps {
  t: any;
  free?: boolean;
}

const ActorRegistrationComplete: React.FC<Props> = ({
  t,
  location,
  clientId,
  free
}) => {
  const [remainingTime, setRemainingTime] = useState<number>(19);

  const redirect = useCallback(() => {
    const baseUrl = process.env.REACT_APP_CWB_Site;
    const params = qs.stringify({
      login: clientId,
      ...(qs.parse(location.search))
    });
    window.location.replace(`${baseUrl}/login/?${params}`);
  }, [clientId, location.search]);

  useEffect(() => {
    if (remainingTime === 0) {
      redirect();
    } else {
      setTimeout(() => {
        setRemainingTime(remainingTime - 1);
      }, 1000);
    }
  }, [remainingTime, redirect]);

  return qs.parse(location.search).cwblabs ? (
    <CWBLabsActorRegistrationComplete />
  ) : (
    <PageContainer>
      <StyledModal>
        <Typography
          align="center"
          component="h1"
          variant="h2"
        >
          {free ? t(
            'Thank you for signing up for your Free Casting Workbook account!'
          ) : t(
            'Thank you for signing up for your Pro Casting Workbook account!'
          )}
        </Typography>
        <Icon alt="check" src="/images/icon-tick.svg" />
        <StyledTypography
          align="center"
          color="darkGrey"
        >
          {free ? t(
            'You now have access to all of Casting Workbooks Free features and content!'
          ) : t(
            'You now have access to all of Casting Workbooks Pro features and content!'
          )}
        </StyledTypography>
        <StyledTypography
          align="center"
          color="darkGrey"
        >
          {t('On a mobile device? Go to the App Store or Google play store to download the powerful Actor App!')}
        </StyledTypography>
        <StyledTypography
          align="center"
          color="darkGrey"
        >
          {t('You will automatically be redirected to the login screen, in')}
          &nbsp;<span>{remainingTime}</span>&nbsp;
          {t('seconds.')}
        </StyledTypography>
        <Button onClick={() => redirect()}>
          {t('Go to Casting Workbook')}
        </Button>
      </StyledModal>
      <Footer />
    </PageContainer>
  );
};

const StyledModal = styled(Modal)`
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${p => p.theme.spacing(11, 18, 15)};
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin: ${p => p.theme.spacing(3, 6)};
  padding: 7px 7px 6px;
  background-color: ${p => p.theme.palette.system.success};
  border-radius: 50%;
`;

const StyledTypography = styled(Typography)`
  margin-bottom: ${p => p.theme.spacing(3)};
  font-size: 16px;
  line-height: 22px;

  & > span {
    color: ${p => p.theme.palette.misc.darkBlue};
    text-decoration: underline;
  }
`;

interface StateProps {
  clientId: string;
}

const mapStateToProps = (state: any) => ({
  clientId: state.actorModel.clientId
});

export default withI18n()(withRouter(connect(
  mapStateToProps,
  null
)(ActorRegistrationComplete)));
