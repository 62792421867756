import React, { useCallback, useEffect, useState } from 'react';
import qs from 'query-string';
import { withI18n } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  Button,
  TrialFooter,
  Modal,
  PageContainer,
  Typography
} from '../Common';
import { getWebSessionKey } from "../../helpers";

interface Props extends StateProps, RouteComponentProps {
  t: any;
}

const ActorTrailRegistrationComplete: React.FC<Props> = ({
  t,
  location,
  clientId,
}) => {

  const [remainingTime, setRemainingTime] = useState<number>(19);

  const redirect = useCallback(() => {
    const baseUrl = process.env.REACT_APP_CWB_Site;
    const k = getWebSessionKey();
    let params = qs.stringify({
      k,
      signup:1,
      login: clientId,
      ...(qs.parse(location.search))
    });
    if(qs.parse(location.search).bd){
      params = qs.stringify({
        page:'openbd',
        k,
        signup:1,
        login: clientId,
        ...(qs.parse(location.search))
      })
    }
    window.location.replace(`${baseUrl}/login/go?${params}`);
  }, [clientId, location.search]);

  useEffect(() => {
    if (remainingTime === 0) {
      redirect();
    } else {
      setTimeout(() => {
        setRemainingTime(remainingTime - 1);
      }, 1000);
    }
  }, [remainingTime, redirect]);

  return <PageContainer>
      <StyledModal>
        <Typography
          align="center"
          component="h1"
          variant="h2"
        >
         {t("Thank you for signing up for your Casting Workbook free trial")}
        </Typography>
        <Icon alt="check" src="/images/icon-tick.svg" />
        <StyledTypography
          align="center"
          color="darkGrey"
        >
          {t('You now have access to all of Casting Workbooks Pro features and content!')}
        </StyledTypography>
        <StyledTypography
          align="center"
          color="darkGrey"
        >
          {t('On a mobile device? Go to the App Store or Google play store to download the powerful Actor App!')}
        </StyledTypography>
        <StyledTypography
          align="center"
          color="darkGrey"
        >
          {t('You will be automatically redirected to the submission screen, in')}
          &nbsp;<span>{remainingTime}</span>&nbsp;
          {t('seconds.')}
        </StyledTypography>
        <StyledTypography align="center"
          color="darkGrey">
          or
        </StyledTypography>
        <StyledButton onClick={() => redirect()}>
          {t('Submit to breakdown')}
        </StyledButton>
        <TrialFooter />
      </StyledModal>
    </PageContainer>
    ;
};

const StyledModal = styled(Modal)`
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${p => p.theme.spacing(11, 18, 3)};
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin: ${p => p.theme.spacing(3, 6)};
  padding: 7px 7px 6px;
  background-color: ${p => p.theme.palette.system.success};
  border-radius: 50%;
`;

const StyledTypography = styled(Typography)`
  margin-bottom: ${p => p.theme.spacing(3)};
  font-size: 16px;
  line-height: 22px;

  & > span {
    color: ${p => p.theme.palette.misc.darkBlue};
    text-decoration: underline;
  }
`;

const StyledButton = styled(Button)`
margin-bottom:${p => p.theme.spacing(3)}
`;

interface StateProps {
  clientId: string;
}

const mapStateToProps = (state: any) => ({
  clientId: state.actorModel.clientId
});

export default withI18n()(withRouter(connect(
  mapStateToProps,
  null
)(ActorTrailRegistrationComplete)));
