import * as http from '../http';
import { IDiscount } from './dtos/IPromoCodeDto';

const api = `${process.env.REACT_APP_REG_Api}/PromoCode`;

export const verifyDiscount = (code: string) => {
  return http.get<IDiscount>(`${api}/verify-discount/${code}`);
};

export const getDiscount = (code: string) => {
  return http.get<IDiscount>(`${api}/discount/${code}`);
};
