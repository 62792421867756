import React from 'react';
import { withI18n } from 'react-i18next';
import styled from 'styled-components';
import { withRouter } from 'react-router';
import { RouteComponentProps } from 'react-router-dom';
import Footer from './Footer';
import Modal from './Modal';
import PageContainer from './PageContainer';

interface IProps extends RouteComponentProps<any> {
  t?: any;
}

class ConfirmEmailComponent extends React.Component<IProps, {}> {
  render() {
    const { t, location } = this.props;
    return (
      <PageContainer>
        <Wrapper>
          <StyledTitle>
            {t('Thank you for verifying your email address.')}
          </StyledTitle>
          <LoginButton
            href={`${process.env.REACT_APP_CWB_Site}/login/${location.search}`}
          >
            {t('Sign In')}
          </LoginButton>
        </Wrapper>
        <Footer />
      </PageContainer>
    );
  }
}

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  flex-wrap: wrap;
  align-items: center;

  max-width: 610px;
  min-width: 610px;
  background-color: ${(p) => p.theme.white};
  padding: 32px 96px 48px;
  box-shadow: 0px 8px 20px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  text-align: center;

  @media all and (max-width: 520px) {
    min-width: 0%;
    padding: 32px 24px;
    justify-content: flex-start;
    align-items: flex-start;
  }
`;

const StyledTitle = styled.div`
  color: ${p => p.theme.darkGrey} !important;
  font-size: ${p => p.theme["xxl-font-size"]};
  font-weight: ${p => p.theme["font-weight-600"]};
  text-align: center;
  margin-bottom: ${p => p.theme.spacing(3)};
`;

const LoginButton = styled.a`
  width: 60%;
  display: flex;
  justify-content: center;
  color: ${p => p.theme.white} !important;
  font-size: ${p => p.theme["font-size"]};
  font-weight: ${p => p.theme["font-weight-600"]};
  background-color: ${p => p.theme.btnBlue};
  border-radius: 4px;
  padding: 9px 0;
  @media all and (max-width: 520px) {
    width: 100%;
  }
`;

export default withI18n()(withRouter(ConfirmEmailComponent));
