import React from 'react';
import qs from 'query-string';
import { withI18n, getI18n } from 'react-i18next';
import { connect } from 'react-redux';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import {
  Modal,
  PageContainer,
  Typography
} from '../../Common';

interface Props extends StateProps, RouteComponentProps {
  t: any;
}

const ActorTrailRegistrationComplete: React.FC<Props> = ({
  t,
  location,
  clientId,
}) => {

  let iosAppLink = " https://apps.apple.com/ca/app/actor-app-work-like-a-pro/id911898214";
  if (getI18n().language === "es") {
    iosAppLink="https://apps.apple.com/es/app/actor-app-work-like-a-pro/id911898214"
  }

  return <PageContainer>
      <StyledModal>
        <LeftPanel>
        <Icon alt="check" src="/images/icon-tick.svg" />
        <Typography
          align="center"
          variant="h2"
        >
         {t("That’s it, you’re done!")}
        </Typography>
        <SubTitle
          align="center"
          variant="body"
        >
         {t("Thank you for signing up with Casting Workbook! Please enjoy your free trial.")}
        </SubTitle>
        <Divider />
        <Typography
          align="center"
          variant="h2"
        >
         {t("What’s next?")}
        </Typography>
        <SubTitle
          align="center"
          variant="body"
        >
         {t("We are currently working on our webflow so for now, please download the free app and login with your new account to make your submission.")}
        </SubTitle>
        <SubTitle
          align="center"
          variant="body"
        >
         {t("Your workbook id is:")} <b>{clientId}</b>
        </SubTitle>
        <AppContainer>
            <a href="https://play.google.com/store/apps/details?id=com.cwb.selftapingapp" target="_blank" rel="noopener noreferrer"> 
              <AppImg src="/images/google-play-app.svg" alt="google-play"/>
            </a>
            <a href={iosAppLink} target="_blank" rel="noopener noreferrer"> 
              <AppImg src="/images/ios-app.svg" alt="google-play"/>
            </a>
        </AppContainer>
        </LeftPanel>
        <RightPanel>
            <Preview alt="preview" src="/images/mockup.svg" />
            <RightText>{t("Once in the app, open")} <b>{t("Jobs")} </b><SearchIcon alt="search" src="/images/search-icon.svg" /></RightText>
            <RightText>{t("and make your submission.")}</RightText>
        </RightPanel>
      </StyledModal>
    </PageContainer>
    ;
};

const StyledModal = styled(Modal)`
  width: 891px;
  display: flex;
  height:668px;
`;

const LeftPanel = styled.div`
display:flex;
flex-direction:column;
width:531px;
justify-content:center;
align-items:center;
height:100%;
padding:0px 88px;
`;

const RightPanel = styled.div`
width: 360px;
height:100%;
background: #F1F2F4;
justify-content:center;
align-items:center;
display:flex;
flex-direction:column;
`;

const Icon = styled.img`
  width: 30px;
  height: 30px;
  margin: ${p => p.theme.spacing(3, 6)};
  padding: 7px 7px 6px;
  background-color: ${p => p.theme.palette.system.success};
  border-radius: 50%;
`;

const SubTitle= styled(Typography)`
margin-top: ${p => p.theme.spacing(2)};
`;

const Divider = styled.div`
margin-top: ${p => p.theme.spacing(5)};
margin-bottom: ${p => p.theme.spacing(5)};
border-top: 1px solid #F1F2F4;
width:284px;
height:0px;
`;

const AppContainer = styled.div`
width:349px;
display:flex;
justify-content:space-between;
margin-top: ${p => p.theme.spacing(10)};
margin-bottom: ${p => p.theme.spacing(5)};
`;

const AppImg = styled.img`
width: 168px;
height: 48px;
`;

const SearchIcon = styled.img`
width:12px;
height:12px;
`;

const Preview = styled.img`
width: 336px;
height: 527px;
margin-bottom: ${p => p.theme.spacing(2)};
`;

const RightText = styled.span`
font-size: ${(p) => p.theme["lg-font-size"]};
font-family: Open Sans;
`;

const StyledTypography = styled(Typography)`
  margin-bottom: ${p => p.theme.spacing(3)};
  font-size: 16px;
  line-height: 22px;

  & > span {
    color: ${p => p.theme.palette.misc.darkBlue};
    text-decoration: underline;
  }
`;


interface StateProps {
  clientId: string;
}

const mapStateToProps = (state: any) => ({
  clientId: state.actorModel.clientId
});

export default withI18n()(withRouter(connect(
  mapStateToProps,
  null
)(ActorTrailRegistrationComplete)));
