import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
import { StripeProvider } from "react-stripe-elements";

import ScrollToTop from "./components/ScrollToTop";
import RouteWrapper from "./components/RouteWrapper";

import AccountConfirmation from "./components/Actor/AccountConfirmation";
import AccountConfirmed from "./components/Actor/AccountConfirmed";
import ConfirmEmailComponent from "./components/Common/ConfirmEmailComponent";
import FailConfirmEmailComponent from "./components/Common/FailConfirmEmailComponent";

import Registration from "./components/Registration";
import CastingFilmCenterStep from "./components/Director/CastingFilmCenterStep";
import CastingAccessStep from "./components/Director/CastingAccessStep";
import CastingAssociationsStep from "./components/Director/CastingAssociationsStep";
import CastingProAccountStep from "./components/Director/CastingProAccountStep";
import CastingLimitedAccountStep from "./components/Director/CastingLimitedAccountStep";
import SuccessSignUpBizhours from "./components/Common/SuccessSignUpBizhours";
import ActorCreatePhoneStep from "./components/Actor/SignUp/ActorCreatePhoneStep";
import ActorVerifyPhoneStep from "./components/Actor/SignUp/ActorVerifyPhoneStep";
import ActorStageNameStep from "./components/Actor/SignUp/ActorStageNameStep";
import ActorFilmCenterStep from "./components/Actor/SignUp/ActorFilmCenterStep";
import ActorTrialFilmCenterStep from "./components/Actor/SignUp/ActorTrialFilmCenterStep";
import ActorRegistrationUpgradeTable from "./components/Actor/SignUp/ActorRegistrationUpgradeTable";
import ActorRegistrationComplete from "./components/Actor/ActorRegistrationComplete";
import ActorTrailRegistrationComplete from './components/Actor/ActorTrailRegistrationComplete';
import ActorPaymentStep from "./components/Actor/SignUp/ActorPaymentStep";
import ActorPaymentProcessing from "./components/Actor/ActorPaymentProcessing";
import ActorPaymentDeclined from "./components/Actor/ActorPaymentDeclined";
import ActorUpgradeTable from "./components/Actor/ActorUpgradeTable";
import ActorUpgradePayment from "./components/Actor/ActorUpgradePayment";
import ActorUpgradeComplete from "./components/Actor/ActorUpgradeComplete";
import StripeActorPaymentWrapper from "./components/Actor/StripeActorPaymentWrapper";
import ActorTrialDetail from './components/Actor/SignUp/ActorTrialDetail';
import TrialActorApp from './components/Actor/SignUp/TrailActorApp';

import AcademyUserTypeSelect from "./components/Academy/UserTypeSelect";

import UserTypeSelect from "./components/CWBLabs/UserTypeSelect";
import ActorSignUp from "./components/CWBLabs/ActorSignUp";
import ActorTrialSignUp from "./components/CWBLabs/ActorTrialSignUp";
import PerformerSignUp from "./components/CWBLabs/PerformerSignUp";
import SignUpSuccess from "./components/CWBLabs/SignUpSuccess";
import SignUpFailure from "./components/CWBLabs/SignUpFailure";

import { IActorAccountDto } from "./shared/api/dtos/IActorDto";
import { getCurUserSignUp, getCountryCode } from "./helpers";
import ActorPaymentDetails from "./components/Actor/ActorPaymentDetails";
import BridgeAuthRoute from "./components/BridgeAuthRoute";
import SeamlessAuthRoute from "./components/SeamlessAuthRoute";

const Routes: React.FC = () => {
  const getStripeKey = () => {
    const user: IActorAccountDto = getCurUserSignUp();
    const userfilmCenter = user!.actorFilmCenter;

    let countryCode = getCountryCode();
    if (userfilmCenter && userfilmCenter.country)
      countryCode = userfilmCenter.country;

    if (countryCode === "CA") return process.env.REACT_APP_Stripe_CAD_test_key;
    // rest of the world, including US
    return process.env.REACT_APP_Stripe_USD_test_key;
  };

  return (
    <>
      <ScrollToTop />
      <Switch>
        <BridgeAuthRoute
          path="/actor/payment/details"
          component={ActorPaymentDetails}
        />
        <Route
          exact
          path="/"
          render={(routeProps) => (
            <Redirect
              to={{
                pathname: "/register",
                search: routeProps.location.search,
              }}
            />
          )}
        />
        <RouteWrapper exact path="/register" component={Registration} />
        <Route exact path="/register/performer" component={PerformerSignUp} />
        <Route path="/register/cwblabs/actor" component={ActorSignUp} />
        <Route path="/register/special/actor" component={ActorTrialSignUp} />
        <RouteWrapper path="/register/:accountType?" component={Registration} />
        <Route path="/academy/select-account-type" component={AcademyUserTypeSelect} />
        <Route path="/select-account-type" component={UserTypeSelect} />
        <RouteWrapper path="/casting/1" component={CastingFilmCenterStep} />
        <RouteWrapper
          path="/casting/2"
          render={(routeProps) => <CastingAccessStep {...routeProps} />}
        />
        <RouteWrapper
          path="/casting/select-associations"
          component={CastingAssociationsStep}
        />
        <RouteWrapper path="/casting/pro" component={CastingProAccountStep} />
        <RouteWrapper
          path="/casting/limited"
          component={CastingLimitedAccountStep}
        />
        <RouteWrapper
          path="/agent/completed"
          render={(routeProps) => <SuccessSignUpBizhours {...routeProps} />}
        />
        <RouteWrapper path="/actor/1" component={ActorCreatePhoneStep} />
        <RouteWrapper path="/actor/2" component={ActorVerifyPhoneStep} />
        <RouteWrapper path="/actor/3" component={ActorStageNameStep} />
        <RouteWrapper path="/actor/4" component={ActorFilmCenterStep} />
        <RouteWrapper path='/actor/special/4' component={ActorTrialFilmCenterStep} />
        <RouteWrapper
          path="/actor/5"
          component={ActorRegistrationUpgradeTable}
        />
        <RouteWrapper
          path="/actor/trial-detail"
          component={ActorTrialDetail}
        />
        <RouteWrapper
          path="/actor/free-trial/completed"
          render={(routerProps) => (
            <ActorTrailRegistrationComplete {...routerProps} />
          )}
        />
        <RouteWrapper
          path="/trial-actor-app"
          render={(routerProps) => (
            <TrialActorApp {...routerProps} />
          )}
        />
        <RouteWrapper
          path="/actor/free-completed"
          render={(routerProps) => (
            <ActorRegistrationComplete free {...routerProps} />
          )}
        />
        <RouteWrapper
          path="/actor/payment"
          render={(routerProps) => (
            <StripeProvider apiKey={getStripeKey()}>
              <StripeActorPaymentWrapper>
                <ActorPaymentStep {...routerProps} />
              </StripeActorPaymentWrapper>
            </StripeProvider>
          )}
        />
        <RouteWrapper
          path="/actor/payment-processing"
          component={ActorPaymentProcessing}
        />
        <RouteWrapper
          path="/actor/payment-declined"
          component={ActorPaymentDeclined}
        />
        <RouteWrapper
          path="/actor/pro-completed"
          component={ActorRegistrationComplete}
        />
        <RouteWrapper
          path="/actor/completed"
          render={(routerProps) => <SuccessSignUpBizhours {...routerProps} />}
        />
        <SeamlessAuthRoute
          isUpgrade
          path="/actor/upgrade-to-premium"
          component={ActorUpgradeTable}
        />
        <SeamlessAuthRoute
          isUpgrade
          path="/actor/upgrade-to-pro"
          component={ActorUpgradeTable}
        />
        <SeamlessAuthRoute
          path="/actor/select-membership"
          render={(routerProps) => (
            <StripeProvider apiKey={getStripeKey()}>
              <StripeActorPaymentWrapper>
                <ActorUpgradePayment {...routerProps} />
              </StripeActorPaymentWrapper>
            </StripeProvider>
          )}
        />
        <SeamlessAuthRoute
          isUpgrade
          path="/actor/upgrade-completed"
          component={ActorUpgradeComplete}
        />
        <RouteWrapper
          path="/actor/account-confirmation"
          render={(routerProps) => <AccountConfirmation {...routerProps} />}
        />
        <RouteWrapper
          path="/actor/account-confirmed"
          render={(routerProps) => <AccountConfirmed {...routerProps} />}
        />
        <Route path="/performer/completed" component={SignUpSuccess} />
        <Route path="/performer/failed" component={SignUpFailure} />
        <RouteWrapper
          path="/account-confirmed"
          render={(routerProps) => <ConfirmEmailComponent {...routerProps} />}
        />
        <RouteWrapper
          path="/failConfirm"
          render={(routerProps) => (
            <FailConfirmEmailComponent {...routerProps} />
          )}
        />
        <Route
          render={() => {
            window.location.replace(process.env.REACT_APP_CWB_Site);
            return null;
          }}
        />
      </Switch>
    </>
  );
};

export default Routes;
