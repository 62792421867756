import React from 'react';
import { withI18n } from 'react-i18next';
import styled from 'styled-components';
import { Button, TextInput, Typography } from 'components/Common';
import { Spinner } from 'components/Common/StyledComponents';
import Card from './components/Card';
import Footer from './components/Footer';
import Page from './components/Page';
import { IActorPhone } from 'shared/api/dtos/IActorDto';

type Props = {
  t: any;
  canResend: boolean;
  errors: any;
  fields: IActorPhone;
  isLoading: boolean;
  onChange: (e: any) => void;
  onSubmit: (e: any) => void;
  onResendCode: (e: any) => void;
  onChangePhone: () => void;
};

const ActorVerifyPhoneStep: React.FC<Props> = ({
  t,
  canResend,
  errors,
  fields,
  isLoading,
  onChange: handleChange,
  onSubmit: handleSubmit,
  onResendCode: handleResendCode,
  onChangePhone: handleChangePhone
}) => {
  return (
    <StyledPage>
      <StyledCard>
        <Typography align="center" component="h1" gutterBottom variant="h2">
          {t('Verify your phone number')}
        </Typography>
        <Typography align="center" color="medGrey" gutterBottom>
          {t('Enter the 4 digit code we sent to')}
        </Typography>
        <Typography align="center" color="darkGrey" variant="bodyBold">
          {fields && fields.phone}
        </Typography>
        <Form onSubmit={handleSubmit}>
          <TextInput
            error={Boolean(errors.Code)}
            errorMessage={errors.Code}
            label={t('Verification Code')}
            name="code"
            value={fields.code}
            onChange={handleChange}
          />
          <StyledButton
            startIcon={
              isLoading && (
                <Spinner
                  className="spinner-width"
                  src="../images/spinner.svg"
                />
              )
            }
          >
            {t('Continue')}
          </StyledButton>
        </Form>
        <TextButton disabled={!canResend} onClick={handleResendCode}>
          {canResend ? t('Resend code') : t('Code resent!')}
        </TextButton>
        <TextButton onClick={handleChangePhone}>
          {t('Change your phone number')}
        </TextButton>
      </StyledCard>
      <StyledFooter />
    </StyledPage>
  );
};

const StyledPage = styled(Page)`
  @media all and (max-width: 520px) {
    padding: ${(p) => p.theme.spacing(10, 2, 0)};
  }
`;

const StyledCard = styled(Card)`
  width: 480px;
  padding: ${(p) => p.theme.spacing(4, 10)};

  @media all and (max-width: 520px) {
    width: 100%;
    padding: ${(p) => p.theme.spacing(4, 2)};
  }
`;

const Form = styled.form`
  width: 100%;
  margin: ${(p) => p.theme.spacing(2, 0)};
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${(p) => p.theme.spacing(3)};
`;

const TextButton = styled.button`
  color: ${(p) => p.theme.palette.button.normal};
  font-size: 14px;
  font-weight: 600;
  line-height: 19px;
  background-color: transparent;
  border: none;

  &:hover {
    color: ${(p) => p.theme.palette.button.hover};
  }

  &:active {
    color: ${(p) => p.theme.palette.button.active};
  }

  &:focus {
    outline: none;
  }

  &:disabled {
    color: ${(p) => p.theme.typography.color.disabled};
    cursor: default;
  }

  & + & {
    margin-top: ${(p) => p.theme.spacing(1)};
  }
`;

const StyledFooter = styled(Footer)`
  margin-top: ${(p) => p.theme.spacing(3)};
`;

export default withI18n()(ActorVerifyPhoneStep);
