import axios from "axios";
import { IStripeCharge } from "./dtos/IStripeDto";
// @ts-ignore
import { getAuthConfig } from "cwb-react";
import { TrackingData } from "./dtos/ITrackingDataDto";

// TODO: response will change to include JWT
export const createStripeSubscription = (
  actorSignUpId: number,
  dto: IStripeCharge
) => {
  const trackingData = JSON.parse(
    localStorage.getItem("tData")
  ) as TrackingData;
  dto.trackingData = trackingData;
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_REG_Api}/StripeSubscription/create/${actorSignUpId}${window.location.search}`,
    headers: {
      "Content-Type": "application/json",
      CWB: "1",
      ...getAuthConfig().headers,
    },
    data: dto,
    withCredentials: true,
  });
};

export const upgradeFreemium = (dto: IStripeCharge) => {
  return axios({
    method: "post",
    url: `${process.env.REACT_APP_REG_Api}/StripeSubscription/upgrade`,
    headers: {
      "Content-Type": "application/json",
      CWB: "1",
      ...getAuthConfig().headers,
    },
    data: dto,
    withCredentials: true,
  });
};
