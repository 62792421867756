import * as http from '../http';
// @ts-ignore
import { getAuthConfig } from 'cwb-react';

const api = `${process.env.REACT_APP_REG_Api}/Payment`;

export const getPaymentStatus = (serviceId: number) => {
  return http.get(`${api}/status/${serviceId}`, {
    method: 'GET',
    mode: 'cors',
    credentials: 'include',
    headers: {
      CWB: '1',
      ...getAuthConfig().headers
    }
  });
};
