import React from 'react';
// @ts-ignore
import { CWBDropdown } from 'cwb-react';
import { getI18n, withI18n } from 'react-i18next';
import styled from 'styled-components';
import { Button, Typography } from 'components/Common';
import BackButton from 'components/Common/BackButton';
import {
  CheckboxWrapper,
  Spinner,
  StyledCheckbox
} from 'components/Common/StyledComponents';
import Card from './components/Card';
import Footer from './components/Footer';
import Page from './components/Page';

type Props = {
  t: any;
  country: any;
  city: any;
  countryList: any[];
  cityList: any[];
  errors: any;
  fields: any;
  isLoading: boolean;
  onChange: (field: string, option: any) => void;
  onChangeCountry: (option: any) => void;
  onChangeCity: (option: any) => void;
  onSubmit: (e: any, nextPath: string) => void;
};

const ActorFilmCenterStep: React.FC<Props> = ({
  t,
  country,
  city,
  countryList,
  cityList,
  errors,
  fields,
  isLoading,
  onChange: handleChange,
  onChangeCountry: handleChangeCountry,
  onChangeCity: handleChangeCity,
  onSubmit: handleSubmit
}) => {
  const isLangSpanish = getI18n().language === "es";

  return (
    <StyledPage>
      <BackButton width={480} />
      <StyledCard>
        <Typography align="center" component="h1" variant="h2">
          {t('Customize your account')}
        </Typography>
        <form
          onSubmit={(e) => {
            return handleSubmit(
              e,
              isLangSpanish ? '/actor/5' : '/actor/payment'
            );
          }}
        >
          <InputLabel>
            {t('What type of projects are you looking for?')}
          </InputLabel>
          <CheckboxContainer>
            <CheckboxWrapper
              onClick={() => handleChange('onCamera', !fields.onCamera)}
            >
              <StyledCheckbox
                className="small-cb"
                isChecked={fields.onCamera}
              />
              <StyledImg alt="camera" src="../images/camera.svg" />
              <StyledCbLabel>{t('On-Camera')}</StyledCbLabel>
            </CheckboxWrapper>
            <CheckboxWrapper
              onClick={() => handleChange('voice', !fields.voice)}
            >
              <StyledCheckbox className="small-cb" isChecked={fields.voice} />
              <StyledImg alt="voice" src="../images/voice.svg" />
              <StyledCbLabel>{t('Voice')}</StyledCbLabel>
            </CheckboxWrapper>
          </CheckboxContainer>
          <ErrorText color="error" variant="captionBold">
            {errors.OnCamera}
          </ErrorText>
          <Divider />
          <Typography color="darkGrey" component="h2" variant="h5">
            {t('Your Location')}
          </Typography>
          <Typography align="center" color="medGrey">
            {t('Select your closest film center from the list below.')}
          </Typography>
          <InputLabel>
            {t('Choose your country')}
          </InputLabel>
          <StyledCWBDropdown
            isValueSelected
            items={countryList}
            placeholder={t('Select Country')}
            value={country}
            onChange={handleChangeCountry}
          />
          <ErrorText color="error" variant="captionBold">
            {errors.Country}
          </ErrorText>
          <InputLabel>
            {t('Choose your nearest location')}
          </InputLabel>
          <StyledCWBDropdown
            isValueSelected
            items={cityList}
            placeholder={t('Select an Option')}
            value={city || null}
            onChange={handleChangeCity}
          />
          <ErrorText color="error" variant="captionBold">
            {errors.City}
          </ErrorText>
          <StyledButton
            startIcon={isLoading && (
              <Spinner className="spinner-width" src="../images/spinner.svg" />
            )}
          >
            {t('Continue')}
          </StyledButton>
        </form>
      </StyledCard>
      <StyledFooter />
    </StyledPage>
  );
};

const StyledPage = styled(Page)`
  @media all and (max-width: 520px) {
    padding: ${(p) => p.theme.spacing(10, 2, 0)};
  }
`;

const StyledCard = styled(Card)`
  width: 480px;
  padding: ${(p) => p.theme.spacing(4, 10)};

  @media all and (max-width: 520px) {
    width: 100%;
    padding: ${(p) => p.theme.spacing(4, 2)};
  }
`;

const InputLabel = styled(Typography).attrs({
  color: 'darkGrey'
})`
  margin: ${(p) => p.theme.spacing(2, 0, 1)};
  font-size: 14px;
  font-weight: 600;
  line-height: 21px;
`;

const CheckboxContainer = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const StyledImg = styled.img`
  margin: ${(p) => p.theme.spacing(0, 0, 0.25, 1)};
`;

const StyledCbLabel = styled.span`
  font-size: ${(p) => p.theme["xxs-font-size"]};
  letter-spacing: 0.2px;
  margin-left: 4px;
`;

const ErrorText = styled(Typography)`
  margin-top: ${(p) => p.theme.spacing(1)};
`;

const Divider = styled.div`
  width: 100%;
  height: 1px;
  margin: ${(p) => p.theme.spacing(3, 0)};
  background-color: #D3DDE9;
`;

const StyledCWBDropdown = styled(CWBDropdown)`
  && {
    max-width: 100%;

    & > div {
      min-height: 35px;
    }
  }
`;

const StyledButton = styled(Button)`
  width: 100%;
  margin-top: ${(p) => p.theme.spacing(3)};
`;

const StyledFooter = styled(Footer)`
  margin-top: ${(p) => p.theme.spacing(3)};
`;

export default withI18n()(ActorFilmCenterStep);
