import React from "react";
import qs from "query-string";
import { withI18n } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import { Footer, PageContainer } from "../Common";
import BackButton from "../Common/BackButton";
import CWBLabsActorPaymentDeclined from "components/CWBLabs/ActorPaymentDeclined";
import { IActorAccountDto } from "shared/api/dtos/IActorDto";
import { getCurUserSignUp } from "helpers";
import ModalSignUpPaymentDeclined from "./SignUp/ModalSignUpPaymentDeclined";
import ModalUpgradePaymentDeclined from "./ModalUpgradePaymentDeclined";

type Props = {
  t: any;
} & RouteComponentProps;

const ActorPaymentDeclined: React.FC<Props> = ({ t, history, location }) => {
  const userSignUp: IActorAccountDto = getCurUserSignUp();
  return qs.parse(location.search).cwblabs ? (
    <CWBLabsActorPaymentDeclined />
  ) : (
    <PageContainer>
      <BackButton width={800} />
      {userSignUp ? (
        <ModalSignUpPaymentDeclined />
      ) : (
        <ModalUpgradePaymentDeclined />
      )}
      <Footer />
    </PageContainer>
  );
};

export default withI18n()(withRouter(ActorPaymentDeclined));
