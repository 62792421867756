import { IGroupedCountry } from "./ICountryList";
import i18n from "../i18n";

const shortid = require("shortid");
export const generateId = () => `item-${shortid.generate()}`;

export const localeKeys: any = { en: 0, fr: 1, en_uk: 2, es: 3, nb: 99 };

export const getUserLanguageChoice = (
  localeKeys: any,
  languageChoice: number
): string => {
  return Object.keys(localeKeys).find((key: string) => {
    return localeKeys[key] === languageChoice;
  });
};

export const detectBrowserLang =
  (navigator.languages && navigator.languages[0]) || navigator.language;

export const getBrowserLang = () => {
  const browserLang = detectBrowserLang.toLowerCase();
  if (browserLang === "en-gb") {
    return "en_uk";
  } else if (localeKeys[browserLang.split("-")[0]]) {
    return browserLang.split("-")[0];
  } else {
    return "en";
  }
};

export const setBrowserLang = (lang?: string) => {
  if (lang) {
    i18n.changeLanguage(lang);
    return;
  }

  const browserLang = detectBrowserLang.toLowerCase();
  if (browserLang === "en-gb") {
    i18n.changeLanguage("en_uk");
  } else if (localeKeys[browserLang.split("-")[0]]) {
    i18n.changeLanguage(browserLang.split("-")[0]);
  } else {
    i18n.changeLanguage("en");
  }
};

export const setCurUserSignUp = (user: any) => {
  if (user && user.sessionId) {
    // This saved CWB_USER info is used by all API calls and in the user.api.js to cache responses
    localStorage.setItem(
      "CWB_SIGNUP_USER",
      JSON.stringify({ cachedAt: new Date().valueOf(), ...user })
    );
    localStorage.setItem("Session_Id", user.sessionId);
  } else {
    localStorage.removeItem("CWB_SIGNUP_USER");
    localStorage.removeItem("Session_Id");
  }
};

export const setUserActorSignUpId = (id: string) => {
  if (id == null) {
    localStorage.removeItem("Actor_SignUp_Id");
    return;
  }
  localStorage.setItem("Actor_SignUp_Id", id);
};

export const setCountryCode = (code: string) => {
  localStorage.setItem("CountryCode", code);
};

export const getCountryCode = () => {
  return localStorage.getItem("CountryCode");
};

export const getUserActorSignUpId = () => {
  let userSignUpId = JSON.parse(localStorage.getItem("Actor_SignUp_Id"));
  if (!userSignUpId) return 0;
  return userSignUpId;
};

export const getCurUserSignUp = () => {
  let curUser = JSON.parse(localStorage.getItem("CWB_SIGNUP_USER"));
  if (!curUser || !curUser.sessionId) {
    return 0;
  }

  return curUser;
};

export const getSessionId = () => {
  let sessionId = localStorage.getItem("Session_Id");
  if (!sessionId) {
    return "";
  }

  return sessionId;
};

export const getWebSessionKey = () => {
  let webSessionKey = localStorage.getItem("CWB_WebSessionKey");
  if (!webSessionKey) {
    return "";
  }

  return webSessionKey;
};

export const setWebSessionKey = (k: string) => {
  if (k) localStorage.setItem("CWB_WebSessionKey", k);
};

// TODO: move this to a more narrowed helper like apiHelper...
export const postData = (url: any, data: any) => {
  // Default options are marked with *
  return fetch(url, {
    method: "POST", // *GET, POST, PUT, DELETE, etc.
    mode: "cors", // no-cors, cors, *same-origin
    credentials: "include", // include, *same-origin, omit
    cache: "no-cache", // *default, no-cache, reload, force-cache, only-if-cached
    headers: {
      "Content-Type": "application/json",
      CWB: "Test",
    },
    // redirect: "follow", // manual, *follow, error
    // referrer: "no-referrer", // no-referrer, *client
    body: JSON.stringify(data), // body data type must match "Content-Type" header
  }); // parses JSON response into native JavaScript objects
};

// TODO: review and return Dict and create inteface for Select option items
export const groupCountryList = (countryWithCities: any): IGroupedCountry[] => {
  const counties = countryWithCities.reduce(function (r: any, country: any) {
      const category = country.countryId;
      !r[category]
        ? (r[category] = {
            label: country.countryName,
            value: country.countryCode,
            id: category,
            country,
            cityList: [country],
          })
        : r[category]["cityList"].push(country);
      return r;
    }, {}),
    result = Object.keys(counties).map(function (k) {
      return counties[k];
    });
  return result;
};

export const VerifyPhoneCodeLength = 4;

export const NameMaxLength = 55;

export const PasswordLength = 6;

export const buildSearchStyle = () => ({
  control: (base: any, state: any) => ({
    minHeight: "40px !important",
    width: "68px",
    border: "1px solid #D3DDE9 !important",
    borderRadius: "4px 0 0 4px",
    display: "flex",
  }),
  singleValue: (base: any) => ({
    ...base,
    marginLeft: "0",
    marginRight: "0",
    maxWidth: "100%",
    color: "#122640",
    fontSize: "12px",
    fontWeight: "600",
    width: "80%",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    top: "55%",
  }),
  dropdownIndicator: () => ({
    paddingRight: "8px",
  }),
  indicatorSeparator: () => ({
    display: "none",
  }),
  container: (base: any) => ({
    ...base,
    width: "68px",
  }),
  placeholder: (base: any) => ({
    ...base,
    whiteSpace: "pre",
    textOverflow: "ellipsis",
  }),
  group: (base: any) => ({
    ...base,
    paddingTop: 2,
    overflowX: "hidden",
  }),
  groupHeading: (base: any) => ({
    ...base,
    textTransform: "titlecase",
    overflowX: "hidden",
  }),
  menu: (base: any) => ({
    ...base,
    borderRadius: "4px",
    border: "1px solid #D3DDE9",
    boxShadow: "0 2px 5px 0 rgba(0,0,0,0.05)",
    overflowX: "hidden",
    width: "180px",
    marginTop: "2px",
  }),
  menuList: (base: any) => ({
    ...base,
    overflowX: "hidden",
    maxHeight: "391px",
    paddingBottom: "6px",
    paddingTop: "6px",
  }),
  option: (base: any, state: any) => {
    return {
      ...base,
      backgroundColor: "#FFFFFF",
      padding: "6px 16px",
      color: "#495A6E",
      fontSize: 12,
      fontWeight: "normal",
      width: "100%",
    };
  },
});

export const getE164number = (
  phoneNumber: string,
  internationalCallingCode: string
): string => {
  let e164 = `+${internationalCallingCode}${phoneNumber}`;

  if (!e164.match(/^\+{1}[1-9]\d{1,15}$/i)) return "";

  return e164;
};
