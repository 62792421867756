import { setCurUserSignUp, getSessionId, setUserActorSignUpId, getCurUserSignUp, getUserActorSignUpId, setWebSessionKey } from "../helpers";
import { createModel } from "@rematch/core";
import {
  IActorCredentials,
  IActorStageName,
  IActorFilmCenter,
  IActorPhone,
  IActorError,
  IActorAccountDto,
  IActorSignUpDto,
  IAccountCredentials, IAccountInfo, IAccountRequestData, IActorTrialDto
} from "../shared/api/dtos/IActorDto";
import * as ActorRegistrationApi from "../shared/api/ActorRegistration.api";
import * as ProfileManagementApi from "../shared/api/ProfileManagement.api";
import {
  IError,
  IApiResponseError
} from "../shared/api/dtos/IApiRepsonseError";
// @ts-ignore
import { getSessionDataFromAuthToken } from "cwb-react";

const actorModel = createModel({
  state: {
    sessionId: "",
    isLoading: false,
    actorCredentials: {} as IActorCredentials,
    actorStageName: {} as IActorStageName,
    actorFilmCenter: {} as IActorFilmCenter,
    actorPhone: {} as IActorPhone,
    errors: {} as IActorError,
    fields: {} as any,
    accountCredentials: {} as IAccountCredentials,
    accountRequestData: {} as IAccountRequestData,
    accountConfirmed: false,
    invalidAccount: false,
    clientId: ""
  },
  reducers: {
    setIsLoading: (state: any, isLoading: boolean) => ({
      ...state,
      isLoading
    }),
    setErrors: (state: any, errors: IActorError) => ({
      ...state,
      errors
    }),
    setActorCredentials: (state: any, actorCredentials: any) => ({
      ...state,
      actorCredentials
    }),
    setAccountCredentials: (state: any, accountCredentials: IAccountCredentials) => ({
      ...state,
      accountCredentials
    }),
    appendAccountRequestData: (state:any, payload: any) => ({
      ...state,
      accountRequestData: {
        ...payload
      }
    }),
    setAccountConfirmed: (state, accountConfirmed: boolean) =>({
      ...state,
      accountConfirmed
    }),
    setInvalidAccount: (state, invalidAccount: boolean) =>({
      ...state,
      invalidAccount
    }),
    setClientId: (state, clientId: string) => ({
      ...state,
      clientId
    })
  },
  effects: (dispatch: any) => ({
    async createActorAccount(dto: IActorCredentials, state: any) {
      dispatch.actorModel.setIsLoading(true);
      dispatch.actorModel.setActorCredentials(dto);
      await ActorRegistrationApi.createActorAccount(dto)
        .then((resp: any) => {
          dispatch.actorModel.setIsLoading(false);
          let actorDto: IActorAccountDto = resp!.data;
          if (actorDto) {
            dispatch.actorModel.setErrors({} as IActorError);
            setCurUserSignUp(null); // clear user in case pick diff account type and went through one step
            setCurUserSignUp(actorDto);
          }
        })
        .catch(resp => {
          setCurUserSignUp(null);
          let apiResponseError: IApiResponseError = resp;
          if (apiResponseError.errors) {
            dispatch.actorModel.setErrors(
              getActorErrors(apiResponseError.errors)
            );
          } else if (apiResponseError.message) {
            dispatch.actorModel.setErrors({
              errorMessage: apiResponseError.message
            });
          }
          console.log(resp);
        });
    },
    async createActorPhone(dto: IActorPhone, state: any) {
      dispatch.actorModel.setIsLoading(true);
      await ActorRegistrationApi.createActorPhone(getSessionId(), dto)
        .then((resp: any) => {
          dispatch.actorModel.setIsLoading(false);
          let actorDto: IActorAccountDto = resp!.data;
          if (actorDto) {
            dispatch.actorModel.setErrors({} as IActorError);
            setCurUserSignUp(actorDto);
          }
        })
        .catch(resp => {
          let apiResponseError: IApiResponseError = resp;
          if (apiResponseError.errors) {
            dispatch.actorModel.setErrors(
              getActorErrors(apiResponseError.errors)
            );
          } else if (apiResponseError.message) {
            dispatch.actorModel.setErrors({
              errorMessage: apiResponseError.message
            });
          }
          dispatch.actorModel.setIsLoading(false);
          console.log(resp);
        });
    },
    async verifyActorPhone(dto: IActorPhone, state: any) {
      dispatch.actorModel.setIsLoading(true);
      await ActorRegistrationApi.verifyActorPhone(getSessionId(), dto, dto.code)
        .then((resp: any) => {
          dispatch.actorModel.setIsLoading(false);
          let actorDto: IActorAccountDto = resp!.data;
          if (actorDto) {
            dispatch.actorModel.setErrors({} as IActorError);
            setCurUserSignUp(actorDto);
          }
        })
        .catch(resp => {
          let apiResponseError: IApiResponseError = resp;
          if (apiResponseError.errors) {
            dispatch.actorModel.setErrors(
              getActorErrors(apiResponseError.errors)
            );
          } else if (apiResponseError.message) {
            dispatch.actorModel.setErrors({
              errorMessage: apiResponseError.message
            });
          }
          console.log(resp);
        });
    },
    async createStageName(dto: IActorStageName, state: any) {
      dispatch.actorModel.setIsLoading(true);
      await ActorRegistrationApi.createStageName(getSessionId(), dto)
        .then((resp: any) => {
          dispatch.actorModel.setIsLoading(false);
          let actorDto: IActorAccountDto = resp!.data;
          if (actorDto) {
            dispatch.actorModel.setErrors({} as IActorError);
            setCurUserSignUp(actorDto);
          }
        })
        .catch(resp => {
          setCurUserSignUp(null);
          let apiResponseError: IApiResponseError = resp;
          if (apiResponseError.errors) {
            dispatch.actorModel.setErrors(
              getActorErrors(apiResponseError.errors)
            );
          } else if (apiResponseError.message) {
            dispatch.actorModel.setErrors({
              errorMessage: apiResponseError.message
            });
          }
          console.log(resp);
        });
    },
    async createTrialSignUp (dto:IActorFilmCenter,state:any) {
      dispatch.actorModel.setIsLoading(true);
      const user: IActorAccountDto = getCurUserSignUp();
      user.actorFilmCenter = dto;
      try {
        const resp = await ActorRegistrationApi.createTrialSignUp(getSessionId(), dto);
        setCurUserSignUp(user);
        let actorSignUp: IActorSignUpDto = resp!.data;
        if (actorSignUp) {
          dispatch.actorModel.setErrors({} as IActorError);
          const signUpId = (actorSignUp.actorSignUpId
            || actorSignUp.clientId)!.toString()
          setUserActorSignUpId(signUpId);
          const resp:any = await ActorRegistrationApi.createTrialUser(getUserActorSignUpId())
          const clientId = resp!.data.clientId;
          const jwtToken = resp!.data.jwt;
          let data =  await getSessionDataFromAuthToken(jwtToken);
          let webSessionKey = data.WebSessionKey;
          setWebSessionKey(webSessionKey)
          dispatch.actorModel.setClientId(clientId);
          dispatch.actorModel.setIsLoading(false);
        }
      }catch(resp){
        setUserActorSignUpId(null);
          setCurUserSignUp(null);
          let apiResponseError: IApiResponseError = resp;
          if (apiResponseError.errors) {
            dispatch.actorModel.setErrors(
              getActorErrors(apiResponseError.errors)
            );
          } else if (apiResponseError.message) {
            dispatch.actorModel.setErrors({
              errorMessage: apiResponseError.message
            });
          }
          console.log(resp);
      }
    },
    async saveActorSignUp(dto: IActorAccountDto, state: any) {
      dispatch.actorModel.setIsLoading(true);
      await ActorRegistrationApi.createActorSignUp(getSessionId(), dto)
        .then((resp: any) => {
          dispatch.actorModel.setIsLoading(false);
          setCurUserSignUp(dto);
          let actorSignUp: IActorSignUpDto = resp!.data;
          if (actorSignUp) {
            dispatch.actorModel.setErrors({} as IActorError);
            setUserActorSignUpId((actorSignUp.actorSignUpId
              || actorSignUp.clientId)!.toString());
            dispatch.headerModel.setUserLoggedIn(true); // TODO:
          }
        })
        .catch(resp => {
          setUserActorSignUpId(null);
          setCurUserSignUp(null);
          dispatch.headerModel.setUserLoggedIn(false);
          let apiResponseError: IApiResponseError = resp;
          if (apiResponseError.errors) {
            dispatch.actorModel.setErrors(
              getActorErrors(apiResponseError.errors)
            );
          } else if (apiResponseError.message) {
            dispatch.actorModel.setErrors({
              errorMessage: apiResponseError.message
            });
          }
          console.log(resp);
        });
    },
    async getAccountInfo(guid, state: any) {
      dispatch.actorModel.setIsLoading(true);
      await ActorRegistrationApi.getAccountInfo(guid)
          .then((resp: any) => {
            dispatch.actorModel.setIsLoading(false);
            let accountInfo: IAccountInfo = resp!.data;
            if (accountInfo) {
              dispatch.actorModel.setErrors({} as IActorError);
              dispatch.actorModel.setAccountCredentials({
                ...accountInfo,
                workbookId: accountInfo.userId,
              })
            }
          })
          .catch(resp => {
            dispatch.actorModel.setInvalidAccount(true);
            let apiResponseError: IApiResponseError = resp;
            if (resp.response && resp.response.data) {
              dispatch.actorModel.setErrors({
                errorMessage: resp.response.data.message
              });
            } else if (apiResponseError.errors) {
              dispatch.actorModel.setErrors(
                  getActorErrors(apiResponseError.errors)
              );
            } else if (apiResponseError.message) {
              dispatch.actorModel.setErrors({
                errorMessage: apiResponseError.message
              });
            }
          });
    },
    async confirmAccount({dto, guid}: any, state: any) {
      await ActorRegistrationApi.confirmAccount(dto.password, guid)
          .then((resp: any) => {
            if (resp.status === 200) {
              ActorRegistrationApi.loginAccount(dto).then((resp: any) => {
                if (resp.status === 200 && resp.data.accessToken) {
                  const webSessionKey = JSON.parse(atob(resp.data.accessToken.split('.')[1]));
                  dispatch.actorModel.setAccountConfirmed(true);
                  const qs = `tk=${webSessionKey.WebSessionKey}&cid=${dto.workbookId}&sch=${dto.sch}&saud=${dto.saud}`;
                  window.localStorage.setItem('qs', qs);
                }
              });
            }
          })
          .catch(resp => {
            let apiResponseError: IApiResponseError = resp;
            if (resp.response && resp.response.data) {
              dispatch.actorModel.setErrors({
                errorMessage: resp.response.data.message
              });
            } else if (apiResponseError.errors) {
              dispatch.actorModel.setErrors(
                  getActorErrors(apiResponseError.errors)
              );
            } else if (apiResponseError.message) {
              dispatch.actorModel.setErrors({
                errorMessage: apiResponseError.message
              });
            }
          });
    },
    async createFreemiumUser(actorSignUpId: number) {
      return await ActorRegistrationApi.createFreemiumUser(actorSignUpId)
        .then((resp: any) => resp)
        .catch(resp => {
          setCurUserSignUp(null);
          let apiResponseError: IApiResponseError = resp;
          if (apiResponseError.errors) {
            dispatch.actorModel.setErrors(
              getActorErrors(apiResponseError.errors)
            );
          } else if (apiResponseError.message) {
            dispatch.actorModel.setErrors({
              errorMessage: apiResponseError.message
            });
          }
          console.log(resp);
          throw resp;
        });
    },
    async createTrialUser(actorSignUpId: number) {
      return await ActorRegistrationApi.createTrialUser(actorSignUpId)
        .then((resp: any) => resp)
        .catch(resp => {
          setCurUserSignUp(null);
          let apiResponseError: IApiResponseError = resp;
          if (apiResponseError.errors) {
            dispatch.actorModel.setErrors(
              getActorErrors(apiResponseError.errors)
            );
          } else if (apiResponseError.message) {
            dispatch.actorModel.setErrors({
              errorMessage: apiResponseError.message
            });
          }
          throw resp;
        });
    },
    async updateEmail({ clientId, email }) {
      await ProfileManagementApi.updateEmail(clientId, email)
        .then(() => {})
        .catch(e => {
          window.location.replace(process.env.REACT_APP_CWB_500);
        });
    }
  })
});

const getActorErrors = (apiErrors: IError[]): IActorError => {
  let errors = {} as IActorError;
  apiErrors.forEach((k: IError) => (errors[k.field] = k.message));
  return errors;
};

export default actorModel;
