import * as http from "../http";
import { IAgentUser } from "./dtos/IAgentDtos";
import { TrackingData } from "./dtos/ITrackingDataDto";

export const createAgentAccount = (dto: IAgentUser) => {
  const trackingData = JSON.parse(
    localStorage.getItem("tData")
  ) as TrackingData;
  dto.trackingData = trackingData;
  return http.post<IAgentUser>(
    `${process.env.REACT_APP_REG_Api}/agent/create${window.location.search}`,
    dto
  );
};
