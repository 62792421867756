import React from "react";
import qs from "query-string";
import { withI18n } from "react-i18next";
import styled from "styled-components";
import { connect } from "react-redux";

import { getCurUserSignUp, getSessionId, getCountryCode } from "../../../helpers/index";
// @ts-ignore
import { CWBDropdown } from "cwb-react";
import { withRouter } from "react-router-dom";

import { iRootState } from "../../../store";
import BackButton from "../../Common/BackButton";
import Footer from "../../Common/Footer";
import Modal from "../../Common/Modal";
import PageContainer from "../../Common/PageContainer";
import {
  ErrorText,
  Section,
  SectionTitle,
  LargeText,
  Spinner,
} from "../../Common/StyledComponents";
import Typography from "../../Common/Typography";
import {
  IActorFilmCenter,
  IActorError,
  IActorAccountDto,
} from "../../../shared/api/dtos/IActorDto";
import { IGroupedCountry } from "../../../helpers/ICountryList";
import Button from "../../Common/Button";

interface IProps extends StateProps, DispatchProps {
  t?: any;
  history?: any;
  location?: any;
}

interface IState {
  fields: any;
  isFormSubmitClicked: boolean;
  cityList: any[];
}

//@ ts-check
// TODO: Move style and JSDOM out of container compnent  into Presentational Components
class ActorFilmCenterStep extends React.Component<IProps, IState> {
  public state: IState = {
    fields: {
      onCamera: true,
      voice: true,
      country: "",
      province: "",
      provinceId: 1,
      city: "",
      cityId: 1,
      countryId: 1,
    },
    isFormSubmitClicked: false,
    cityList: [],
  };

  async componentDidMount() {
    const user: IActorAccountDto = getCurUserSignUp();
    if (getSessionId() && user) {
      if (this.props.groupCountryList.length <= 0)
        await this.props.getGroupCountryList();

      if (user.actorFilmCenter) this.mapUserToStateFields(user.actorFilmCenter);
      else {
        const grpCountries = this.props.groupCountryList;
        let country = grpCountries[0];

        // To test below code. Install Opera. In settings, turn on VPN.
        if (getCountryCode() && getCountryCode() !== "CA")
          country = grpCountries.find((x) => x.country.countryCode === "US");

        this.setCountry(country);
      }
    } else {
      window.location.replace("/");
    }
  }

  mapUserToStateFields = (userfilmCenter: IActorFilmCenter) => {
    if (!userfilmCenter) return;
    let fields: IActorFilmCenter = this.state.fields;

    fields.province = userfilmCenter.province;
    fields.provinceId = userfilmCenter.provinceId;
    fields.city = userfilmCenter.city;
    fields.cityId = userfilmCenter.cityId;
    fields.countryId = userfilmCenter.countryId;
    fields.onCamera = userfilmCenter ? userfilmCenter.onCamera : false;
    fields.voice = userfilmCenter ? userfilmCenter.voice : false;

    this.setState({ fields });

    const grpCountries = this.props.groupCountryList;
    const country = grpCountries.find((x) => x.id === fields.countryId);
    this.setCountry(country);
  };

  checkValidation(): boolean {
    const { t } = this.props;
    let fields: IActorFilmCenter = this.state.fields;
    let errors: IActorError = {} as IActorError;

    //projectTypeToCast
    if (!fields.onCamera && !fields.voice)
      errors.OnCamera = t("Select at least one type");

    //country
    if (!fields.country) errors.Country = t("Country field is required");

    //city
    if (!fields.city) errors.City = t("City field is required");

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  setCountry = (option: any) => {
    let fields: IActorFilmCenter = this.state.fields;
    const cityList = option.cityList
      ? option.cityList.map((city: any) => ({
          label: city.cityName,
          value: city.cityName,
          id: city.cityId,
          city,
        }))
      : [];
    fields.country = option.value;
    fields.countryId = option.id;
    this.setState({ fields });
    this.setState({ cityList });
  };

  handleCity = (option: any) => {
    let fields: IActorFilmCenter = this.state.fields;
    fields.city = option.value;
    fields.cityId = option.id;
    fields.provinceId = option.city!.provinceId;
    fields.province = option.city!.provinceName;
    this.setState({ fields });
    this.state.isFormSubmitClicked && this.checkValidation();
  };

  handleCountry = (option: any) => {
    let fields: IActorFilmCenter = this.state.fields;
    fields.country = option.value;
    fields.countryId = option.id;
    fields.provinceId = option.country!.provinceId;
    fields.province = option.country!.provinceName;
    this.setState({ fields });
    this.setCountry(option);
    this.state.isFormSubmitClicked && this.checkValidation();
  };

  saveAccountSignUp = async (e: any) => {
    e.preventDefault();
    this.setState({ isFormSubmitClicked: true });
    if (!this.checkValidation()) return;
    const user: IActorAccountDto = getCurUserSignUp();
    user.actorFilmCenter = this.state.fields;
    await this.props.createTrialSignUp({...this.state.fields});
    this.moveToNext();
  };

  moveToNext = () => {
    if (this.props.errors.errorMessage)
      window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    // TODO: push to history when on INT
    else if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.history.push({
        pathname: '/actor/free-trial/completed',
        search: this.props.location.search
      });
    }
  };

  getCountry = (): IGroupedCountry => {
    const fields: IActorFilmCenter = this.state.fields;
    return this.props.groupCountryList.find((x) => x.id === fields.countryId);
  };

  render() {
    const { t, location } = this.props;
    const errors: IActorError = this.props.errors;
    const fields: IActorFilmCenter = this.state.fields;
    const city = this.state.cityList.find((x) => x.id === fields.cityId);
    const country = this.getCountry();
    // // Stripe limitation. Stripe Key defined on App start. Cannot be changed based on Selection.
    // // TODO: Have Stripe Provider wrap only ActorPaymentStep and based on country code props set appropriate Stripe Key
    // // Temp solution. Have User form X country only X country's cities
    // const countryList =
    //   this.props.groupCountryList &&
    //   this.props.groupCountryList.filter(
    //     (x) => x.country.countryCode === getCountryCode()
    //   );

    return <PageContainer>
        <BackButton width={480} />
        <Wrapper>
        <StyledTypography align="center" component="h1" variant="h2">
            {t("Last Step")}
          </StyledTypography>
          <Section className="margin-bottom-none">
            <LargeText>{t("Film center")}</LargeText>
            <FilmCenterContent>
              {t("Select your closest film center from the list below.")}
            </FilmCenterContent>
          </Section>
          <Section className="margin-bottom-none">
            <SectionTitle>{t("Choose your country")}</SectionTitle>
            <CWBDropdown
              value={country}
              isValueSelected={true}
              placeholder={t("Select Country")}
              items={this.props.groupCountryList}
              onChange={(option: any) => this.handleCountry(option)}
            />
            <ErrorText>{errors.Country}</ErrorText>
          </Section>
          <Section className="margin-bottom-none">
            <SectionTitle>{t("Choose your nearest location")}</SectionTitle>
            <CWBDropdown
              name="city"
              value={city || null}
              isValueSelected={true}
              placeholder={t("Select an Option")}
              items={this.state.cityList ? this.state.cityList : []}
              onChange={(option: any) => this.handleCity(option)}
            />
            <ErrorText>{errors.City}</ErrorText>
          </Section>
          <ErrorText>{errors.errorMessage}</ErrorText>
          <Section>
            <StyledButton
              onClick={(e) => this.saveAccountSignUp(e)}
              
            >
              {t("Create my account now")}
              {this.props.isLoading && (
                    <Spinner
                      src="/images/spinner.svg"
                      className="spinner-width"
                    />
                  )}
            </StyledButton>
          </Section>
        </Wrapper>
        <Footer />
      </PageContainer>;
  }
}

interface StateProps {
  errors: IActorError;
  groupCountryList: IGroupedCountry[];
  isLoading: boolean;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    errors: state.actorModel.errors,
    groupCountryList: state.referencesModel.supportedGroupCountryList,
    isLoading: state.actorModel.isLoading,
  };
}

interface DispatchProps {
  saveCastingFilmCenter: (fields: any) => void;
  getGroupCountryList: () => void;
  setErrors: (errors: IActorError) => void;
  createTrialSignUp: (dto: IActorFilmCenter) => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    saveCastingFilmCenter: dispatch.actorModel.saveCastingFilmCenter,
    getGroupCountryList: dispatch.referencesModel.getSupportedGroupCountryList,
    setErrors: dispatch.actorModel.setErrors,
    createTrialSignUp: dispatch.actorModel.createTrialSignUp,
  };
}

export default withI18n()(
  withRouter(connect(mapStateToProps, mapDispatchToProps)(ActorFilmCenterStep))
);

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  font-weight: ${(p) => p.theme["font-weight-600"]};

  max-width: 480px;
  min-width: 480px;
  background-color: ${(p) => p.theme.white};
  padding: 40px 80px;

  @media all and (max-width: 520px) {
    width: 90%;
    min-width: 40%;
  }

  .cwb-dropdown-wrapper {
    margin-top: 8px;
    max-width: 100%;
    height: 40px;

    .cwb-dropdown__single-value {
      font-size: ${(p) => p.theme["xs-font-size"]};
    }

    .cwb-dropdown__indicator {
      width: 14px;
    }
  }

  .cwb-dropdown__placeholder {
    color: ${(p) => p.theme.darkGrey};
    font-size: ${(p) => p.theme["xs-font-size"]};
    font-weight: ${(p) => p.theme["font-weight-600"]};
  }

  .cwb-dropdown-wrapper > div {
    min-width: 100%;
    max-width: 100%;
  }

  .cwb-dropdown-wrapper > div .cwb-dropdown__control {
    min-height: 40px;
    border: ${(props) => props.theme.dropDown["border"]} !important;
    border-radius: ${(props) => props.theme.dropDown["border-radius"]};
  }
`;

const StyledTypography = styled(Typography)`
  margin-bottom: ${(p) => p.theme.spacing(3)};
`;

const FilmCenterContent = styled.div`
  color: ${(p) => p.theme.color} !important;
  font-size: ${(p) => p.theme["s-font-size"]};
  font-weight: normal;
`;

const StyledButton = styled(Button)`
  width: 100%;
  height: 37px;
  font-size: 13px;
  font-weight: 600;
  background-color: ${(p) => p.theme.palette.brand.lightBlue};

  &:hover {
    background-color: ${(p) => p.theme.palette.brand.blue};
  }

  &:active {
    background-color: ${(p) => p.theme.palette.brand.darkBlue};
  }
`;
