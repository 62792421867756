import React from "react";
import { withI18n } from "react-i18next";
import { RouteComponentProps, withRouter } from "react-router-dom";
import styled from "styled-components";
import { Button, Link, Modal, Typography } from "../Common";
import ErrorIcon from "../../images/error.svg";

type Props = {
  t: any;
} & RouteComponentProps;

const ModalUpgradePaymentDeclined: React.FC<Props> = ({
  t,
  history,
  location,
}) => {
  return (
    <>
      <StyledModal>
        <Typography align="center" component="h1" variant="h2">
          {t("Your payment was declined")}
        </Typography>
        <StyledImg src={ErrorIcon} />
        <Typography align="center" color="darkGrey" variant="h4">
          {t(
            "Please go back and update your payment method then try again. If the problem persists, please contact our"
          )}
          &nbsp;
          <StyledLink
            href="https://home.castingworkbook.com/contacts"
            target="_blank"
            rel="noopener noreferrer"
          >
            {t("support team")}
          </StyledLink>
          .
        </Typography>
        <StyledButton
          onClick={() =>
            history.push({
              pathname: '/actor/select-membership',
              search: location.search,
            })
          }
        >
          {t("Try again")}
        </StyledButton>
      </StyledModal>
    </>
  );
};

const StyledModal = styled(Modal)`
  width: 800px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: ${(p) => p.theme.spacing(10, 19, 12)};
`;

const StyledImg = styled.img`
  margin: ${(p) => p.theme.spacing(2, 0, 4)};
`;

const StyledLink = styled(Link)`
  font-size: 16px;

  &&,
  &&:hover {
    text-decoration: underline;
  }
`;

const StyledButton = styled(Button)`
  min-width: 200px;
  margin-top: ${(p) => p.theme.spacing(5)};
`;

export default withI18n()(withRouter(ModalUpgradePaymentDeclined));
