import React from "react";
import qs from "query-string";
import { withI18n } from "react-i18next";
import styled, { css } from "styled-components";
import { connect } from "react-redux";

import { VerifyPhoneCodeLength, getCurUserSignUp } from "../../../helpers";
import Footer from "../../Common/Footer";
import Modal from "../../Common/Modal";
import PageContainer from "../../Common/PageContainer";
import {
  Section,
  StyledBlueBtn,
  SectionTitle,
  SmallTextError,
  Spinner
} from "../../Common/StyledComponents";
import CWBLabsActorVerifyPhoneStep from "components/CWBLabs/ActorVerifyPhoneStep";
import { iRootState } from "../../../store";
import {
  IActorError,
  IActorPhone,
  IActorAccountDto
} from "../../../shared/api/dtos/IActorDto";
import { ICountryDto } from "../../../shared/api/dtos/IReferencesDto";

interface IProps extends StateProps, DispatchProps {
  t?: any;
  history?: any;
  location?: any;
}

interface IState {
  canResend: boolean;
  isFormSubmitClicked: boolean;
  fields: IActorPhone;
}

class ActorVerifyPhoneStep extends React.Component<IProps, IState> {
  public state: IState = {
    canResend: true,
    isFormSubmitClicked: false,
    fields: { phone: "", code: "" }
  };

  componentDidMount() {
    const user: IActorAccountDto = getCurUserSignUp();
    if (user && !this.state.fields.phone)
      this.setState({
        fields: {
          ...this.state.fields,
          ...user.actorPhone
        }
      });
  }

  checkValidation() {
    const { t } = this.props;
    let fields = this.state.fields;
    let errors = {} as IActorError;

    if (
      !fields.code ||
      !fields.code.match(/^\d{4}/i) ||
      fields.code.length !== VerifyPhoneCodeLength
    ) {
      errors.Code = t(
        "Invalid code, only numbers and a code length of {{length}} is expected.",
        { length: VerifyPhoneCodeLength }
      );
      fields.code = "";
      this.setState({ fields });
    }

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  checkNumber() {
    const { t } = this.props;
    let fields = this.state.fields;
    let errors = {} as IActorError;

    if (!fields.code || fields.code.match(/^[0-9`]+$/i)) {
      this.props.setErrors(errors);
      return true;
    }

    errors.Code = t("Only numbers are accepted");
    fields.code = "";
    this.setState({ fields });
    this.props.setErrors(errors);
    return false;
  }

  handleChange = (e: any) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
    this.checkNumber();
  };

  verifyPhone = async (e: any) => {
    e.preventDefault();
    this.setState({ isFormSubmitClicked: true });
    if (!this.checkValidation()) return;
    await this.props.verifyActorPhone(this.state.fields);
    this.moveToNext();
  };

  resendCode = async (e: any) => {
    e.preventDefault();
    if (!this.state.canResend) return;
    const user: IActorAccountDto = getCurUserSignUp();
    if (!user) window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    this.setState({ canResend: false });
    await this.props.createActorPhone(user.actorPhone);
    setTimeout(() => this.setState({ canResend: true }), 10 * 1000);
  };

  changePhone = () => {
    this.props.history.goBack();
  };

  moveToNext = () => {
    if (this.props.errors.errorMessage)
      window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    else if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.history.push({
        pathname: "/actor/3",
        search: this.props.location.search
      });
    }
  };

  render() {
    const { t, location } = this.props;
    const { canResend, fields } = this.state;

    return qs.parse(location.search).cwblabs ? (
      <CWBLabsActorVerifyPhoneStep
        canResend={canResend}
        errors={this.props.errors}
        fields={fields}
        isLoading={this.props.isLoading}
        onChange={this.handleChange}
        onSubmit={this.verifyPhone}
        onResendCode={this.resendCode}
        onChangePhone={this.changePhone}
      />
    ) : (
      <PageContainer>
        <Wrapper>
          <Title>{t("You’re almost done!  First verify your phone number.")}</Title>
          <SubTitle>
            {t("Enter the 4 digit code we sent to")} {fields && fields.phone}
          </SubTitle>
          <form
            name="verifyForm"
            className="verifyForm"
            onSubmit={this.verifyPhone}
            style={{ width: "100%" }}
          >
            <fieldset>
              <Section>
                <SectionTitle>{t("Verification Code")}</SectionTitle>
                <StyledInput
                  name="code"
                  type="text"
                  className={this.props.errors.Code ? "invalid" : ""}
                  maxLength={VerifyPhoneCodeLength}
                  onChange={this.handleChange}
                  value={fields.code}
                />
                <SmallTextError className="error">
                  <span>{this.props.errors.Code}</span>
                </SmallTextError>
              </Section>
              <Section>
                <StyledBlueBtn type="submit" id="submit">
                  {t("Continue")}
                  {this.props.isLoading && (
                    <Spinner
                      src="../images/spinner.svg"
                      className="spinner-width"
                    />
                  )}
                </StyledBlueBtn>
              </Section>
            </fieldset>
          </form>
          <StyledAnchor disabled={!canResend} onClick={this.resendCode}>
            {canResend ? t("Resend code") : t("Code resent!")}
          </StyledAnchor>
          <StyledAnchor onClick={this.changePhone}>{t("Change your phone number")}</StyledAnchor>
        </Wrapper>
        <Footer />
      </PageContainer>
    );
  }
}

interface StateProps {
  errors: IActorError;
  fields: IActorPhone;
  countryList: ICountryDto[];
  isLoading: boolean;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    errors: state.actorModel.errors,
    fields: state.actorModel.fields,
    countryList: state.referencesModel.countryList,
    isLoading: state.actorModel.isLoading
  };
}

interface DispatchProps {
  setErrors: (errors: IActorError) => void;
  createActorPhone: (fields: IActorPhone) => void;
  getCountryList: () => void;
  verifyActorPhone: (fields: IActorPhone) => void;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    setErrors: dispatch.actorModel.setErrors,
    createActorPhone: dispatch.actorModel.createActorPhone,
    getCountryList: dispatch.referencesModel.getCountryList,
    verifyActorPhone: dispatch.actorModel.verifyActorPhone
  };
}

export default withI18n()(
  connect(
    mapStateToProps,
    mapDispatchToProps
  )(ActorVerifyPhoneStep)
);

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: start;
  align-items: center;
  font-weight: ${p => p.theme["font-weight-600"]};

  max-width: 480px;
  min-width: 480px;
  background-color: ${p => p.theme.white};
  padding: 40px 80px;

  @media all and (max-width: 520px) {
    width: 90%;
    min-width: 40%;
  }

  * {
    font-size: ${p => p.theme["s-font-size"]};
  }
`;

const StyledAnchor = styled.a<{ disabled?: boolean }>`
  && {
    color: #00a2e0;
    font-size: ${p => p.theme["xs-font-size"]};
    font-weight: ${p => p.theme["font-weight-600"]};
    letter-spacing: 0.21px;
    line-height: 15px;
    text-align: center;
    margin-top: 8px;
    cursor: pointer;

    &:hover { color: ${p => p.theme.lightBlue}; }

    ${(p) => p.disabled && css`
      color: ${(p) => p.theme.typography.color.disabled};
      cursor: default;

      &:hover { color: ${(p) => p.theme.typography.color.disabled}; }
    `}
  }
`;

export const StyledInput = styled.input`
  box-sizing: border-box;
  height: 40px;
  width: 100%;
  border: ${props => props.theme.dropDown["border"]};
  border-radius: ${props => props.theme.dropDown["border-radius"]};
  background-color: ${props => props.theme.white};
  padding: 0 14px;
  margin-top: 8px;
  outline: 0 !important;

  &.invalid {
    color: ${p => p.theme.errorColor};
    border: ${p => p.theme["error-border"]};
    background-color: ${p => p.theme.red};
  }
`;

const Title = styled.div`
  font-size: ${p => p.theme["xxl-font-size"]} !important;
`;

const SubTitle = styled.div`
  color: ${p => p.theme.color} !important;
  margin: auto 8px;
  font-weight: normal;
  text-align: center;
`;
