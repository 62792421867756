import React from 'react';
import { getI18n, withI18n } from 'react-i18next';
import styled from 'styled-components';
import Typography from '../../Common/Typography';

type Props = {
  t: any;
  className?: string;
};

const Footer: React.FC<Props> = ({ t, className }) => {
  const isLangSpanish = getI18n().language === "es";

  const terms = isLangSpanish
    ? process.env.REACT_APP_CWB_Terms_Path_es
    : `${process.env.REACT_APP_CWB_Site}/${process.env.REACT_APP_CWB_Terms_Path}`;
  const privacy = isLangSpanish
    ? process.env.REACT_APP_CWB_Privacy_Path_es
    : `${process.env.REACT_APP_CWB_Site}/${process.env.REACT_APP_CWB_Privacy_Path}`;

  return (
    <>
      <StyledDiv className={className}>
        <Link
          href={terms}
          rel="noopener noreferrer"
          target="_blank"
        >
          {t('Terms and Conditions')}
        </Link>
        <Divider />
        <Link
          href={privacy}
          rel="noopener noreferrer"
          target="_blank"
        >
          {t('Privacy Policy')}
        </Link>
      </StyledDiv>
      <StyledTypography variant="caption">
        {t('Copyright © 2021 Casting Workbook Services Inc.')}
      </StyledTypography>
    </>
  );
};

const StyledDiv = styled.div`
  display: flex;
  flex-flow: row nowrap;
`;

const Link = styled.a`
  && {
    color: #E1E4E8;
    font-size: 12px;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const Divider = styled.div`
  width: 1px;
  height: 22px;
  margin: ${(p) => p.theme.spacing(0, 3)};
  background-color: ${p => p.theme.palette.common.white};
`;

const StyledTypography = styled(Typography)`
  color: #E1E4E8;
  margin-top: ${(p) => p.theme.spacing(1)};
`;

export default withI18n()(Footer);
