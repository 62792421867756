import React from "react";
import qs from "query-string";
import { connect } from "react-redux";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withI18n } from "react-i18next";
import { CardElement, injectStripe } from "react-stripe-elements";
import styled, { css } from "styled-components";
import BackButton from "../Common/BackButton";
import Footer from "../Common/Footer";
import Modal from "../Common/Modal";
import PageContainer from "../Common/PageContainer";
import Tooltip from "../Common/Tooltip";
import {
  Section,
  SectionTitle,
  StyledInput,
  SmallTextError,
  StyledBlueBtn,
  SmallText,
  Spinner,
} from "../Common/StyledComponents";
import CWBLabsFooter from "components/CWBLabs/components/Footer";
import { iRootState } from "../../store";
import { ISubscriptionService } from "../../shared/api/dtos/IServicesDto";
import { IDiscount } from "../../shared/api/dtos/IPromoCodeDto";
import {
  IStripeResponse,
  IStripeCharge,
  ICreditCard,
  ISubscriptionResponse,
  IStripePaymentError,
  IStripePaymentForm,
} from "../../shared/api/dtos/IStripeDto";
import { postalCodeValidation } from "../../helpers/validations";

interface IProps extends StateProps, DispatchProps, RouteComponentProps {
  t?: any;
  stripe?: any;
  country: string;
  provinceId: number;
  allowSkip?: boolean;
  onSubmit: (apiPayload: IStripeCharge) => void;
  getServices: () => void;
  moveToNext: (serviceId: number) => void;
  onSkip?: () => void;
}

interface IState {
  fields: any;
  subtotal: string;
  tax: string;
  total: string;
  discountMessage: string;
  discount: any;
  currencySymbol: string;
}

class Membership extends React.Component<IProps, IState> {
  state: IState = {
    fields: {
      name: "",
      postalCode: "",
      serviceId: 0,
      couponCode: "",
      addressLine1: ""
    } as IStripePaymentForm,
    subtotal: "0.00",
    tax: "0.00",
    total: "0.00",
    discountMessage: "",
    discount: null,
    currencySymbol: "$",
  };

  async componentDidMount() {
    try {
      await this.getService();
      this.setService();
    } catch (error) {
      window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    }
  }

  static getDerivedStateFromProps(props: any, state: any) {
    if (
      props.serviceList !== state.serviceList ||
      props.subscriptionDetails !== state.subscriptionDetails
    ) {
      return {
        serviceList: props.serviceList,
        subscriptionDetails: props.subscriptionDetails,
      };
    }
    return null;
  }

  getService = async () => {
    await this.props.getServices();
  };

  setService = () => {
    let fields = this.state.fields;
    const service = this.props.serviceList.find((x) => x.durationMonths === 12);
    fields.serviceId = service.serviceId;
    const total = this.getTotal(service);
    this.setState({ fields });
    if (service.currency === "EUR") this.setState({ currencySymbol: "€" });
    this.updatePrice(
      this.getSubTotal(service),
      this.getTax(service.price, service.tax).toFixed(2),
      total
    );
  };

  getMonthlyPrice = (service: ISubscriptionService): number => {
    if (service.price <= 1) return service.price;

    // TODO: review precision
    return Number((service.price / service.durationMonths).toFixed(2));
  };

  getSavingPerYear = (service: ISubscriptionService) => {
    if (!this.props.serviceList) return null;

    let serviceList = this.props.serviceList.map((service) => ({
      ...service,
      totalPerMonth: service.price / service.durationMonths,
    }));

    const max = Math.max.apply(
      Math,
      serviceList.map(function (o) {
        return o.totalPerMonth;
      })
    );

    const serviceWithTotal = serviceList.find(
      (x) => x.serviceId === service.serviceId
    );

    if (max <= serviceWithTotal.totalPerMonth) return null;

    return (max - serviceWithTotal.totalPerMonth) * 12;
  };

  getTax = (price: number, tax: number): number => {
    if (!price || !tax) return 0;

    return (price * tax) / 100;
  };

  getSubTotal = (service: ISubscriptionService): string => {
    if (!service || !service.price) return "0.00";
    return service.price.toFixed(2);
  };

  getTotal = (service: ISubscriptionService): string => {
    if (!service || !service.price) return "0.00";
    const tax = this.getTax(service.price, service.tax);

    return (service.price + tax).toFixed(2);
  };

  getDiscountedTotal = (
    coupon: IDiscount,
    service: ISubscriptionService
  ): string => {
    if (!coupon || !service) return service.price.toFixed(2);

    let price = service.price;

    let discountAmount = coupon.discountAmount;

    if (coupon.isDiscountPercent)
      discountAmount = (price / 100) * coupon.discountAmount;

    let newPrice = price - discountAmount;
    const tax = this.getTax(newPrice, service.tax);
    const total = (newPrice + tax).toFixed(2);

    this.updatePrice(newPrice.toFixed(2), tax.toFixed(2), total);
    return total;
  };

  setTotal = () => {
    const { discount } = this.state;
    const service = this.props.serviceList.find(
      (x) => x.serviceId === this.state.fields.serviceId
    );
    let total = "";

    if (discount && discount.isValid) {
      total = this.getDiscountedTotal(discount, service);
    } else {
      total = this.getTotal(service);
    }

    this.updatePrice(
      this.getSubTotal(service),
      this.getTax(service.price, service.tax).toFixed(2),
      total
    );
  };

  updatePrice = (subtotal: string, tax: string, total: string) => {
    this.setState({ total });
    this.setState({ tax });
    this.setState({ subtotal });
  };

  handleServiceChange = (serviceId: number) => {
    this.setState({ fields: { ...this.state.fields, serviceId } }, () => {
      this.setTotal();
    });
  };

  handleChange = async (e: any) => {
    let fields = this.state.fields;
    fields[e.target.name] = e.target.value;
    this.setState({ fields });
    if (e.target.name === "couponCode") this.setState({ discountMessage: "" });
    // if (e.target.name === "postalCode") {
    //   await this.handleOnBlurPostal(e);
    // }
  };

  // handleOnBlurPostal = async (e: any) => {
  //   if (!e || !e.target || !e.target.value) return;
  //   await this.getService(e.target.value);
  //   this.setTotal();
  // };

  handleDiscountClick = async (e: any) => {
    if (!this.state.fields.couponCode) return;
    const inputCode = this.state.fields.couponCode;
    const discountResponse = await this.props.getDiscount(inputCode);
    this.setState(
      {
        discount: discountResponse,
        fields: discountResponse.isValid
          ? this.state.fields
          : { ...this.state.fields, couponCode: "" },
        discountMessage: discountResponse.isValid
          ? this.props.t("Valid code: {{code}}", { code: inputCode })
          : this.props.t("Invalid code: {{code}}", { code: inputCode })
      },
      () => {
        this.setTotal();
      }
    );
  };

  checkValidation(): boolean {
    const { t } = this.props;
    let fields: IStripePaymentForm = this.state.fields;
    let errors = {} as IStripePaymentError;

    if (!fields.name) errors.name = t("Name on Card field is required");

    if (!fields.addressLine1) errors.addressLine1 = t("Address is required");

    if (!fields.postalCode)
      errors.addressZip = t("Postal / Zip Code field is required");

    const validationError = postalCodeValidation(
      fields.postalCode.replace(/\s/g, ""),
      t
    );
    if (validationError) errors.addressZip = validationError;

    this.props.setErrors(errors);
    return Object.entries(errors).length === 0 && errors.constructor === Object;
  }

  handleSubmit = async () => {
    if (this.props.isLoading || !this.checkValidation()) return;
    let fields = this.state.fields;

    try {
      this.props.setIsLoading(true);
      let { token, error } = await this.props.stripe.createToken();

      if (error && error.message) {
        let errors = {} as IStripePaymentError;
        // TODO: how to translate erorr message? check i18 doc
        errors.errorMessage = error.message;
        this.props.setErrors(errors);
        this.props.setIsLoading(false);
        return;
      }

      const stripeResponse: IStripeResponse = token;

      const apiPayload = {} as IStripeCharge;
      apiPayload.token = stripeResponse.id;

      apiPayload.card = {} as ICreditCard;
      apiPayload.card.brand = stripeResponse.card.brand;
      apiPayload.card.expMonth = stripeResponse.card.exp_month;
      apiPayload.card.expYear = stripeResponse.card.exp_year;
      apiPayload.card.last4 = stripeResponse.card.last4;

      apiPayload.card.name = fields.name;
      apiPayload.card.addressZip = fields.postalCode.replace(/\s/g, "");
      apiPayload.card.addressLine1 = fields.addressLine1;
      apiPayload.couponCode = fields.couponCode;
      apiPayload.serviceId = fields.serviceId;
      apiPayload.provinceId = this.props.provinceId;

      await this.props.onSubmit(apiPayload);

      this.moveToNext(apiPayload.serviceId);
    } catch (error) {
      window.location.replace(`${process.env.REACT_APP_CWB_500}`);
    }
  };

  moveToNext = (serviceId: number) => {
    if (
      !this.props.errors ||
      (Object.entries(this.props.errors).length === 0 &&
        this.props.errors.constructor === Object)
    ) {
      this.props.moveToNext(serviceId);
    }
  };

  handleSkip = () => {
    this.props.onSkip();
  };

  render() {
    const { t, location } = this.props;
    const errors: IStripePaymentError = this.props.errors;
    const fields: IStripePaymentForm = this.state.fields;
    const services: ISubscriptionService[] = this.props.serviceList;
    const currencySymbol = this.state.currencySymbol;

    const isTablet = window.matchMedia("(max-width: 768px)").matches;

    const isCWBLabs = qs.parse(location.search).cwblabs;

    return (
      <StyledPageContainer isCWBLabs={isCWBLabs}>
        <BackButton width={isTablet ? 690 : 880} />
        <Wrapper isCWBLabs={isCWBLabs}>
          <StyledTitle>{t("Start your Membership")}</StyledTitle>
          <RowColumnWrapper>
            <Row>
              <ColumnBilling>
                <BillingWrapper>
                  <Section className="margin-bottom-none">
                    <SectionLargeTitle>{t("Billing")}</SectionLargeTitle>
                  </Section>
                  <Section>
                    <SectionTitle>{t("Name on card")}</SectionTitle>
                    <StyledInput
                      name="name"
                      type="text"
                      className={errors.Name ? "invalid" : ""}
                      maxLength={256}
                      onChange={this.handleChange}
                      value={fields.name}
                    />
                    <SmallTextError className="error">
                      <span>{errors.name}</span>
                    </SmallTextError>
                  </Section>
                  <Section>
                    {/* <SectionTitle>{t("Card Details")}</SectionTitle> */}
                    <CardElement
                      hidePostalCode={true}
                      className="form-control"
                    />
                    <SmallTextError className="error">
                      <span>{errors.errorMessage}</span>
                    </SmallTextError>
                  </Section>
                  <Section>
                    <SectionTitle>
                      {t("Billing Address")}
                    </SectionTitle>
                    <StyledInput
                      name="addressLine1"
                      type="text"
                      className={errors.Name ? "invalid" : ""}
                      maxLength={256}
                      onChange={this.handleChange}
                      value={fields.addressLine1}
                    />
                    <SmallTextError className="error">
                      <span>{errors.addressLine1}</span>
                    </SmallTextError>
                  </Section>
                  <Section>
                    <SectionTitle>
                      {t("Billing Postal / Zip Code")}
                    </SectionTitle>
                    <StyledInput
                      name="postalCode"
                      type="text"
                      className={errors.Name ? "invalid" : ""}
                      maxLength={256}
                      onChange={this.handleChange}
                      value={fields.postalCode}
                      // onBlur={this.handleOnBlurPostal}
                    />
                    <SmallTextError className="error">
                      <span>{errors.addressZip}</span>
                    </SmallTextError>
                  </Section>
                  <InfoText>
                    {t(
                      `By completing this purchase, I will enter into Agreement with Casting Workbook Services, Inc. ("Casting Workbook"). For the price of my enrolment, my photograph(s) and other information will be placed online in Casting Workbook, on my agent's roster (if represented) or the Talent Scout database (if unrepresented). I understand that my photograph(s) and other portfolio materials will be made available to casting director members and talent agent members (where applicable) for a period of one full year.`
                    )}
                  </InfoText>
                  <InfoText>
                    {t(
                      `I understand and agree that Casting Workbook makes no guarantee or promise, either expressed or implied, that I will obtain work, but only that my photograph(s) and other information supplied by myself will be made available to industry professionals utilizing the Casting Workbook database.`
                    )}
                  </InfoText>
                  <Section>
                    <StyledBlueBtn
                      disabled={this.props.isLoading}
                      onClick={this.handleSubmit}
                    >
                      {t("Complete Purchase")}
                      {this.props.isLoading && (
                        <Spinner
                          src="../images/spinner.svg"
                          className="spinner-width"
                        />
                      )}
                    </StyledBlueBtn>
                  </Section>
                </BillingWrapper>
              </ColumnBilling>
              <ColumnMembership>
                <MembershipContainer>
                  <MembershipWrapper>
                    <Section className="margin-bottom-none">
                      <SectionLargeTitle>
                        {t("Actor Membership")}
                      </SectionLargeTitle>
                    </Section>
                    <Section>
                      {services &&
                        services
                          .sort((a, b) => b.durationMonths - a.durationMonths)
                          .map((serv: ISubscriptionService, ix: number) => (
                            <RadioWrapper
                              key={serv.serviceId}
                              onClick={() =>
                                this.handleServiceChange(serv.serviceId)
                              }
                            >
                              <RadioInput
                                type="radio"
                                id={serv.serviceId.toString()}
                                name={"service"}
                                onChange={() =>
                                  this.handleServiceChange(serv.serviceId)
                                }
                                value={serv.serviceId}
                                checked={
                                  this.state.fields.serviceId === serv.serviceId
                                }
                              />
                              <RadioStyledInput />
                              <RadioLabelTitle
                                htmlFor={serv.serviceId.toString()}
                              >
                                {serv.description}
                                {serv.isVoiceOnly && (
                                  <StyledTooltip
                                    placement="top"
                                    tip={t("Limited features")}
                                  >
                                    <img
                                      alt="more info"
                                      src="/images/info.svg"
                                    />
                                  </StyledTooltip>
                                )}
                              </RadioLabelTitle>
                              <br />
                              <RadioLabel htmlFor={serv.serviceId.toString()}>
                                {Number(this.state.tax)
                                  ? t(
                                      // eslint-disable-next-line
                                      "{{currencySymbol}}{{amount}}/month + tax",
                                      { currencySymbol, amount: this.getMonthlyPrice(serv) }
                                    )
                                  : t(
                                      // eslint-disable-next-line
                                      "{{currencySymbol}}{{amount}}/month",
                                      { currencySymbol, amount: this.getMonthlyPrice(serv) }
                                    )}
                              </RadioLabel>
                              {this.getSavingPerYear(serv) > 0 && (
                                <>
                                  <br />
                                  <RadioLabel
                                    className={"light-green"}
                                    htmlFor={serv.serviceId.toString()}
                                  >
                                    {/* eslint-disable-next-line */}
                                    {t("Save {{currencySymbol}}{{amount}}/year", {
                                      currencySymbol, amount: this.getSavingPerYear(serv),
                                    })}
                                  </RadioLabel>
                                </>
                              )}
                            </RadioWrapper>
                          ))}
                    </Section>
                  </MembershipWrapper>
                  <Divider />
                  <MembershipWrapper>
                    <Section>
                      <SectionTitle>{t("Discount Code")}</SectionTitle>
                      <WrapperDiscount>
                        <InputDiscount
                          value={this.state.fields.couponCode}
                          name="couponCode"
                          onChange={this.handleChange}
                        />
                        <ButtonDiscount onClick={this.handleDiscountClick}>
                          {t("Apply")}
                        </ButtonDiscount>
                      </WrapperDiscount>
                    </Section>
                    <Section>
                      <SmallText>
                        <br />
                        <span
                          className={
                            this.state.fields.couponCode ? "light-green" : ""
                          }
                        >
                          {" "}
                          {this.state.discountMessage}
                        </span>
                      </SmallText>
                    </Section>
                  </MembershipWrapper>
                  <Divider />
                  <MembershipWrapper>
                    <PriceTextWrapper>
                      <PriceTextBox>
                        <PriceTextLeft>{t("Subtotal")}</PriceTextLeft>
                        <PriceTextRight>{`${currencySymbol}${this.state.subtotal}`}</PriceTextRight>
                        <PriceTextClear></PriceTextClear>
                      </PriceTextBox>
                    </PriceTextWrapper>
                  </MembershipWrapper>
                  <Divider />
                  <MembershipWrapper>
                    <PriceTextWrapper>
                      <PriceTextBox>
                        <PriceTextLeft>{t("Tax")}</PriceTextLeft>
                        <PriceTextRight>{`${currencySymbol}${this.state.tax}`}</PriceTextRight>
                        <PriceTextClear></PriceTextClear>
                      </PriceTextBox>
                    </PriceTextWrapper>
                  </MembershipWrapper>
                  <DueTodayDivider />
                  <DueTodayWrapper>
                    <PriceTextWrapper>
                      <PriceTextBox>
                        <PriceTextLeft>{t("Due Today")}</PriceTextLeft>
                        <PriceTextRight>{`${currencySymbol}${this.state.total}`}</PriceTextRight>
                        <PriceTextClear></PriceTextClear>
                      </PriceTextBox>
                    </PriceTextWrapper>
                  </DueTodayWrapper>
                </MembershipContainer>
                {this.props.allowSkip && (
                  <StyledLink onClick={this.handleSkip}>
                    {t("Skip for now - get started for free")}
                  </StyledLink>
                )}
              </ColumnMembership>
            </Row>
          </RowColumnWrapper>
        </Wrapper>
        {isCWBLabs ? (<StyledCWBLabsFooter />) : (<Footer />)}
      </StyledPageContainer>
    );
  }
}

interface StateProps {
  serviceList: ISubscriptionService[];
  subscriptionDetails: ISubscriptionResponse;
  errors: IStripePaymentError;
  isLoading: boolean;
}

function mapStateToProps(state: iRootState): StateProps {
  return {
    serviceList: state.servicesModel.services,
    subscriptionDetails: state.stripeModel.subscriptionDetails,
    errors: state.stripeModel.errors,
    isLoading: state.stripeModel.isLoading,
  };
}

interface DispatchProps {
  getDiscount: (code: string) => Promise<IDiscount>;
  createStripeSubscription: (dto: IStripeCharge) => void;
  setErrors: (errors: IStripePaymentError) => void;
  setIsLoading: (loading: boolean) => any;
}

function mapDispatchToProps(dispatch: any): DispatchProps {
  return {
    getDiscount: dispatch.promoCodeModel.getDiscount,
    createStripeSubscription: dispatch.stripeModel.createStripeSubscription,
    setErrors: dispatch.stripeModel.setErrors,
    setIsLoading: dispatch.stripeModel.setIsLoading,
  };
}

const StyledPageContainer = styled(PageContainer)`
  ${(p) => p.isCWBLabs && css`
    background-color: #040F1C;

    @media all and (max-width: 520px) {
      padding: ${(p) => p.theme.spacing(4, 2)};
      border: none;

      & > div:first-of-type {
        display: none;
      }
    }
  `}
`;

const Wrapper = styled(Modal)`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;

  * {
    color: ${(p) => p.theme.color};
    font-size: ${(p) => p.theme["s-font-size"]};
  }

  width: 880px;
  padding: 40px 60px;

  @media all and (max-width: 768px) {
    width: 690px;
    margin: 0;
    padding: 24px 24px 40px;
  }

  @media all and (max-width: 520px) {
    width: 100%;
    margin: 0;
    padding: 24px 16px 40px;

    ${(p) => p.isCWBLabs && css`
      border-top: 6px solid #02B8F9;
      border-radius: 4px;
    `}
  }
`;

const RowColumnWrapper = styled.div`
  height: 100%;
`;

const Row = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 100%;

  @media all and (max-width: 520px) {
    flex-direction: column-reverse;
  }
`;

const ColumnBilling = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  padding: 12px;
  min-width: 420px;
  background-color: #f2f2f7;
  border-radius: 4px;
  padding: 8px 40px 8px;
  margin-top: 24px;
  margin-right: 3%;
  height: fit-content;

  @media all and (max-width: 768px) {
    min-width: 360px;
    padding-left: 24px;
    padding-right: 24px;
  }

  @media all and (max-width: 520px) {
    min-width: unset;
    margin: 16px 0;
    padding: 12px 16px;
  }
`;

const ColumnMembership = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
  flex: 1;
  width: 100%;
  justify-content: start;
  // align-items: center;
  background-color: ${(p) => p.theme.white};
  font-weight: ${(p) => p.theme["font-weight-600"]};
  margin-top: 24px;
  height: 100%;
`;

const MembershipContainer = styled.div`
  border: ${(props) => props.theme.dropDown["border"]} !important;
  border-radius: ${(props) => props.theme.dropDown["border-radius"]};
`;

const StyledTitle = styled.div`
  color: ${(p) => p.theme.darkGrey} !important;
  font-size: ${(p) => p.theme["xxl-font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};
`;

const SectionLargeTitle = styled.div`
  color: ${(p) => p.theme.darkGrey} !important;
  font-size: ${(p) => p.theme["xl-font-size"]};
  font-weight: ${(p) => p.theme["font-weight-600"]};
`;

const BillingWrapper = styled.div``;

const MembershipWrapper = styled.div`
  padding: 8px 40px 18px;

  @media all and (max-width: 768px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media all and (max-width: 520px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const DueTodayWrapper = styled.div`
  padding: 8px 40px 18px;
  background-color: #f2f2f7;

  @media all and (max-width: 768px) {
    padding-left: 24px;
    padding-right: 24px;
  }

  @media all and (max-width: 520px) {
    padding-left: 16px;
    padding-right: 16px;
  }
`;

const InfoText = styled.div`
  margin-top: 24px;
  font-size: 9px !important;
`;

const PriceTextWrapper = styled.div`
  width: 100%;
  padding: 0;
  margin: 7px 0 0 0;
`;

const PriceTextBox = styled.div`
  overflow: auto;
  margin: 0;
`;

const PriceTextLeft = styled.div`
  float: left;
`;

const PriceTextRight = styled.div`
  float: right;
`;

const PriceTextClear = styled.div`
  clear: both;
`;

const WrapperDiscount = styled.div`
  margin-top: 10px;
  display:flex;
`;

const InputDiscount = styled.input`
  padding: 6px;
  font-size: 15px;
  border: 1px solid grey;
  float: left;
  flex: 1;
  border: 1px solid #d3dde9;
  border-right: none;
  border-radius: 4px 0 0 4px;
`;

const ButtonDiscount = styled.button`
  float: left;
  width:auto;
  padding: 6px 12px;
  background: white;
  color: #2196f3 !important;
  font-size: 15px;
  /* border: 1px solid grey; */
  /* border-left: none; */
  cursor: pointer;
  border: 1px solid #2196f3 !important;
  border-radius: 0 4px 4px 0;
  border-left: none;
`;

const Divider = styled.div`
  margin: 4px 0 4px 0;
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.dropDown["border-color"]};
`;

const DueTodayDivider = styled.div`
  margin: 8px 0 0 0;
  height: 1px;
  width: 100%;
  background-color: ${(props) => props.theme.dropDown["border-color"]};
`;

const RadioWrapper = styled.button`
  background-color: ${(p) => p.theme.white};
  text-align: left;
  &:focus {
    outline: none;
  }
  & + & {
    margin-top: 8px;
  }

  display: inline-block;
  position: relative;
  line-height: ${(props) => props.theme.formField["font-size"]};

  .fake-radio-button {
    fill: white;
  }

  input:disabled:checked + div svg .styled-radio-button-center {
    fill: ${(props) => props.theme.formField.disabled["color"]};
  }

  .styled-radio-button-outline {
    stroke: ${(props) => props.theme.formField.outline};
  }

  input:not(:disabled):hover + div svg .styled-radio-button-outline,
  input:not(:disabled):checked + div svg .styled-radio-button-outline {
    stroke: ${(props) => props.theme.lightBlue};
  }

  input:checked + div svg .styled-radio-button-center {
    fill: ${(props) => props.theme.lightBlue};
  }

  input:focus + div svg {
    box-shadow: 0 0 6px rgba(25, 99, 246, 0.6);
    border-radius: 100%;
  }

  input:disabled + div,
  input:disabled,
  input:disabled ~ label {
    cursor: not-allowed;
  }

  input:disabled + div svg .styled-radio-button-outline {
    stroke: ${(props) => props.theme.formField.disabled["color"]};
  }

  border: 1px solid #d3dde9 !important;
  border-radius: 4px;
  padding: 8px 8px 0;
  width: 100%;
`;

const RadioInput = styled.input`
  cursor: pointer;
  opacity: 0;
  position: absolute;
  top: 0px;
  left: 0px;
  width: 15px;
  height: 15px;
  z-index: 100;
  padding: 0px;
  margin: 0px;
  vertical-align: middle;
`;

const RadioLabelTitle = styled.label`
  color: ${(p) => p.theme.formField["color"]};
  font-family: ${(props) => props.theme["font-family"]};
  font-size: ${(props) => props.theme.formField["font-size"]};
  font-weight: ${(props) => props.theme.formField["font-weight"]};
  margin-left: 32px;
  vertical-align: middle;

  &:hover {
    cursor: pointer;
  }
`;

const RadioLabel = styled.label`
  color: ${(p) => p.theme.formField["color"]};
  font-family: ${(props) => props.theme["font-family"]};
  font-size: 12px;
  font-weight: 100;
  margin-left: 32px;
  vertical-align: middle;

  &:hover {
    cursor: pointer;
  }
`;

const RadioStyledInput = () => (
  <StyledInputWrapper
    dangerouslySetInnerHTML={{
      __html: `<svg width="15" height="15" viewBox="0 0 15 15" style="overflow: visible">
    <g stroke="none" strokeWidth="1" fill="none" fill-rule="evenodd">
      <g transform="translate(-69.000000, -293.000000)">
        <g transform="translate(69.000000, 268.000000)">
          <g transform="translate(0.000000, 25.000000)">
            <circle fill="#FFFFFF" cx="7.5" cy="7.5" r="7.5"></circle>
            <path class="styled-radio-button-outline" d="M7.5,15 C11.6421356,15 15,11.6421356 15,
              7.5 C15,3.35786438 11.6421356,0 7.5,0 C3.35786438,0 0,3.35786438 0,7.5 C0,
              11.6421356 3.35786438,15 7.5,15 Z M7.5,14 C11.0898509,14 14,11.0898509 14,
              7.5 C14,3.91014913 11.0898509,1 7.5,1 C3.91014913,1 1,3.91014913 1,7.5 C1,
              11.0898509 3.91014913,14 7.5,14 Z" fill="#AFAFAF"></path>
            <circle fill="white" cx="7.5" cy="7.5" r="3.5" class="styled-radio-button-center"></circle>
          </g>
        </g>
      </g>
    </g>
  </svg>`,
    }}
  />
);

const StyledInputWrapper = styled.div`
  height: 15px;
  width: 15px;
  position: absolute;
  z-index: 1;
`;

const StyledTooltip = styled(Tooltip)`
  position: absolute;
  top: 0;
  right: 0;
  margin: 4px 5px;
`;

const StyledLink = styled.a`
  && {
    color: ${(p) => p.theme.color};
    margin: 8px auto 0;
    font-size: ${(p) => p.theme["xs-font-size"]};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

const StyledCWBLabsFooter = styled(CWBLabsFooter)`
  margin-top: ${(p) => p.theme.spacing(3)};
`;

export default withI18n()(injectStripe(withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Membership))));
